import React, {useState} from 'react';
import {cteateFerma, getMessageBitrixBD, parsingXel} from "../http/ordersAPI";

const TheMessageAnalysis = () => {
    const [message, setMessage] = useState([])
    const getBitrixMessage = async () => {
        const result = await getMessageBitrixBD()
        console.log("result", result)
        //setMessage(result)
    }
    // const getCteateFerma = () => {
    //     cteateFerma()
    // }
    return (
        <div>
           <h2>Анализ Сообщений</h2>
            <button onClick={()=>{
                getBitrixMessage()
            }}>тест</button>
            <div>{message.map((mes, index)=>{
                return (
                    <div>
                        {mes.name}
                    </div>
                )
            })}</div>

            {/*<button onClick={()=>{*/}
            {/*    getCteateFerma()*/}
            {/*}}>Пробить чек</button>*/}
            {/*<button onClick={()=>{*/}
            {/*    parsingXel()*/}
            {/*}}>parsingXel</button>*/}

        </div>
    );
};

export default TheMessageAnalysis;
