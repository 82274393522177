import {$host} from "./index";

export const getCompany = async (company) => {
    console.log("city запрос:", company)
    const {data} = await $host.post(`api/company/getCompany`, {company: company})
    return data
}

export const createOrderBD = async (newOrder) => {
    console.log("create order")
    const {data} = await $host.post(`api/company/createOrderBD`, newOrder)
    return data
}

export const createCompanyBD = async (newCompany) => {
    console.log("make create", newCompany)
    const {data} = await $host.post(`api/company/createCompanyBD`, newCompany)
    return data
}

export const deleteCompanyBD = async (deleteCompany) => {
    console.log("make delete")
    const {data} = await $host.post(`api/company/deleteCompanyBD`, {INN: deleteCompany})
    return data
}

export const updateOneCardBD = async (updateCompany) => {
    console.log("make update", updateCompany)
    const {data} = await $host.post(`api/company/updateOneCardBD`, {INN: updateCompany})
    return data
}


export const fetchAllDeals = async (updateBx24) => {
    // console.log("bx 24 bd кликнуто")
    const {data} = await $host.post(`api/company/fetchAllDeals`, updateBx24)
    return data
}

export const getOne = async (one) => {
    console.log("getOne bd кликнуто", one)
    const {data} = await $host.post(`api/company/getOne`, one)
    return data
}

export const newCreateBDCompanies = async (company) => {
    console.log("city запрос:", company)
    const {data} = await $host.post(`api/company/newCreateBDCompanies`, company)
    return data
}

export const newUpdateBDCompanies = async (company) => {
    console.log("city UPDATE запрос:", company)
    const {data} = await $host.post(`api/company/newUpdateBDCompanies`, company)
    return data
}

export const getFilteredCompaniesFalse = async () => {
    const {data} = await $host.get(`api/company/getFilteredCompaniesFalse`)
    return data
}

export const enterMainPhoneBD = async (updateItem) => {
    const {data} = await $host.post(`api/company/enterMainPhoneBD`, updateItem)
    return data
}

export const getTrimBD = async () => {
    const {data} = await $host.post(`api/company/getTrimBD`, getTrimBD)
    return data
}


//////////
// export const fetchAllDealsKami = async (updateBx24) => {
//     // console.log("bx 24 bd кликнуто")
//     const {data} = await $host.post(`api/company/fetchAllDealsKami`, updateBx24)
//     return data
// }
