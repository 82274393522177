import React, {useEffect, useState, useRef} from 'react';
import searchImg from '../img/search.svg';
import filterImg from '../img/filter.svg';
import exitImg from '../img/close.svg';
import saveImg from '../img/save.svg';
import editImg from '../img/edit.svg';
import notificationImg from '../img/bell.svg'
import {
    createCompanyBD,
    deleteCompanyBD,
    getCompany,
    updateOneCardBD,
    fetchAllDeals,
    fetchAllDealsKami
} from "../http/catalogAPI";
import {Link, NavLink, useLocation} from "react-router-dom";
import rightImg from "../img/right-arrow.svg";
import {getUser, updateUser} from "../http/userAPI";
import {createCity, getCompaniesByCityName, groupCityI} from "../http/cityAPI";
import {getNotificationOrders, getNotificationOrdersLength} from "../http/notificationAPI";
import {normalizationNotifAll} from "../http/ordersAPI";
import {getAppReg} from "../http/appRegAPI";
import {Badge} from "react-bootstrap";
// import {IncomingForm} from "formidable";
// import fs from "fs";
// import Sound from '../audio/iphone.mp3'

const TheHeader = ({info, openFilter}) => {
    console.log('headerInfo', info);
    const [names, setNames] = useState([]);
    const [role, setRole] = useState("")
    const [oneEmp, setOneEmp] = useState([])

    const [users, setUsers] = useState([])
    const [preview, setPreview] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [dis, setDis] = useState(true);
    const [empPhoto, setEmpPhoto] = useState(null)
    const [saveBtn, showSaveBtn] = useState(false);
    const [companiesByCityName, setCompaniesByCityName] = useState([])
    const [notificationCount, setNotificationCount] = useState(0)

    const [networkStatus, setNetworkStatus] = useState(false);
    const [countReg, setCountReg] = useState(0)
    useEffect(() => {
        getAppReg().then(res=>{
            setCountReg(res.length)
        })
    }, []);

    // const [image, setImage] = useState(null)
    // const [previewImage, setPreviewImage] = useState(null)

    const menuRef = useRef();

    useEffect(() => {
        setRole(info.role.role)
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef])

    useEffect(() => {

        const fetch = async () => {
            const storedTokenView = JSON.parse(localStorage.getItem('tokenView'));
            console.log('!!!!HEADER storedTokenView', storedTokenView.email);

            const allUsers = await getUser();
            const result = allUsers.filter(email => email.email === storedTokenView.email);
            console.log('!!! ХЕАДЕР проверка', result);

            if (result.length > 0) {
                setOneEmp(result);
                setName(result[0].nameTitle || '');
                setEmail(result[0].email || '');
                setPhone(result[0].dop || '');
            }
        }

        fetch();

        console.log('nameRole', info.role);
        console.log('проверка');

    }, [info]);

    const [menu, setMenu] = useState(false);

    const [showInput, setShowInput] = useState(false);


    const showChange = (event) => {
        setMenu(false);
        // Remove the "active" class from all NavLink elements
        const navLinks = document.querySelectorAll('nav .item a');
        navLinks.forEach(link => link.classList.remove('active'));

        // Add the "active" class to the clicked NavLink
        event.target.classList.add('active');
    }


    // useEffect(() => {
    //     if (showDeleteMsg) {
    //         document.body.classList.add('modal-open');
    //     } else {
    //         document.body.classList.remove('modal-open');
    //     }
    //
    // }, [showDeleteMsg]);


    const deleteCompany = (inn) => {
        const result = names.filter(name => name.INN !== inn)
        setNames(result);
        console.log('inn', inn);
        deleteCompanyBD(inn).then(async data => {
            alert(data);
        })
            .catch(error => {
                alert(error.message);
            });
    }

    const exit = () => {
        localStorage.removeItem('tokenView')
        localStorage.clear();
    }


    const updateBDBx24 = (name) => {
        const site = {
            name: name
        }

        fetchAllDeals(site).then(async data => {
            console.log(data);
        })
            .catch(error => {
                alert(error.message);
            });
    }

    const location = useLocation();

    const [headerName, setHeaderName] = useState('')

    const handleSentName = (event) => {
        setName(event.target.value);
    };

    const handleSentPhone = (event) => {
        setPhone(event.target.value);
    };

    const handleSentEmail = (event) => {
        setEmail(event.target.value);
    };

    const updateEmployee = () => {
        // const email = empData.filter(item => item.email === user.email)
        // const cloneArr = [...empData]
        // console.log('cloneArr', cloneArr)

        const updateData = {
            name: name || '',
            dop: phone || '',
            email: email || '',
            dop1: empPhoto || '',
        }

        updateUser(updateData)


        // console.log('updateData', updateData)
        //
        // cloneArr[0] = {...cloneArr[0], updateData}
        //
        // console.log('updateEmployee', cloneArr)
        //
        // update(cloneArr)

        setDis(true);


    }

    // Установка фото по умолчанию
    const defaultPhoto = "./img/account/user_default.png"; // Путь к фото по умолчанию

    // Обработчик изменения фото
    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result); // Установка превью
                // Вы можете также отправить файл на сервер здесь
            };
            reader.readAsDataURL(file);
        }
    };

    // const handleImageChangePhoto = async (e, name) => {
    //     //console.log("name", name);
    //     let file = e.target.files[0];
    //     //console.log("file", file);
    //     const parts = file.name.split('.');
    //     const fileExtension = parts[parts.length - 1];
    //     if (fileExtension !== "jpg") {
    //         alert("фото только JPG")
    //     } else {
    //         let newFile = new File([file], name + ".jpg", {type: file.type});
    //         setImage(newFile);
    //         // Создаем URL превью фото, пока оно загружается на сервер
    //         let reader = new FileReader();
    //         reader.onloadend = () => {
    //             setPreviewImage(reader.result);
    //         };
    //         reader.readAsDataURL(newFile);
    //
    //
    //         if (file) {
    //             const formData = new FormData();
    //             formData.append('image', newFile);
    //             const req = {
    //                 method: 'POST',
    //                 body: formData,
    //             }
    //             try {
    //                 // const response = await fetch('api/upload', {
    //                 //     method: 'POST',
    //                 //     body: formData,
    //                 // });
    //                 if (req.method === 'POST') {
    //                     const form = new IncomingForm();
    //                     await form.parse(req, async (err, fields, files) => {
    //
    //                         if (err) {
    //                             return
    //                             // res.status(400).json({message: 'Ошибка разбора формы'});
    //                         }
    //
    //                         try {
    //                             //console.log("files", files);
    //                             const image = files.image[0];
    //
    //                             const oldPath = image.filepath;
    //                             const newPath = `./img/account/${image.originalFilename}`;
    //
    //                             console.log('newPath', newPath)
    //
    //                             try {
    //                                 await fs.promises.access(newPath, fs.constants.F_OK); // Check file existence
    //                                 return formData.status(400).json({message: 'Файл с таким именем уже существует, выберите другой'});
    //                             } catch (e) {
    //
    //                             }
    //
    //                             await fs.promises.rename(oldPath, newPath);
    //
    //                             // return
    //                             // res.status(200).json({message: 'Изображение успешно загружено'});
    //                         } catch (error) {
    //                             console.error(error);
    //                             // return
    //                             // res.status(500).json({message: 'Ошибка загрузки изображения'});
    //                         }
    //                     });
    //                 } else {
    //                     // res.status(405).json({ message: 'Метод не разрешен' });
    //                 }
    //                 if (true) {
    //                     // Обработка успешного ответа от сервера
    //                     console.log('Изображение успешно загружено', newFile);
    //                 } else {
    //                     alert("Фото уже существует выберете новое")
    //                     console.error('Ошибка загрузки изображения');
    //                 }
    //             } catch (error) {
    //                 // Обработка ошибки сети
    //                 console.error('Ошибка сети', error);
    //             }
    //         }
    //     }
    // }

    const openNotification = () => {
        console.log('openNotification')
    }

    // useEffect(() => {
    //     console.log('ДЕРНУЛСЯ res getNotificationOrders');
    //
    //     // Define a function to play the sound
    //     // const playNotificationSound = () => {
    //     //     const audio = new Audio('audio/iphone.mp3'); // Provide the path to your sound file
    //     //     audio.play()
    //     //         .then(() => {
    //     //             console.log('Notification sound played successfully.');
    //     //         })
    //     //         .catch(error => {
    //     //             console.error('Failed to play notification sound:', error);
    //     //         });
    //     // };
    //
    //     const fetch = async () => {
    //         const intervalId = setInterval(async () => {
    //             try {
    //                 // Обращаемся к вашей функции getNotificationOrders()
    //                 // и обрабатываем ошибку, если возникает Network Error
    //                 const resLength = await getNotificationOrdersLength();
    //                 localStorage.setItem('notificationCount', resLength);
    //
    //                 // Если удалось получить данные, устанавливаем значение стейта в true
    //                 setNetworkStatus(true);
    //
    //                 // Проверяем изменение количества уведомлений и воспроизводим звук при необходимости
    //                 if (notificationCount < resLength) {
    //                     console.log('ДЗИНЬК');
    //                     // playNotificationSound(); // Call the function to play the sound
    //                 }
    //
    //                 setNotificationCount(resLength);
    //             } catch (error) {
    //                 if (error.code === 'ERR_NETWORK') {
    //                     console.error('Server connection refused:', error);
    //                     console.log('Соединение потеряно.');
    //                     // Устанавливаем значение стейта в false, чтобы указать на потерю сети
    //                     setNetworkStatus(false);
    //                 }
    //                 else {
    //                     console.error('Error occurred:', error);
    //                     // Ваш общий код для обработки других типов ошибок, если необходимо
    //                 }
    //             }
    //
    //             // catch (error) {
    //             //     // Обработка ошибки при возникновении Network Error
    //             //     console.error('Network Error:', error);
    //             //     // Выводим сообщение о потере сети в консоль
    //             //     console.log('Соединение потеряно.');
    //             //     // Устанавливаем значение стейта в false, чтобы указать на потерю сети
    //             //     setNetworkStatus(false);
    //             // }
    //         }, 5000); // каждые 5 секунд, например
    //
    //         // Очищаем интервал при размонтировании компонента
    //         return () => clearInterval(intervalId);
    //     };
    //
    //     fetch();
    // }, []);

    useEffect(() => {
        // let employee = empData.filter(item => item.fio === "Кузнецова Анна Николаевна")
        // setEmpPhoto(users[0].photo)
        // setPhone(employee[0].phone)
        // setName(users.name)
        // setEmail(users.email)
        let parts = location.pathname.split('/');
        let lastWord = parts[parts.length - 2];
        if (lastWord === '') {
            lastWord = parts[parts.length - 1];
        }
        console.log('lastWord', lastWord)


        switch (lastWord) {
            case '':
                setHeaderName('');
                break;
            case 'card':
                setHeaderName('Контрагент');
                break;
            case 'payments':
                setHeaderName('Деньги');
                break;
            case 'orders':
                setHeaderName('Заказы');
                break;
            case 'order':
                setHeaderName('Заказ');
                break;
            case 'pay':
                setHeaderName('Выплата');
                break;
            case 'create':
                setHeaderName('Создание контрагента');
                break;
            case 'createOrder':
                setHeaderName('Создание заказа');
                break;
            case 'employees':
                setHeaderName('Сотрудники');
                break;
            case 'deletedContractors':
                setHeaderName('Удаленные контрагенты');
                break;
            case 'notifications':
                setHeaderName('Оповещения');
                break;
            case 'statistics':
                setHeaderName('Статистика');
                break;
            case 'deals':
                setHeaderName('Сделки');
                break;
            case 'cityStatistics':
                setHeaderName('Статистика по городам');
                break;
            case 'cityStatisticsTest':
                setHeaderName('Сверка');
                break;
            // case 'dealsStatistics':
            //     setHeaderName('Статистика по дням');
            //     break;
            case 'dealsStatistics':
                setHeaderName('Статистика по дням');
                break;
            default:
                setHeaderName('');
                break;
        }


    }, [location]);

    const updateOrderTable = () => {
        normalizationNotifAll()
    }


    switch (role) {
        case 'admin':
            return (
                <>
                    <div className="header_wrapper">
                        <div className="menu_bg"
                             style={menu ? {display: "block"} : {display: "none"}}
                        ></div>
                        <header
                            className="header"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">
                                <div className="burger"
                                     onClick={() => {
                                         if (menu) {
                                             setMenu(false)
                                         }
                                         setMenu(true)
                                     }}
                                >
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                </div>
                                <div className="header__name"
                                     style={!showInput ? {display: "block"} : {display: "none"}}
                                >
                                    {headerName}
                                </div>
                            </div>
                            {/*<button onClick={() => updateOrderTable()}>Обновить orders</button>*/}
                            <div className="header__right">
                                {/*<div className="input">*/}
                                {/*    <input type="search" placeholder="Введите запрос" className="search-field"*/}
                                {/*           style={showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    />*/}
                                {/*    <button*/}
                                {/*        type="submit"*/}
                                {/*        className="search-button"*/}
                                {/*        onClick={() => {setShowInput(!showInput)}}*/}
                                {/*        style={!showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    >*/}
                                {/*        <img src={searchImg} className="logo_img" width="20px" height="20px"/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div
                                    className="login"
                                    onClick={openFilter}
                                >
                                    <img src={filterImg} width="25px" height="25px"/>
                                </div>
                                {/*<div*/}
                                {/*    className="notification"*/}
                                {/*    onClick={openNotification}*/}
                                {/*>*/}
                                {/*    <NavLink to="/notifications">*/}
                                {/*        <img src={notificationImg} width="25px" height="25px"/>*/}
                                {/*        <div*/}
                                {/*            className="notification_count"*/}
                                {/*            style={{display: notificationCount > 0 ? "flex" : "none"}}*/}
                                {/*        >*/}
                                {/*            <div*/}
                                {/*                className="notification_count__item"*/}
                                {/*                style={{ fontSize: notificationCount > 100 ? "7px" : (notificationCount > 20 ? "9px" : "inherit") }}*/}
                                {/*            >{notificationCount}</div>*/}
                                {/*        </div>*/}
                                {/*    </NavLink>*/}
                                {/*    <div className="network_ident" style={{backgroundColor: networkStatus ? "green" : "red"}}></div>*/}
                                {/*</div>*/}
                            </div>
                        </header>

                        <div className="menu"
                             style={menu ? {display: "block"} : {display: "none"}}
                             ref={menuRef}
                        >
                            {/*<div className="close"*/}
                            {/*     onClick={() => {*/}
                            {/*         if (!menu) {*/}
                            {/*             setMenu(true)*/}
                            {/*         } else setMenu(false)*/}
                            {/*     }}>*/}
                            {/*    <img src={exitImg} width="25px" height="25px"/>*/}
                            {/*</div>*/}

                            <div className="menu__header">
                                {/*<div className="logo"></div>*/}
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <button
                                        onClick={() => {
                                            window.location.reload();
                                            exit()
                                        }}
                                        className="menu__main_button"
                                    >Выход
                                    </button>
                                    {/*<img src={empPhoto.photo ? empPhoto.photo : defaultPhoto}/>*/}
                                    {/*<img src={defaultPhoto} width="50px" height="50px" className="circular-image"/>*/}
                                </div>
                                {/*<input type="file" onChange={handlePhotoChange}*/}
                                {/*       accept="image/*"*/}
                                {/*       style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                {/*/>*/}
                                {/*{preview && (*/}
                                {/*    <div style={saveBtn ? {display: "block"} : {display: "none"}}>*/}
                                {/*        <h4>Предпросмотр</h4>*/}
                                {/*        <img src={preview} alt="Preview" className="circular-image"/>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*<div className="name">{info.role.nameTitle}</div>*/}
                                {/*<div className="role">{info.role.role}</div>*/}
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={handleSentName}
                                        disabled={dis ? true : false}
                                        placeholder="ФИО"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={handleSentEmail}
                                        disabled={true}
                                        placeholder="Введите email"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="phone"
                                        type="text"
                                        value={phone}
                                     onChange={handleSentPhone}
                                        disabled={dis ? true : false}
                                        placeholder="Телефон"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="menu__buttons">
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(true);*/}
                                    {/*        setDis(false);*/}
                                    {/*    }}*/}
                                    {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={editImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(false);*/}
                                    {/*        setPreview(null)*/}
                                    {/*        // console.log('card.id', id)*/}
                                    {/*        updateEmployee()*/}
                                    {/*    }}*/}
                                    {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={saveImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div style={{display: role === "admin" || role === "manager" ? "block" : "none"}}
                                 className="menu__main">
                                {/*<button onClick={() => updateBDBx24("mi-amor")}>НЕ НАЖИМАТЬ!</button>*/}

                                <nav>
                                    <div className="item">
                                        <NavLink to="/" exact="true" onClick={showChange}>Главная</NavLink>
                                    </div>
                                    {/*<button onClick={() => {*/}
                                    {/*    createCity().then()*/}
                                    {/*}}>Создать города*/}
                                    {/*</button>*/}

                                    {/*<button onClick={async () => {*/}
                                    {/*    const resultSent = "березовский"*/}
                                    {/*    const r = resultSent.toLowerCase().replace(/[\s.\-()]/g, '')*/}
                                    {/*    const result = await getCompaniesByCityName(r).then()*/}
                                    {/*    setCompaniesByCityName(result)*/}
                                    {/*    console.log("Ростов на дону", result)*/}
                                    {/*}}>получить города*/}
                                    {/*</button>*/}
                                    {/*{companiesByCityName && companiesByCityName.map((company, index)=>{*/}
                                    {/*    return (*/}
                                    {/*        <div key={index}>{company.nameCompany}</div>*/}
                                    {/*    )*/}
                                    {/*})}*/}
                                    <div className="item">
                                        <NavLink to="/payments" onClick={showChange}>Деньги
                                        </NavLink>
                                    </div>
                                    <div className="item">
                                        <NavLink to="/employees" onClick={showChange}>Сотрудники
                                        </NavLink>
                                    </div>
                                    <div className="item">
                                        <NavLink to="/deletedContractors" onClick={showChange}>Удаленные контрагенты
                                        </NavLink>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <div className="link_wrapper">*/}
                                    {/*        <NavLink to="/notifications" onClick={showChange}>Оповещения*/}
                                    {/*        </NavLink>*/}
                                    {/*        <div*/}
                                    {/*            className="count_"*/}
                                    {/*            style={{display: notificationCount > 0 ? "block" : "none"}}*/}
                                    {/*        >{notificationCount}</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="item">
                                        <div className="link_wrapper">
                                            <NavLink to="/statistics" onClick={showChange}>Статистика
                                            </NavLink>
                                            {/*<div*/}
                                            {/*    className="count_"*/}
                                            {/*    style={{display: notificationCount > 0 ? "block" : "none"}}*/}
                                            {/*>{notificationCount}</div>*/}
                                        </div>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <div className="link_wrapper">*/}
                                    {/*        <NavLink to="/bot" onClick={showChange}>Бот*/}
                                    {/*        </NavLink>*/}
                                    {/*        /!*<div*!/*/}
                                    {/*        /!*    className="count_"*!/*/}
                                    {/*        /!*    style={{display: notificationCount > 0 ? "block" : "none"}}*!/*/}
                                    {/*        /!*>{notificationCount}</div>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="item">
                                        <div className="link_wrapper">
                                            <NavLink to="/orderShop" onClick={showChange}>Заказы
                                            </NavLink>
                                            {/*<div*/}
                                            {/*    className="count_"*/}
                                            {/*    style={{display: notificationCount > 0 ? "block" : "none"}}*/}
                                            {/*>{notificationCount}</div>*/}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="link_wrapper">
                                            <NavLink to="/appreg" onClick={showChange}>Заявки от бота
                                            </NavLink>
                                            <Badge bg="secondary" style={{ marginLeft: '10px' }}>
                                                {countReg > 0 ? countReg : null}
                                            </Badge>
                                            {/*<div*/}
                                            {/*    className="count_"*/}
                                            {/*    style={{display: notificationCount > 0 ? "block" : "none"}}*/}
                                            {/*>{notificationCount}</div>*/}
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="link_wrapper">
                                            <NavLink to="/messageAnalysis" onClick={showChange}>Анализ сообщений
                                            </NavLink>
                                            {/*<div*/}
                                            {/*    className="count_"*/}
                                            {/*    style={{display: notificationCount > 0 ? "block" : "none"}}*/}
                                            {/*>{notificationCount}</div>*/}
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'buh':
            return (
                <>
                    <div className="header_wrapper">
                        <div className="menu_bg"
                             style={menu ? {display: "block"} : {display: "none"}}
                        ></div>
                        <header
                            className="header"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">
                                <div className="burger"
                                     onClick={() => {
                                         if (menu) {
                                             setMenu(false)
                                         }
                                         setMenu(true)
                                     }}
                                >
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                </div>
                                <div className="header__name"
                                     style={!showInput ? {display: "block"} : {display: "none"}}
                                >
                                    {headerName}
                                </div>
                            </div>
                            <div className="header__right">
                                {/*<div className="input">*/}
                                {/*    <input type="search" placeholder="Введите запрос" className="search-field"*/}
                                {/*           style={showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    />*/}
                                {/*    <button*/}
                                {/*        type="submit"*/}
                                {/*        className="search-button"*/}
                                {/*        onClick={() => {setShowInput(!showInput)}}*/}
                                {/*        style={!showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    >*/}
                                {/*        <img src={searchImg} className="logo_img" width="20px" height="20px"/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div
                                    className="login"
                                    onClick={openFilter}
                                >
                                    <img src={filterImg} width="25px" height="25px"/>
                                </div>
                                {/*<div*/}
                                {/*    className="notification"*/}
                                {/*    onClick={openNotification}*/}
                                {/*>*/}
                                {/*    <img src={notificationImg} width="25px" height="25px"/>*/}
                                {/*</div>*/}
                            </div>
                        </header>

                        <div className="menu"
                             style={menu ? {display: "block"} : {display: "none"}}
                             ref={menuRef}
                        >
                            {/*<div className="close"*/}
                            {/*     onClick={() => {*/}
                            {/*         if (!menu) {*/}
                            {/*             setMenu(true)*/}
                            {/*         } else setMenu(false)*/}
                            {/*     }}>*/}
                            {/*    <img src={exitImg} width="25px" height="25px"/>*/}
                            {/*</div>*/}

                            <div className="menu__header">
                                {/*<div className="logo"></div>*/}
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <button
                                        onClick={() => {
                                            window.location.reload();
                                            exit()
                                        }}
                                        className="menu__main_button"
                                    >Выход
                                    </button>
                                    {/*<img src={empPhoto.photo ? empPhoto.photo : defaultPhoto}/>*/}
                                    {/*<img src={defaultPhoto} width="50px" height="50px" className="circular-image"/>*/}
                                </div>
                                {/*<input type="file" onChange={handlePhotoChange}*/}
                                {/*       accept="image/*"*/}
                                {/*       style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                {/*/>*/}
                                {/*{preview && (*/}
                                {/*    <div style={saveBtn ? {display: "block"} : {display: "none"}}>*/}
                                {/*        <h4>Предпросмотр</h4>*/}
                                {/*        <img src={preview} alt="Preview" className="circular-image"/>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*<div className="name">{info.role.nameTitle}</div>*/}
                                {/*<div className="role">{info.role.role}</div>*/}
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={handleSentName}
                                        disabled={dis ? true : false}
                                        placeholder="ФИО"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={handleSentEmail}
                                        disabled={true}
                                        placeholder="Введите email"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="phone"
                                        type="text"
                                        value={phone}
                                        onChange={handleSentPhone}
                                        disabled={dis ? true : false}
                                        placeholder="Телефон"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="menu__buttons">
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(true);*/}
                                    {/*        setDis(false);*/}
                                    {/*    }}*/}
                                    {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={editImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(false);*/}
                                    {/*        setPreview(null)*/}
                                    {/*        // console.log('card.id', id)*/}
                                    {/*        updateEmployee()*/}
                                    {/*    }}*/}
                                    {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={saveImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div style={{display: role === "admin" || role === "manager" || role === "buh" ? "block" : "none"}}
                                 className="menu__main">
                                {/*<button onClick={() => updateBDBx24("mi-amor")}>НЕ НАЖИМАТЬ!</button>*/}

                                <nav>
                                    <div className="item">
                                        <NavLink to="/" exact="true" onClick={showChange}>Главная</NavLink>
                                    </div>
                                    <div className="item">
                                        <NavLink to="/payments" onClick={showChange}>Деньги
                                        </NavLink>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/orders" activeClassName="active" onClick={showChange}>Заказы*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                    <div className="item">
                                        <NavLink to="/employees" onClick={showChange}>Сотрудники
                                        </NavLink>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <div className="link_wrapper">*/}
                                    {/*        <NavLink to="/statistics" onClick={showChange}>Статистика*/}
                                    {/*        </NavLink>*/}
                                    {/*        /!*<div*!/*/}
                                    {/*        /!*    className="count_"*!/*/}
                                    {/*        /!*    style={{display: notificationCount > 0 ? "block" : "none"}}*!/*/}
                                    {/*        /!*>{notificationCount}</div>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'manager':
            return (
                <>
                    <div className="header_wrapper">
                        <div className="menu_bg"
                             style={menu ? {display: "block"} : {display: "none"}}
                        ></div>
                        <header
                            className="header"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">
                                <div className="burger"
                                     onClick={() => {
                                         if (menu) {
                                             setMenu(false)
                                         }
                                         setMenu(true)
                                     }}
                                >
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                </div>
                                <div className="header__name"
                                     style={!showInput ? {display: "block"} : {display: "none"}}
                                >
                                    {headerName}
                                </div>
                            </div>
                            <div className="header__right">
                                {/*<div className="input">*/}
                                {/*    <input type="search" placeholder="Введите запрос" className="search-field"*/}
                                {/*           style={showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    />*/}
                                {/*    <button*/}
                                {/*        type="submit"*/}
                                {/*        className="search-button"*/}
                                {/*        onClick={() => {setShowInput(!showInput)}}*/}
                                {/*        style={!showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    >*/}
                                {/*        <img src={searchImg} className="logo_img" width="20px" height="20px"/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div
                                    className="login"
                                    onClick={openFilter}
                                >
                                    <img src={filterImg} width="25px" height="25px"/>
                                </div>
                                {/*<div*/}
                                {/*    className="notification"*/}
                                {/*    onClick={openNotification}*/}
                                {/*>*/}
                                {/*    <img src={notificationImg} width="25px" height="25px"/>*/}
                                {/*</div>*/}
                            </div>
                        </header>

                        <div className="menu"
                             style={menu ? {display: "block"} : {display: "none"}}
                             ref={menuRef}
                        >
                            {/*<div className="close"*/}
                            {/*     onClick={() => {*/}
                            {/*         if (!menu) {*/}
                            {/*             setMenu(true)*/}
                            {/*         } else setMenu(false)*/}
                            {/*     }}>*/}
                            {/*    <img src={exitImg} width="25px" height="25px"/>*/}
                            {/*</div>*/}

                            <div className="menu__header">
                                {/*<div className="logo"></div>*/}
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <button
                                        onClick={() => {
                                            window.location.reload();
                                            exit()
                                        }}
                                        className="menu__main_button"
                                    >Выход
                                    </button>
                                    {/*<img src={empPhoto.photo ? empPhoto.photo : defaultPhoto}/>*/}
                                    {/*<img src={defaultPhoto} width="50px" height="50px" className="circular-image"/>*/}
                                </div>
                                {/*<input type="file" onChange={handlePhotoChange}*/}
                                {/*       accept="image/*"*/}
                                {/*       style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                {/*/>*/}
                                {/*{preview && (*/}
                                {/*    <div style={saveBtn ? {display: "block"} : {display: "none"}}>*/}
                                {/*        <h4>Предпросмотр</h4>*/}
                                {/*        <img src={preview} alt="Preview" className="circular-image"/>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*<div className="name">{info.role.nameTitle}</div>*/}
                                {/*<div className="role">{info.role.role}</div>*/}
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={handleSentName}
                                        disabled={dis ? true : false}
                                        placeholder="ФИО"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={handleSentEmail}
                                        disabled={true}
                                        placeholder="Введите email"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="phone"
                                        type="text"
                                        value={phone}
                                        onChange={handleSentPhone}
                                        disabled={dis ? true : false}
                                        placeholder="Телефон"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="menu__buttons">
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(true);*/}
                                    {/*        setDis(false);*/}
                                    {/*    }}*/}
                                    {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={editImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(false);*/}
                                    {/*        setPreview(null)*/}
                                    {/*        // console.log('card.id', id)*/}
                                    {/*        updateEmployee()*/}
                                    {/*    }}*/}
                                    {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={saveImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div style={{display: role === "admin" || role === "manager" || role === "buh" ? "block" : "none"}}
                                 className="menu__main">
                                {/*<button onClick={() => updateBDBx24("mi-amor")}>НЕ НАЖИМАТЬ!</button>*/}

                                <nav>
                                    <div className="item">
                                        <NavLink to="/" exact="true"  onClick={showChange}>Главная</NavLink>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/payments" activeClassName="active" onClick={showChange}>Деньги*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/orders" activeClassName="active" onClick={showChange}>Заказы*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}

                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/employees"  onClick={showChange}>Сотрудники*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                    {/*<div className="item">*/}
                                    {/*    <div className="link_wrapper">*/}
                                    {/*        <NavLink to="/statistics" onClick={showChange}>Статистика*/}
                                    {/*        </NavLink>*/}
                                    {/*        /!*<div*!/*/}
                                    {/*        /!*    className="count_"*!/*/}
                                    {/*        /!*    style={{display: notificationCount > 0 ? "block" : "none"}}*!/*/}
                                    {/*        /!*>{notificationCount}</div>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            );
        case 'shop':
            return (
                <>
                    <div className="header_wrapper">
                        <div className="menu_bg"
                             style={menu ? {display: "block"} : {display: "none"}}
                        ></div>
                        <header
                            className="header"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">
                                <div className="burger"
                                     onClick={() => {
                                         if (menu) {
                                             setMenu(false)
                                         }
                                         setMenu(true)
                                     }}
                                >
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                    <div className="burger__item"/>
                                </div>
                                <div className="header__name"
                                     style={!showInput ? {display: "block"} : {display: "none"}}
                                >
                                    {headerName}
                                </div>
                            </div>
                            <div className="header__right">
                                {/*<div className="input">*/}
                                {/*    <input type="search" placeholder="Введите запрос" className="search-field"*/}
                                {/*           style={showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    />*/}
                                {/*    <button*/}
                                {/*        type="submit"*/}
                                {/*        className="search-button"*/}
                                {/*        onClick={() => {setShowInput(!showInput)}}*/}
                                {/*        style={!showInput ? {display: "block"} : {display: "none"}}*/}
                                {/*    >*/}
                                {/*        <img src={searchImg} className="logo_img" width="20px" height="20px"/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                <div
                                    className="login"
                                    onClick={openFilter}
                                >
                                    <img src={filterImg} width="25px" height="25px"/>
                                </div>
                                {/*<div*/}
                                {/*    className="notification"*/}
                                {/*    onClick={openNotification}*/}
                                {/*>*/}
                                {/*    <img src={notificationImg} width="25px" height="25px"/>*/}
                                {/*</div>*/}
                            </div>
                        </header>

                        <div className="menu"
                             style={menu ? {display: "block"} : {display: "none"}}
                             ref={menuRef}
                        >
                            {/*<div className="close"*/}
                            {/*     onClick={() => {*/}
                            {/*         if (!menu) {*/}
                            {/*             setMenu(true)*/}
                            {/*         } else setMenu(false)*/}
                            {/*     }}>*/}
                            {/*    <img src={exitImg} width="25px" height="25px"/>*/}
                            {/*</div>*/}

                            <div className="menu__header">
                                {/*<div className="logo"></div>*/}
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <button
                                        onClick={() => {
                                            window.location.reload();
                                            exit()
                                        }}
                                        className="menu__main_button"
                                    >Выход
                                    </button>
                                    {/*<img src={empPhoto.photo ? empPhoto.photo : defaultPhoto}/>*/}
                                    {/*<img src={defaultPhoto} width="50px" height="50px" className="circular-image"/>*/}
                                </div>
                                {/*<input type="file" onChange={handlePhotoChange}*/}
                                {/*       accept="image/*"*/}
                                {/*       style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                {/*/>*/}
                                {/*{preview && (*/}
                                {/*    <div style={saveBtn ? {display: "block"} : {display: "none"}}>*/}
                                {/*        <h4>Предпросмотр</h4>*/}
                                {/*        <img src={preview} alt="Preview" className="circular-image"/>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                {/*<div className="name">{info.role.nameTitle}</div>*/}
                                {/*<div className="role">{info.role.role}</div>*/}
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={handleSentName}
                                        disabled={dis ? true : false}
                                        placeholder="ФИО"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={handleSentEmail}
                                        disabled={true}
                                        placeholder="Введите email"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="input_item">
                                    <input
                                        className="item_"
                                        name="phone"
                                        type="text"
                                        value={phone}
                                        onChange={handleSentPhone}
                                        disabled={dis ? true : false}
                                        placeholder="Телефон"
                                        style={saveBtn ? {borderBottom: "1px solid #ffffff"} : {display: "1px solid transparent"}}
                                    />
                                </div>
                                <div className="menu__buttons">
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(true);*/}
                                    {/*        setDis(false);*/}
                                    {/*    }}*/}
                                    {/*    style={!saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={editImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    onClick={() => {*/}
                                    {/*        showSaveBtn(false);*/}
                                    {/*        setPreview(null)*/}
                                    {/*        // console.log('card.id', id)*/}
                                    {/*        updateEmployee()*/}
                                    {/*    }}*/}
                                    {/*    style={saveBtn ? {display: "block"} : {display: "none"}}*/}
                                    {/*    className="menu__button"*/}
                                    {/*>*/}
                                    {/*    <img src={saveImg} className="logo_img" width="25px" height="25px"/>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div style={{display: role === "admin" || role === "manager" || role === "buh" ? "block" : "none"}}
                                 className="menu__main">
                                {/*<button onClick={() => updateBDBx24("mi-amor")}>НЕ НАЖИМАТЬ!</button>*/}

                                <nav>
                                    <div className="item">
                                        <NavLink to="/" exact="true"  onClick={showChange}>Главная</NavLink>
                                    </div>
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/payments" activeClassName="active" onClick={showChange}>Деньги*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/orders" activeClassName="active" onClick={showChange}>Заказы*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                    {/*<div className="item">*/}
                                    {/*    <NavLink to="/employees" activeClassName="active" onClick={showChange}>Список*/}
                                    {/*        сотрудников*/}
                                    {/*    </NavLink>*/}
                                    {/*</div>*/}
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            );
    }

};

export {TheHeader};
