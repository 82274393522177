import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import leftImg from "../img/left_arrow.svg";
import {DateTime} from "luxon";
import {callGetDayReport, getBitrix, getCompanyOrdersByDatePeriod, getRating, sendRating} from "../http/ordersAPI";
import preloadImg from "../img/tube-spinner.svg";
import {countAdvBDNotification, dataFromNotifications} from "../http/notificationAPI";
import {getUser} from "../http/userAPI";
import {clearBd, createComponyWordPress, postBd, updateCompanyLive} from "../http/cityAPI";
import {Button, Form, Image, Stack, Table} from "react-bootstrap";

const TheDealsStatisticsBootstrap = ({show, info}) => {
    const [preloader, setPreloader] = useState(false)
    const [sortOption, setSortOption] = useState("");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dealsStatistics, setDealsStatistics] = useState([])
    const [advValues, setAdvValues] = useState({});
    const [profitValues, setProfitValues] = useState([0]);
    const [incomeValues, setIncomeValues] = useState([]);
    const [profitAll, setProfitAll] = useState(0)
    const [advAll, setAdvAll] = useState(0)
    const [incomeAll, setIncomeAll] = useState(0)
    const [allTdSum, setAllTdSum] = useState(0)
    const [failedTdSum, setFailedTdSum] = useState(0)
    const [winTdSum, setWinTdSum] = useState(0)
    const [workTdSum, setWorkTdSum] = useState(0)
    const [dataToNotif, setDataToNotif] = useState(null)
    const [linkBtx, setLinkBtx] = useState('')
    const [showTable,setShowTable] = useState(false)
    const [periodManagers, setPeriodManagers] = useState([])
    const [noTableData, setNoTableData] = useState(false)
    const [users, setUsers] = useState([])
    const [middleConv, setMiddleConv] = useState(null)
    const [managersInfo, setManagersInfo] = useState([])
    const [hoveredItem, setHoveredItem] = useState(null);
    const [formData, setFormData] = useState({
        conversionRate: {
            weight: 0.5, base: 30, norm: 40, goal: 55
        },
        successfulDeals: {
            weight: 0.3, base: 7, norm: 15, goal: 27
        },
        averageRevenue: {
            weight: 0.2, base: 700, norm: 1200, goal: 2700
        },
    });

    // const [formData, setFormData] = useState(null)

    useEffect(() => {
        // setPreloader(true)
        const fetchData = async () => {
            try {
                setAdvAll(0)

                let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let now = DateTime.now().setZone(timeZone);
                let firstMonthDate = now.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate = now.toISODate();

                setStartDate(firstMonthDate);
                setEndDate(currentDate);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
            // setPreloader(false)
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     // console.log('incomeValues values', incomeValues)
    //     // console.log('advValues values', advValues)
    //     // console.log('profitValues values', profitValues)
    //     // console.log('hoveredItem values', hoveredItem)
    //     console.log('formData values', formData)
    // }, [
    //     // incomeValues, advValues, profitValues, hoveredItem,
    //     formData
    // ]);

    const getDataToTable = async () => {
        setPreloader(true)
        setAdvValues([])
        setAdvAll(0)
        setHoveredItem(null)

        const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

        setDealsStatistics(Object.entries(ordersByDate))

        console.log('ordersByDate', ordersByDate)

        if (Object.entries(ordersByDate).length === 0) {
            setNoTableData(true)
        }

        const incomes = Object.entries(ordersByDate)
            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
            .map((item, index) => ({
                dateBitrix: item[0],
                incomeValue: item[1].orders.reduce((total, orderItem) => {
                    if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                        ||
                        (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                    ) {
                        let sum = Math.round(Number(orderItem.sum));
                        let sentSum = Math.round(Number(orderItem.sentSum));
                        if (sum === 0 || sentSum === 0) {
                            return total; // Return current total without adding anything if either sum or sentSum is 0
                        } else {
                            return total + (sum - sentSum);
                        }
                    }
                    return total; // Return current total if conditions are not met
                }, 0)
            }));

        // const incomes = Object.entries(ordersByDate)
        //     .sort((a, b) => new Date(b[0]) - new Date(a[0]))
        //     .map((item, index) => ({
        //         dateBitrix: item[0],
        //         incomeValue: item[1].orders
        //             .filter(item => item.status === null &&
        //                 item.sentSum !== undefined && item.sentSum !== '' && item.sentSum !== null &&
        //                 item.sum !== undefined && item.sum !== '' && item.sum !== null &&
        //                 item.sum !== "0" && item.sentSum !== "0")
        //             .reduce((total, item) => {
        //                 let sum = Math.round(Number(item.sum));
        //                 let sentSum = Math.round(Number(item.sentSum));
        //                 if (sum === 0 || sentSum === 0) {
        //                     return total; // Return current total without adding anything if either sum or sentSum is 0
        //                 } else {
        //                     return total + (sum - sentSum);
        //                 }
        //             }, 0)
        //     }));

        setIncomeValues(incomes);

        setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


        const datas = await dataFromNotifications()
        const data = datas.filter(item => item.link === sortOption);

        let advs = []
        let profits = []

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                advs[data[i].dateBitrix] = {
                    dateBitrix: data[i].dateBitrix,
                    currentAdv: Number(data[i].countAdv),
                    link: sortOption
                };
            }

            setAdvValues(advs);

            const filteredAdvs = Object.values(advs).filter(item => {
                const itemDate = new Date(item?.dateBitrix);
                const startDateObj = new Date(startDate);
                const endDateObj = new Date(endDate);

                return itemDate >= startDateObj && itemDate <= endDateObj;
            });

            const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

            setAdvAll(totalCountAdv);

            let filteredAdvsFull = []

            for (let i = 0; i < filteredAdvs.length; i++) {
                filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                    dateBitrix: filteredAdvs[i].dateBitrix,
                    currentAdv: Number(filteredAdvs[i].currentAdv),
                    link: sortOption
                };
            }

            for (const adv of Object.entries(filteredAdvsFull)) {
                const income = incomes.find(income => income?.dateBitrix === adv[0]);
                if (income) {
                    profits[adv[0]] = {
                        dateBitrix: adv[0],
                        countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                    }
                    setProfitValues(profits);
                }
            }
        } else {
            setAdvValues([]);
            setProfitValues([]);
        }


        setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

        let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
            return total + item[1].orders.length;
        }, 0);

        setAllTdSum(allSumm)

        let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === null);
            return total + filteredOrders.length;
        }, 0);

        setWinTdSum(winSumm)

        let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === 'failed');
            return total + filteredOrders.length;
        }, 0);

        setFailedTdSum(failedSumm)

        let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
            let filteredOrders = value.orders.filter(order => order.status === 'work');
            return total + filteredOrders.length;
        }, 0);

        setWorkTdSum(workSumm)

        let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

        setMiddleConv(middleConversion)

        let orderByDateArr = Object.entries(ordersByDate)

        let arr = []
        for (let i = 0; i < orderByDateArr.length; i++) {
            arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
        }

        let managers = {};

        for (let i = 0; i < arr.length; i++) {
            let key = arr[i][0];
            let orderCount = arr[i][1].orderCount;
            let winCount = arr[i][1].status.win;
            let failedCount = arr[i][1].status.failed;
            let incomCount = arr[i][1].status.incom;
            let workCount = arr[i][1].status.work;
            let ratingCount = arr[i][1].status.rating;


            if (managers[key]) {
                managers[key].orderCount += orderCount;
                managers[key].winCount += winCount;
                managers[key].failedCount += failedCount;
                managers[key].incomCount += incomCount;
                managers[key].workCount += workCount;
                managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                managers[key].ratingCount += ratingCount
            } else {
                managers[key] = {
                    orderCount: orderCount,
                    winCount: winCount,
                    failedCount: failedCount,
                    incomCount: incomCount,
                    workCount: workCount,
                    conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                    middleCashCount: (incomCount / winCount).toFixed(0),
                    ratingCount: ratingCount,
                };
            }

            // console.log(`ratingCount_${i}`, ratingCount)
        }

        let managersArray = Object.keys(managers).map(key => ({
            name: key,
            orderCount: managers[key].orderCount,
            winCount: managers[key].winCount,
            failedCount: managers[key].failedCount,
            incomCount: managers[key].incomCount,
            workCount: managers[key].workCount,
            conversion: managers[key].conversion,
            middleCashCount: managers[key].middleCashCount,
            ratingCount: (managers[key].ratingCount).toFixed(2),
        }));

        managersArray.sort((a, b) => b.incomCount - a.incomCount);

        setManagersInfo(managersArray)

        let sortedManagerNames = managersArray.map(manager => manager.name);

        setPeriodManagers(sortedManagerNames)

        setNoTableData(false)
        setPreloader(false)
    }

    useEffect(() => {
        const fetch = async () => {
            setPreloader(true)
            setAdvAll(0)
            setAdvValues([])
            setHoveredItem(null)

            getRating(sortOption).then(response => {
                // console.log('response', response?.params[0]);
                if (response?.params[0]) {
                    // setFormData(prev => ({
                    //     ...prev,  // Maintain the previous state
                    //     conversionRate: {
                    //         ...prev.conversionRate,  // Maintain the nested structure
                    //         ...response.params[0].conversionRate  // Update specific nested properties
                    //     },
                    //     successfulDeals: {
                    //         ...prev.successfulDeals,
                    //         ...response.params[0].successfulDeals
                    //     },
                    //     averageRevenue: {
                    //         ...prev.averageRevenue,
                    //         ...response.params[0].averageRevenue
                    //     }
                    // }));
                    setFormData(response?.params[0])
                }
            });

            let users = await getUser();
            setUsers(users)

            if (sortOption === '') {
                setSortOption("https://miamor.bitrix24.ru/")
            }

            if (startDate && endDate && sortOption) {
                // console.log('ordersByDate sortOption', sortOption)
                const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

                console.log('ordersByDate', ordersByDate)

                setDealsStatistics(Object.entries(ordersByDate))

                if (Object.entries(ordersByDate).length === 0) {
                    setNoTableData(true)
                }

                const incomes = Object.entries(ordersByDate)
                    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                    .map((item, index) => ({
                        dateBitrix: item[0],
                        incomeValue: item[1].orders.reduce((total, orderItem) => {
                            if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                ||
                                (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                            ) {
                                let sum = Math.round(Number(orderItem.sum));
                                let sentSum = Math.round(Number(orderItem.sentSum));
                                if (sum === 0 || sentSum === 0) {
                                    return total; // Return current total without adding anything if either sum or sentSum is 0
                                } else {
                                    return total + (sum - sentSum);
                                }
                            }
                            return total; // Return current total if conditions are not met
                        }, 0)
                    }));

                setIncomeValues(incomes);

                setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


                const datas = await dataFromNotifications()
                const data = datas.filter(item => item.link === sortOption);

                let advs = []
                let profits = []

                if (data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        advs[data[i].dateBitrix] = {
                            dateBitrix: data[i].dateBitrix,
                            currentAdv: Number(data[i].countAdv),
                            link: sortOption
                        };
                    }
                    setAdvValues(advs);

                    const filteredAdvs = Object.values(advs).filter(item => {
                        const itemDate = new Date(item?.dateBitrix);
                        const startDateObj = new Date(startDate);
                        const endDateObj = new Date(endDate);

                        return itemDate >= startDateObj && itemDate <= endDateObj;
                    });


                    const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

                    setAdvAll(totalCountAdv);

                    let filteredAdvsFull = []

                    for (let i = 0; i < filteredAdvs.length; i++) {
                        filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                            dateBitrix: filteredAdvs[i].dateBitrix,
                            currentAdv: Number(filteredAdvs[i].currentAdv),
                            link: sortOption
                        };
                    }

                    for (const adv of Object.entries(filteredAdvsFull)) {
                        const income = incomes.find(income => income?.dateBitrix === adv[0]);
                        if (income) {
                            profits[adv[0]] = {
                                dateBitrix: adv[0],
                                countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                            }
                            setProfitValues(profits);
                        }
                    }

                } else {
                    setAdvValues([]);
                    setProfitValues([]);
                }


                setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

                let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
                    return total + item[1].orders.length;
                }, 0);

                setAllTdSum(allSumm)

                let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === null);
                    return total + filteredOrders.length;
                }, 0);

                setWinTdSum(winSumm)

                let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === 'failed');
                    return total + filteredOrders.length;
                }, 0);

                setFailedTdSum(failedSumm)

                let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                    let filteredOrders = value.orders.filter(order => order.status === 'work');
                    return total + filteredOrders.length;
                }, 0);

                setWorkTdSum(workSumm)

                let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

                setMiddleConv(middleConversion)

                let orderByDateArr = Object.entries(ordersByDate)

                let arr = []
                for (let i = 0; i < orderByDateArr.length; i++) {
                    arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
                }

                let managers = {};

                for (let i = 0; i < arr.length; i++) {
                    let key = arr[i][0];
                    let orderCount = arr[i][1].orderCount;
                    let winCount = arr[i][1].status.win;
                    let failedCount = arr[i][1].status.failed;
                    let incomCount = arr[i][1].status.incom;
                    let workCount = arr[i][1].status.work;
                    let ratingCount = arr[i][1].status.rating;


                    if (managers[key]) {
                        managers[key].orderCount += orderCount;
                        managers[key].winCount += winCount;
                        managers[key].failedCount += failedCount;
                        managers[key].incomCount += incomCount;
                        managers[key].workCount += workCount;
                        managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                        let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                        managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                        managers[key].ratingCount += ratingCount
                    } else {
                        managers[key] = {
                            orderCount: orderCount,
                            winCount: winCount,
                            failedCount: failedCount,
                            incomCount: incomCount,
                            workCount: workCount,
                            conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                            middleCashCount: (incomCount / winCount).toFixed(0),
                            ratingCount: ratingCount,
                        };
                    }

                    // console.log(`ratingCount_${i}`, ratingCount)
                }

                let managersArray = Object.keys(managers).map(key => ({
                    name: key,
                    orderCount: managers[key].orderCount,
                    winCount: managers[key].winCount,
                    failedCount: managers[key].failedCount,
                    incomCount: managers[key].incomCount,
                    workCount: managers[key].workCount,
                    conversion: managers[key].conversion,
                    middleCashCount: managers[key].middleCashCount,
                    ratingCount: (managers[key].ratingCount).toFixed(2),
                }));

                managersArray.sort((a, b) => b.incomCount - a.incomCount);

                setManagersInfo(managersArray)

                let sortedManagerNames = managersArray.map(manager => manager.name);

                setPeriodManagers(sortedManagerNames)

                setPreloader(false)
                setNoTableData(false)
            }
        }
        fetch()
    }, [sortOption]);


    useEffect(() => {
        if (dataToNotif !== null) {
            countAdvBDNotification(dataToNotif)
                .then(data => {
                })
                .catch(error => {
                    console.error('Ошибка при редактировании расходов по рекламе:', error);
                });
        }
    }, [dataToNotif]);


    const handleInputChange = (e, index, dateSent) => {
        let advs = { ...advValues }; // Assuming advValues is an object

        let inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
            advs[dateSent] = {
                dateBitrix: dateSent,
                currentAdv: Number(inputValue),
                status: 'countAdv',
                link: linkBtx // Assuming linkBtx is defined in your context
            };

            setAdvValues(advs);
            // console.log('Updated advs:', advs);

            const data = {
                currentAdv: Number(inputValue),
                status: 'countAdv',
                dateBitrix: dateSent,
                link: linkBtx
            };

            setDataToNotif(data)
        }

        const filteredAdvs = Object.values(advs).filter(item => {
            const itemDate = new Date(item?.dateBitrix);
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(endDate);

            return itemDate >= startDateObj && itemDate <= endDateObj;
        });

        const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);


        setAdvAll(totalCountAdv);

        let profits = []
        let advsArr = Object.values(advs)

        let filteredAdvsFull = []

        for (let i = 0; i < advsArr.length; i++) {
            filteredAdvsFull[advsArr[i].dateBitrix] = {
                dateBitrix: advsArr[i].dateBitrix,
                currentAdv: Number(advsArr[i].currentAdv),
                link: sortOption
            };
        }

        for (const adv of Object.entries(filteredAdvsFull)) {
            const income = incomeValues.find(income => income?.dateBitrix === adv[0]);
            if (income) {
                profits[adv[0]] = {
                    dateBitrix: adv[0],
                    countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                }
                setProfitValues(profits);
            }
        }

        setProfitValues(profits);

        setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

    }

    const handleChangeSort = (orderBy) => {
        setSortOption(orderBy)
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    // const handleMouseEnter = (value) => {
    //     setHoveredItem(value);
    // };

    const handleClick = (itemKey) => {
        setHoveredItem(itemKey); // Установка текущего элемента при клике
    };

    const handleInputChangeKPI = (event, category, property) => {
        const { value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [property]: value
            }
        }));
    };

    const reverseDate = (date) => {
        if (date !== null) {
            return date.toString().split('-').reverse().join('.')
        }
    }

    const reverseDateWithOutYear = (date) => {
        if (date !== null) {
            return date.toString().split('-').reverse().join('.').slice(0, -5);
        }
    }

    const showManagersTables = async (item) => {
        let failedOrders = item[1].orders.filter(ord => ord.status === "failed");
        failedOrders.sort((a, b) => {
            return parseInt(b.idBitrix) - parseInt(a.idBitrix);
        });

        console.log('item showManagersTables', failedOrders);
        setLinkBtx(item[1].orders[0].link)

        if (!showTable) {
            setShowTable(true)
        } else {
            setShowTable(false)
        }
    }

    const showManagers = async () => {
        if (startDate && endDate && sortOption) {
            await getDataToTable()
        }
    }

    const updateRatio = async () => {
        if (startDate && endDate && sortOption) {
            // console.log('sortOption', sortOption)
            formData.link = sortOption
            await sendRating(formData)
            await getDataToTable()
        }
    }

    // const getDayReport = async () => {
    //     let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //     const nowDate = DateTime.now().setZone(timeZone);
    //     // const formattedCurrentDate = nowDate.toFormat('yyyy-MM-dd');
    //     const formattedCurrentDate = '2024-07-02'
    //
    //     if (formattedCurrentDate && sortOption) {
    //         console.log('ordersByDate formattedCurrentDate', formattedCurrentDate)
    //         const ordersByDate = await getCompanyOrdersByDatePeriod(formattedCurrentDate, formattedCurrentDate, sortOption)
    //
    //         console.log('ordersByDate getDayReport', Object.values(ordersByDate)[0].managers)
    //     }
    // }

    const getOrdersByDatePeriod = async (sortPeriod) => {
        setAdvValues([])
        setAdvAll(0)
        setPreloader(true)
        setHoveredItem(null)
        let startDate, endDate, formattedStartDate, nowDate;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        switch (sortPeriod) {
            case 'неделя':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 7 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case '10 дней':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 10 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'месяц':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 30 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'вчера':
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.minus({ days: 1 });
                formattedStartDate = startDate.toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                setStartDate(formattedStartDate); // Устанавливаем форматированную начальную дату
                setEndDate(endDate); // Устанавливаем форматированную конечную дату
                break;
            case 'текущий':
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                nowDate = DateTime.now().setZone(timeZone);
                startDate = nowDate.startOf('month').toFormat('yyyy-MM-dd');
                endDate = nowDate.toISODate();
                // console.log('formattedStartDate, endDate', formattedStartDate, endDate)
                setStartDate(startDate); // Устанавливаем дату начала текущего месяца
                setEndDate(endDate); // Устанавливаем текущую дату
                break;
            default:
                let now1 = DateTime.now().setZone(timeZone);
                let firstMonthDate1 = now1.startOf('month').toFormat('yyyy-MM-dd');
                let currentDate1 = now1.toISODate();
                setStartDate(firstMonthDate1);
                setEndDate(currentDate1);
        }

        if (startDate && endDate && sortOption) {
            const ordersByDate = await getCompanyOrdersByDatePeriod(startDate, endDate, sortOption, formData)

            setDealsStatistics(Object.entries(ordersByDate))

            // console.log('ordersByDate', ordersByDate)


            if (Object.entries(ordersByDate).length === 0) {
                setNoTableData(true)
            }

            const incomes = Object.entries(ordersByDate)
                .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                .map((item, index) => ({
                    dateBitrix: item[0],
                    incomeValue: item[1].orders.reduce((total, orderItem) => {
                        if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                            ||
                            (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                        ) {
                            let sum = Math.round(Number(orderItem.sum));
                            let sentSum = Math.round(Number(orderItem.sentSum));
                            if (sum === 0 || sentSum === 0) {
                                return total; // Return current total without adding anything if either sum or sentSum is 0
                            } else {
                                return total + (sum - sentSum);
                            }
                        }
                        return total; // Return current total if conditions are not met
                    }, 0)
                }));

            // const incomes = Object.entries(ordersByDate)
            //     .sort((a, b) => new Date(b[0]) - new Date(a[0]))
            //     .map((item, index) => ({
            //         dateBitrix: item[0],
            //         incomeValue: item[1].orders
            //             .filter(item => item.status === null &&
            //                 item.sentSum !== undefined && item.sentSum !== '' && item.sentSum !== null &&
            //                 item.sum !== undefined && item.sum !== '' && item.sum !== null &&
            //                 item.sum !== "0" && item.sentSum !== "0")
            //             .reduce((total, item) => {
            //                 let sum = Math.round(Number(item.sum));
            //                 let sentSum = Math.round(Number(item.sentSum));
            //                 if (sum === 0 || sentSum === 0) {
            //                     return total; // Return current total without adding anything if either sum or sentSum is 0
            //                 } else {
            //                     return total + (sum - sentSum);
            //                 }
            //             }, 0)
            //     }));

            setIncomeValues(incomes);

            setIncomeAll(incomes.reduce((total, item) => total + item.incomeValue, 0));


            const datas = await dataFromNotifications()
            const data = datas.filter(item => item.link === sortOption);

            let advs = []
            let profits = []

            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    advs[data[i].dateBitrix] = {
                        dateBitrix: data[i].dateBitrix,
                        currentAdv: Number(data[i].countAdv),
                        link: sortOption
                    };
                }
                setAdvValues(advs);

                const filteredAdvs = Object.values(advs).filter(item => {
                    const itemDate = new Date(item?.dateBitrix);
                    const startDateObj = new Date(startDate);
                    const endDateObj = new Date(endDate);

                    return itemDate >= startDateObj && itemDate <= endDateObj;
                });

                const totalCountAdv = filteredAdvs.reduce((total, item) => total + item.currentAdv, 0);

                setAdvAll(totalCountAdv);

                let filteredAdvsFull = []

                for (let i = 0; i < filteredAdvs.length; i++) {
                    filteredAdvsFull[filteredAdvs[i].dateBitrix] = {
                        dateBitrix: filteredAdvs[i].dateBitrix,
                        currentAdv: Number(filteredAdvs[i].currentAdv),
                        link: sortOption
                    };
                }

                for (const adv of Object.entries(filteredAdvsFull)) {
                    const income = incomes.find(income => income?.dateBitrix === adv[0]);
                    if (income) {
                        profits[adv[0]] = {
                            dateBitrix: adv[0],
                            countAdv: Number(income.incomeValue) - Number(adv[1].currentAdv)
                        }
                        setProfitValues(profits);
                    }
                }
            } else {
                setAdvValues([]);
                setProfitValues([]);
            }


            setProfitAll(Object.values(profits).reduce((total, item) => total + item.countAdv, 0));

            let allSumm = Object.entries(ordersByDate).reduce((total, item) => {
                return total + item[1].orders.length;
            }, 0);

            setAllTdSum(allSumm)

            let winSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === null);
                return total + filteredOrders.length;
            }, 0);

            setWinTdSum(winSumm)

            let failedSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === 'failed');
                return total + filteredOrders.length;
            }, 0);

            setFailedTdSum(failedSumm)

            let workSumm = Object.entries(ordersByDate).reduce((total, [key, value]) => {
                let filteredOrders = value.orders.filter(order => order.status === 'work');
                return total + filteredOrders.length;
            }, 0);

            setWorkTdSum(workSumm)

            let middleConversion = `${(winSumm * 100 / allSumm).toFixed(2)}%`

            setMiddleConv(middleConversion)

            let orderByDateArr = Object.entries(ordersByDate)

            let arr = []
            for (let i = 0; i < orderByDateArr.length; i++) {
                arr = arr.concat(Object.entries(orderByDateArr[i][1].managers));
            }

            let managers = {};

            for (let i = 0; i < arr.length; i++) {
                let key = arr[i][0];
                let orderCount = arr[i][1].orderCount;
                let winCount = arr[i][1].status.win;
                let failedCount = arr[i][1].status.failed;
                let incomCount = arr[i][1].status.incom;
                let workCount = arr[i][1].status.work;
                let ratingCount = arr[i][1].status.rating;


                if (managers[key]) {
                    managers[key].orderCount += orderCount;
                    managers[key].winCount += winCount;
                    managers[key].failedCount += failedCount;
                    managers[key].incomCount += incomCount;
                    managers[key].workCount += workCount;
                    managers[key].conversion = `${(managers[key].winCount * 100 / managers[key].orderCount).toFixed(2)}%`;
                    let middleCash = (managers[key].incomCount / managers[key].winCount).toFixed(0);
                    managers[key].middleCashCount = isNaN(middleCash) ? '0' : middleCash;
                    managers[key].ratingCount += ratingCount
                } else {
                    managers[key] = {
                        orderCount: orderCount,
                        winCount: winCount,
                        failedCount: failedCount,
                        incomCount: incomCount,
                        workCount: workCount,
                        conversion: `${(winCount * 100 / orderCount).toFixed(2)}%`,
                        middleCashCount: (incomCount / winCount).toFixed(0),
                        ratingCount: ratingCount,
                    };
                }

                // console.log(`ratingCount_${i}`, ratingCount)
            }

            let managersArray = Object.keys(managers).map(key => ({
                name: key,
                orderCount: managers[key].orderCount,
                winCount: managers[key].winCount,
                failedCount: managers[key].failedCount,
                incomCount: managers[key].incomCount,
                workCount: managers[key].workCount,
                conversion: managers[key].conversion,
                middleCashCount: managers[key].middleCashCount,
                ratingCount: (managers[key].ratingCount).toFixed(2),
            }));

            managersArray.sort((a, b) => b.incomCount - a.incomCount);

            setManagersInfo(managersArray)

            let sortedManagerNames = managersArray.map(manager => manager.name);

            setPeriodManagers(sortedManagerNames)

            setPreloader(false)
            setNoTableData(false)
        }
    };


    const getNextDayISOString = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1); // Adding 1 day
        return currentDate.toISOString().split('T')[0];
    }

    const sentGetBitrix = async () => {
        const password = prompt('Введите пароль для запуска синхронизации:');
        if (password === '0111') {
            let result = await Promise.race([
                getBitrix(),
                new Promise((resolve, reject) => setTimeout(() => reject(new Error('Timeout')), 30000))
            ]);
            alert('Синхронизация запущена!');
            console.log("result", result)
        } else {
            alert('Неверный пароль или отмена.');
        }
    };

    const getDayReport = (link, test) => {
        if (test === "botAuto") {
            const password = prompt('Введите пароль для запуска синхронизации:');
            if (password === '0111') {
                const sent = {
                    link: link,
                    test: test
                }
                callGetDayReport(sent)
                    .then((response) => {
                        console.log("Ответ в консоли:", response);
                    })
                    .catch((error) => {
                        console.error("Произошла ошибка при вызове callGetDayReport:", error);
                    });
            } else {
                alert('Неверный пароль или отмена.');
            }
        }
        if (test === "test") {
            const sent = {
                link: link,
                test: test
            }
            callGetDayReport(sent)
                .then((response) => {
                    console.log("Ответ в консоли:", response);
                })
                .catch((error) => {
                    console.error("Произошла ошибка при вызове callGetDayReport:", error);
                });
        }
    }

    const [maxWidth, setMaxWidth] = useState(null);
    const [maxWidthIncom, setMaxWidthIncom] = useState(null);

    useEffect(() => {
        const elements = document.getElementsByClassName('rating_el');
        let max = 65;
        for (let i = 0; i < elements.length; i++) {
            const elementWidth = elements[i].offsetWidth;
            console.log('elementWidth', elementWidth)
            if (elementWidth > max) {
                max = elementWidth;
            }
        }
        setMaxWidth(max);

        const elementsIncom = document.getElementsByClassName('incom_el');
        let maxIncom =58;
        for (let i = 0; i < elementsIncom.length; i++) {
            const elementWidth = elementsIncom[i].offsetWidth;
            console.log('elementWidth', elementWidth)
            if (elementWidth > max) {
                max = elementWidth;
            }
        }
        setMaxWidthIncom(maxIncom);
    }, []);

    const sentPostBd = () => {
        postBd()
    }

    const sentClearBd = () => {
        clearBd()
    }

    const sentCreateComponyWordPressBd = () => {
        createComponyWordPress()
    }

    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <div
                        className="left_links"
                        onClick={() => {
                            localStorage.removeItem('scroll_order');
                        }}
                    >
                        <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                        <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                    </div>
                    <div className="deals">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div className="shop_wrapper">
                            <div className="select__wrapper">
                                Выбрать магазин
                                <Form.Select name="orderby" size="sm" className="orderby" aria-label="Магазин" onChange={(e) => {
                                    handleChangeSort(e.target.value)
                                }} value={sortOption}>
                                    <option value="">Выберите магазин</option>
                                    <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                                    <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                                    <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                                    <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className="date_wrapper">
                            <label>Дата начала:</label>
                            <Form.Control
                                size="sm"
                                type="date"
                                value={startDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                                className="date_wrapper__item"
                            />

                            <label className="date_wrapper__label">Дата окончания:</label>
                            <Form.Control
                                size="sm"
                                type="date"
                                value={endDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                                className="date_wrapper__item"
                            />

                            <Button size="sm"
                                    className="date_wrapper__button"
                                onClick={showManagers}>Применить</Button>
                        </div>
                        <div className="period_wrapper">
                            <Form.Select id="sortPeriod" size="sm" onChange={(e) => {
                                getOrdersByDatePeriod(e.target.value).then()
                            }}>
                                <option value="текущий">Текущий</option>
                                <option value="неделя">За неделю</option>
                                <option value="10 дней">За 10 дней</option>
                                <option value="месяц">За месяц</option>
                                <option value="вчера">За вчера</option>
                            </Form.Select>
                        </div>
                        <div className="tech_wrapper">


                            {/*<button onClick={() => {*/}
                            {/*    sentGetBitrix()*/}
                            {/*}}>Синхронизация*/}
                            {/*</button>*/}

                            {/*<button*/}

                            {/*    onClick={() => {*/}
                            {/*        getDayReport(sortOption)*/}
                            {/*    }}>Отчет в бота</button>*/}
                            {/*<button style={{marginLeft: "10px"}}>Отчет за неделю</button>*/}
                            {/*<button style={{marginLeft: "10px"}}>Отчет за месяц</button>*/}

                            <Stack direction="horizontal" gap={2}>
                                <Button size="sm" as="a" variant="primary" onClick={() => {
                                    sentGetBitrix()
                                }}>
                                    <span style={{ color: "#ffffff" }}>старт синхронизация</span>
                                </Button>
                                <Button size="sm" as="a"  variant="success" onClick={() => {
                                    getDayReport(sortOption, "botAuto")
                                }}>
                                    <span style={{ color: "#ffffff" }}>старт отчет в бота</span>
                                </Button>
                                <Button size="sm" as="a"  variant="success" onClick={() => {
                                    getDayReport(sortOption, "test")
                                }}>
                                    <span style={{ color: "#ffffff" }}>Отчет в анализ сделок</span>
                                </Button>
                            </Stack>
                        </div>
                        {/*<button onClick={() => {*/}
                        {/*    sentPostBd()*/}
                        {/*}}>создаем базу из файлов</button>*/}

                        {/*<button onClick={() => {*/}
                        {/*    sentClearBd()*/}
                        {/*}}>чистим базу</button>*/}

                        {/*<button onClick={() => {*/}
                        {/*    sentCreateComponyWordPressBd()*/}
                        {/*}}>слияние базы</button>*/}
                        <div
                            // style={{display: "flex", position: "relative"}}
                        >
                            {/*<div*/}
                            {/*    className="wide-table-wrapper deals-wide-table-wrapper fixed-wide-table-wrapper"*/}
                            {/*    style={{*/}
                            {/*        display: 'flex', top: "0",*/}
                            {/*        position: "absolute",*/}
                            {/*        zIndex: "3"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Table size="sm"*/}
                            {/*    //     style={{*/}
                            {/*    //     width: "100%", display: 'flex', flexDirection: "column",*/}
                            {/*    // }} className="wide-table"*/}
                            {/*        bordered*/}
                            {/*        style={{ borderColor: 'black', fontSize: "0.7rem", marginBottom: "0" }}*/}
                            {/*    >*/}
                            {/*        <thead style={{ height: "78.9px" }}>*/}
                            {/*        <tr*/}
                            {/*            // style={{height: "49px", display: "table-row"}}*/}
                            {/*        >*/}
                            {/*            <th rowSpan="2"*/}
                            {/*                style={{*/}
                            {/*                    // height: "47.5px",*/}
                            {/*                    // width: "69px",*/}
                            {/*                    // padding: "0 2px"*/}
                            {/*                }}*/}
                            {/*            >{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>*/}
                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        {*/}
                            {/*            dealsStatistics*/}
                            {/*                .sort((a, b) => new Date(b[0]) - new Date(a[0]))*/}
                            {/*                .map((item, index) => {*/}
                            {/*                    const filteredOrders = item[1].orders.filter(order => order.status === null);*/}
                            {/*                    if (filteredOrders.length === 0) {*/}
                            {/*                        return null;*/}
                            {/*                    }*/}

                            {/*                    return (*/}
                            {/*                        <tr*/}
                            {/*                            key={index}*/}
                            {/*                            // className={`main_tr`}*/}
                            {/*                            // onMouseEnter={() => handleMouseEnter(item[0])}*/}
                            {/*                            onClick={() => handleClick(item[0])}*/}

                            {/*                        >*/}
                            {/*                            <td*/}
                            {/*                                style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent', height: "38.4px" }}*/}
                            {/*                                // style={{ height: "47.8px" }}*/}
                            {/*                                // className="city_item city_item_fixed"*/}
                            {/*                            >{reverseDateWithOutYear(item[0])}</td>*/}
                            {/*                        </tr>*/}
                            {/*                    )*/}
                            {/*                })*/}
                            {/*        }*/}
                            {/*        </tbody>*/}
                            {/*    </Table>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    // className="wide-table-wrapper deals-wide-table-wrapper"*/}
                            {/*    // style={{display: 'flex'}}*/}
                            {/*>*/}
                                <Table size="sm"
                                    // className="wide-table"
                                    responsive
                                    bordered
                                       className="deals_table"
                                >
                                    <thead>
                                    <tr>
                                        <th className="first_th center_text" scope="col" rowSpan="2">{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                        <th className="center_text" colSpan="4">Общие показатели</th>
                                        <th className="center_text" >{middleConv}</th>
                                        <th className="center_text" >{incomeAll}</th>
                                        <th className="center_text" >{advAll}</th>
                                        <th
                                            style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}
                                            className="center_text"
                                        >{profitAll}</th>
                                        {
                                            periodManagers.map((manager, i) => {
                                                return (
                                                    <th key={i} className="th_padding">
                                                        <div className="container">
                                                            <div className="row flex-nowrap row_dop">
                                                                <div className="col-2 col_text" style={{  height: "37px", width: "174px"}}>
                                                                    {users &&
                                                                        users.map(user => {
                                                                            if (user.email === manager) {
                                                                                return user.nameTitle;
                                                                            }
                                                                            return null
                                                                        })}
                                                                </div>
                                                                <div className="col col_text" style={{backgroundColor: 'rgba(250,243,155,0.42)', height: "37px", width: "69px"}}>
                                                                    <div>Conv</div>
                                                                </div>
                                                                <div className="col col_text incom_el" style={{backgroundColor: 'rgba(250,169,114,0.42)', height: "37px", width: "65px"}}>
                                                                    <div>Доход</div>
                                                                </div>
                                                                <div className="col col_text" style={{backgroundColor: 'rgba(166,205,241,0.42)', height: "37px"}}>
                                                                    <div>Ср.чек</div>
                                                                </div>
                                                                <div className="col col_text rating_el" style={{backgroundColor: 'rgba(155,153,153,0.35)', height: "37px", width: "65px"}}>
                                                                    <div>Рейтинг</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        <th className="th_head" >Всего</th>
                                        <th className="th_head">Успех</th>
                                        <th className="th_head">Работа</th>
                                        <th className="th_head">Провал</th>
                                        <th className="th_head">Конверсия</th>
                                        <th className="th_head">Доход</th>
                                        <th className="th_head">Реклама</th>
                                        <th className="th_head">Прибыль</th>
                                        {
                                            managersInfo.map((manager, i) => {
                                                return (
                                                    <th key={i} className="th_padding_managers">
                                                        <div className="row">
                                                            <div className="col-custom-width__all p-0" style={{ padding: ".2rem !important" }}>
                                                                <div className="p2 text-center">{manager.orderCount}</div>
                                                            </div>
                                                            <div className="col-custom-width__all p-0" style={{ backgroundColor: 'rgba(152,234,119,0.42)', padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.winCount}</div>
                                                            </div>
                                                            <div className="col-custom-width__all p-0" style={{ backgroundColor: 'rgba(169,175,248,0.42)', padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.workCount}</div>
                                                            </div>
                                                            <div className="col-custom-width__all p-0" style={{ backgroundColor: 'rgba(248,101,101,0.42)', padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.failedCount}</div>
                                                            </div>
                                                            <div className="col-custom-width p-0" style={{ backgroundColor: 'rgba(250,243,155,0.42)',
                                                                // width: '530px',
                                                                padding: ".2rem !important" }}>
                                                                <div className="p2 text-center">{manager.conversion}</div>
                                                            </div>
                                                            <div className="col p-0 incom_el" style={{ backgroundColor: 'rgba(250,169,114,0.42)', width: "65px", padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.incomCount}</div>
                                                            </div>
                                                            <div className="col p-0" style={{ backgroundColor: 'rgba(166,205,241,0.42)', padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.middleCashCount}</div>
                                                            </div>
                                                            <div className="col p-0 rating_el" style={{ backgroundColor: 'rgba(155,153,153,0.35)', width: "65px", padding: ".2rem !important"}}>
                                                                <div className="p2 text-center">{manager.ratingCount}</div>
                                                            </div>
                                                        </div>
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        dealsStatistics
                                            .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                            .map((item, index) => {
                                                const filteredOrders = item[1].orders
                                                    .filter(order => order.status === null
                                                        // &&
                                                        // order.sentSum !== undefined && order.sentSum !== '' && order.sentSum !== null &&
                                                        // order.sum !== undefined && order.sum !== '' && order.sum !== null &&
                                                        // order.sum !== "0" && order.sentSum !== "0"
                                                    );
                                                if (filteredOrders.length === 0) {
                                                    return null;
                                                }

                                                const incomeReduce = item[1].orders.reduce((total, orderItem) => {
                                                    if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                                        ||
                                                        (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                                                    ) {
                                                        let sum = Math.round(Number(orderItem.sum));
                                                        let sentSum = Math.round(Number(orderItem.sentSum));
                                                        if (sum === 0 || sentSum === 0) {
                                                            return total; // Return current total without adding anything if either sum or sentSum is 0
                                                        } else {
                                                            return total + (sum - sentSum);
                                                        }
                                                    }
                                                    return total; // Return current total if conditions are not met
                                                }, 0)

                                                // console.log('incomeReduce', incomeReduce)

                                                return (
                                                    <tr
                                                        className="main_tr"
                                                        style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent'}}
                                                        key={index} onClick={() => {
                                                        showManagersTables(item).then();
                                                        handleClick(item[0]);
                                                    }}
                                                        // onMouseEnter={() => handleMouseEnter(item[0])}
                                                    >
                                                        <td
                                                            // className="city_item"
                                                            className="first_th" scope="col" style={{ backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : '#ffffff', textAlign: "center", verticalAlign: "middle" }}
                                                        >{reverseDateWithOutYear(item[0])}</td>
                                                        <td
                                                            style={{ backgroundColor: 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                        >{item[1].orders.length}</td>
                                                        <td
                                                            style={{
                                                                backgroundColor: 'rgba(152,234,119,0.42)',
                                                                textAlign: "center",
                                                                verticalAlign: "middle",
                                                                position: 'relative'
                                                            }}
                                                        >
                                                            {filteredOrders.length}
                                                            {(() => {
                                                                const countSentSum = filteredOrders.filter(order =>
                                                                    order.sentSum === "0" ||
                                                                    order.sentSum === null
                                                                ).length;

                                                                const countSun = filteredOrders.filter(order =>
                                                                    order.sum === "0" ||
                                                                    order.sum === null
                                                                ).length;

                                                                if (filteredOrders.some(order =>
                                                                    order.sentSum === "0" ||
                                                                    order.sentSum === null ||
                                                                    order.sum === "0" ||
                                                                    order.sum === null)) {
                                                                    return (
                                                                        <Image
                                                                            src="./img/tri.png"
                                                                            alt="Треугольник"
                                                                            title={`Не заполнена оплата в магазин: ${countSentSum}\nНет суммы сделки: ${countSun}`}
                                                                            style={{
                                                                                width: "10px",
                                                                                height:"6px",
                                                                                position: "absolute", // Делаем позиционирование абсолютным
                                                                                top: "-2px", // Расстояние от верхнего края
                                                                                right: "-4px", // Расстояние от правого края
                                                                                transform: "rotate(45deg)", // Поворачиваем изображение на 45 градусов
                                                                                cursor: "pointer"
                                                                            }}
                                                                        />
                                                                    );
                                                                }
                                                                return null;
                                                            })()}
                                                        </td>

                                                        <td
                                                            style={{backgroundColor: 'rgba(169,175,248,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                            // className="work_item"
                                                        >
                                                            {item[1].orders.filter(order => order.status === 'work').length}
                                                        </td>
                                                        <td
                                                            style={{backgroundColor: 'rgba(248,101,101,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                        >
                                                            {item[1].orders.filter(order => order.status === 'failed').length}
                                                        </td>
                                                        <td
                                                            style={{backgroundColor: 'rgba(250,243,155,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                        >
                                                            {`${(filteredOrders.length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                        </td>
                                                        <td
                                                            style={{ backgroundColor: 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                        >
                                                            {/*{filteredOrders.reduce((total, order) => total + Math.round(Number(order.sum) - Math.round(Number(order.sentSum))), 0)}*/}
                                                            {incomeReduce}
                                                        </td>
                                                        <td
                                                            // className="advTd"
                                                            style={{ backgroundColor: 'transparent', verticalAlign: "middle" }}
                                                            id={`${item[0]}`}>
                                                            <Form.Control
                                                                style={{maxWidth: "86px", padding: ".15rem .25rem", fontSize: "12px"}}
                                                                value={advValues[item[0]] ? advValues[item[0]].currentAdv : ''}
                                                                onChange={(e) => handleInputChange(e, index, item[0])}
                                                            />
                                                        </td>
                                                        <td
                                                            style={{backgroundColor: profitValues[item[0]]?.countAdv < 0 ? 'rgba(250,0,0,0.42)' : 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                        >
                                                            {profitValues[item[0]] ? profitValues[item[0]]?.countAdv : '--'}
                                                        </td>
                                                        {
                                                            periodManagers.map((manager, j) => (
                                                                <td key={`manager_${j}`} style={{ padding: "0.2rem 0.9rem 0.1rem", backgroundColor: "transparent" }}>
                                                                    {
                                                                        Object.entries(item[1].managers).map((item, idxNum) => {
                                                                            if (manager === item[0]) {
                                                                                return (

                                                                                    <div key={`manager_${j}_${idxNum}`} className="row">
                                                                                        <div className="col-custom-width__all p-0">
                                                                                            <div className="p2 text-center" style={{ padding: ".2rem !important" }}>
                                                                                                {item[1].orderCount}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-custom-width__all p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(152,234,119,0.42)', padding: ".2rem !important" }}>
                                                                                                {item[1].status.win}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-custom-width__all p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(169,175,248,0.42)', padding: ".2rem !important" }}>
                                                                                                {item[1].status.work}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-custom-width__all p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(248,101,101,0.42)', padding: ".2rem !important"}}>
                                                                                                {item[1].status.failed}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-custom-width p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(250,243,155,0.42)', padding: ".2rem !important"}}>
                                                                                                {item[1].status.conversion}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(250,169,114,0.42)', padding: ".2rem !important"}}>
                                                                                                {item[1].status.incom}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(166,205,241,0.42)', padding: ".2rem !important" }}>
                                                                                                {item[1].status.middleCash}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col p-0">
                                                                                            <div className="p2 text-center" style={{ backgroundColor: 'rgba(155,153,153,0.35)', padding: ".2rem !important"}}>
                                                                                                {item[1].status.rating}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                );
                                            })
                                    }
                                    <tr>
                                        <th className="first_th" scope="col" style={{ textAlign: "center" }}>Итого</th>
                                        <th style={{ textAlign: "center" }}>{allTdSum}</th>
                                        <th style={{ textAlign: "center" }}>{winTdSum}</th>
                                        <th style={{ textAlign: "center" }}>{workTdSum}</th>
                                        <th style={{ textAlign: "center" }}>{failedTdSum}</th>
                                    </tr>
                                    </tbody>
                                </Table>
                            {/*</div>*/}
                        </div>
                        <div style={{marginTop: "20px", display: "flex"}}>
                            <Table size="sm" responsive bordered style={{ maxWidth: '55%', fontSize: "0.7rem" }} className="tableKPI"
                            >
                                <thead>
                                <tr>
                                    <th rowSpan="2">KPI</th>
                                    <th rowSpan="2">Вес <br/> показателя</th>
                                    <th colSpan="3">Значения</th>
                                </tr>
                                <tr>
                                    <th>База (если ниже, то работа не эффективна)</th>
                                    <th>Норма (значение, при котором поддерживается положительный баланс)</th>
                                    <th style={{ maxWidth: "50px" }}>Цель</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>Конверсия в продажу, %</th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }} value={formData.conversionRate.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'weight')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'base')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'norm')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'goal')}/></th>
                                </tr>
                                <tr>
                                    <th>Успешные сделки, шт</th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'weight')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'base')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'norm')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'goal')}/></th>
                                </tr>
                                <tr>
                                    <th>Средний чек прибыли, руб</th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.weight}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'weight')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.base}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'base')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.norm}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'norm')}/></th>
                                    <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.goal}
                                               onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'goal')}/></th>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                        <Button size="sm" style={{marginTop: "20px"}} onClick={updateRatio}>Применить</Button>
                        <div
                            style={{display: noTableData ? 'block' : 'none', marginTop: '20px', fontWeight: "bold"}}>Нет
                            данных
                        </div>
                    </div>
                </>
            );

        case 'buh':
            return (
                <>
                    <div
                        className="left_links"
                        onClick={() => {
                            localStorage.removeItem('scroll_order');
                        }}
                    >
                        <Link to={`/statistics`} style={show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                        <Link to={`/statistics`} style={!show ? {display: "flex"} : {display: "none"}}>
                            <img src={leftImg} className="logo_img" width="25px" height="25px"/>
                        </Link>
                    </div>
                    <div className="deals">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div className="shop_wrapper">
                            <div className="select__wrapper">
                                Выбрать магазин
                                <Form.Select name="orderby" size="sm" className="orderby" aria-label="Магазин" onChange={(e) => {
                                    handleChangeSort(e.target.value)
                                }} value={sortOption}>
                                    <option value="">Выберите магазин</option>
                                    <option value="https://tsvetochnyi.bitrix24.ru/">Цветочный</option>
                                    <option value="https://miamor.bitrix24.ru/">Ми-амор</option>
                                    <option value="https://sladkoeshka.bitrix24.ru/">Сладкоешка</option>
                                    <option value="https://kami-flow.bitrix24.ru/">Камелия</option>
                                </Form.Select>
                            </div>
                        </div>
                        <div className="date_wrapper">
                            <label>Дата начала:</label>
                            <Form.Control
                                size="sm"
                                type="date"
                                value={startDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleStartDateChange(e.target.value)}
                                className="date_wrapper__item"
                            />

                            <label className="date_wrapper__label">Дата окончания:</label>
                            <Form.Control
                                size="sm"
                                type="date"
                                value={endDate}
                                max={getNextDayISOString()}
                                onChange={(e) => handleEndDateChange(e.target.value)}
                                className="date_wrapper__item"
                            />

                            <Button size="sm"
                                    className="date_wrapper__button"
                                    onClick={showManagers}>Применить</Button>
                        </div>
                        <div className="period_wrapper">
                            <Form.Select id="sortPeriod" size="sm" onChange={(e) => {
                                getOrdersByDatePeriod(e.target.value).then()
                            }}>
                                <option value="текущий">Текущий</option>
                                <option value="неделя">За неделю</option>
                                <option value="10 дней">За 10 дней</option>
                                <option value="месяц">За месяц</option>
                                <option value="вчера">За вчера</option>
                            </Form.Select>
                        </div>
                        <div className="tech_wrapper">


                            {/*<button onClick={() => {*/}
                            {/*    sentGetBitrix()*/}
                            {/*}}>Синхронизация*/}
                            {/*</button>*/}

                            {/*<button*/}

                            {/*    onClick={() => {*/}
                            {/*        getDayReport(sortOption)*/}
                            {/*    }}>Отчет в бота</button>*/}
                            {/*<button style={{marginLeft: "10px"}}>Отчет за неделю</button>*/}
                            {/*<button style={{marginLeft: "10px"}}>Отчет за месяц</button>*/}

                            <Stack direction="horizontal" gap={2}>
                                <Button size="sm" as="a" variant="primary" onClick={() => {
                                    sentGetBitrix()
                                }}>
                                    <span style={{ color: "#ffffff" }}>Синхронизация</span>
                                </Button>
                                <Button size="sm" as="a"  variant="success" onClick={() => {
                                    getDayReport(sortOption, "botAuto")
                                }}>
                                    <span style={{ color: "#ffffff" }}>Отчет в бота</span>
                                </Button>
                                <Button size="sm" as="a"  variant="success" onClick={() => {
                                    getDayReport(sortOption, "test")
                                }}>
                                    <span style={{ color: "#ffffff" }}>Отчет в анализ сделок</span>
                                </Button>
                            </Stack>
                        </div>
                        <div
                            // style={{display: "flex", position: "relative"}}
                        >
                            {/*<div*/}
                            {/*    className="wide-table-wrapper deals-wide-table-wrapper fixed-wide-table-wrapper"*/}
                            {/*    style={{*/}
                            {/*        display: 'flex', top: "0",*/}
                            {/*        position: "absolute",*/}
                            {/*        zIndex: "3"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Table size="sm"*/}
                            {/*    //     style={{*/}
                            {/*    //     width: "100%", display: 'flex', flexDirection: "column",*/}
                            {/*    // }} className="wide-table"*/}
                            {/*        bordered*/}
                            {/*        style={{ borderColor: 'black', fontSize: "0.7rem", marginBottom: "0" }}*/}
                            {/*    >*/}
                            {/*        <thead style={{ height: "78.9px" }}>*/}
                            {/*        <tr*/}
                            {/*            // style={{height: "49px", display: "table-row"}}*/}
                            {/*        >*/}
                            {/*            <th rowSpan="2"*/}
                            {/*                style={{*/}
                            {/*                    // height: "47.5px",*/}
                            {/*                    // width: "69px",*/}
                            {/*                    // padding: "0 2px"*/}
                            {/*                }}*/}
                            {/*            >{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>*/}
                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        {*/}
                            {/*            dealsStatistics*/}
                            {/*                .sort((a, b) => new Date(b[0]) - new Date(a[0]))*/}
                            {/*                .map((item, index) => {*/}
                            {/*                    const filteredOrders = item[1].orders.filter(order => order.status === null);*/}
                            {/*                    if (filteredOrders.length === 0) {*/}
                            {/*                        return null;*/}
                            {/*                    }*/}

                            {/*                    return (*/}
                            {/*                        <tr*/}
                            {/*                            key={index}*/}
                            {/*                            // className={`main_tr`}*/}
                            {/*                            // onMouseEnter={() => handleMouseEnter(item[0])}*/}
                            {/*                            onClick={() => handleClick(item[0])}*/}

                            {/*                        >*/}
                            {/*                            <td*/}
                            {/*                                style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent', height: "38.4px" }}*/}
                            {/*                                // style={{ height: "47.8px" }}*/}
                            {/*                                // className="city_item city_item_fixed"*/}
                            {/*                            >{reverseDateWithOutYear(item[0])}</td>*/}
                            {/*                        </tr>*/}
                            {/*                    )*/}
                            {/*                })*/}
                            {/*        }*/}
                            {/*        </tbody>*/}
                            {/*    </Table>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    // className="wide-table-wrapper deals-wide-table-wrapper"*/}
                            {/*    // style={{display: 'flex'}}*/}
                            {/*>*/}
                            <Table size="sm"
                                // className="wide-table"
                                   responsive
                                   bordered
                                   className="deals_table"
                            >
                                <thead>
                                <tr>
                                    <th className="first_th center_text" scope="col" rowSpan="2">{`${reverseDate(startDate)}`} <br/> {`${reverseDate(endDate)}`}</th>
                                    <th className="center_text" colSpan="4">Общие показатели</th>
                                    <th className="center_text" >{middleConv}</th>
                                    <th className="center_text" >{incomeAll}</th>
                                    <th className="center_text" >{advAll}</th>
                                    <th
                                        style={{backgroundColor: profitAll < 0 ? 'rgba(250,0,0,0.42)' : 'transparent'}}
                                        className="center_text"
                                    >{profitAll}</th>
                                    {
                                        periodManagers.map((manager, i) => {
                                            return (
                                                <th key={i} className="th_padding">
                                                    <div className="container">
                                                        <div className="row flex-nowrap row_dop">
                                                            <div className="col-2 col_text" style={{  height: "37px", width: "265px"}}>
                                                                {users &&
                                                                    users.map(user => {
                                                                        if (user.email === manager) {
                                                                            return user.nameTitle;
                                                                        }
                                                                        return null
                                                                    })}
                                                            </div>
                                                            <div className="col col_text" style={{backgroundColor: 'rgba(250,243,155,0.42)', height: "37px"}}>
                                                                <div>Conv</div>
                                                            </div>
                                                            <div className="col col_text incom_el" style={{backgroundColor: 'rgba(250,169,114,0.42)', height: "37px", width: maxWidthIncom}}>
                                                                <div>Доход</div>
                                                            </div>
                                                            <div className="col col_text" style={{backgroundColor: 'rgba(166,205,241,0.42)', height: "37px"}}>
                                                                <div>Ср.чек</div>
                                                            </div>
                                                            <div className="col col_text rating_el" style={{backgroundColor: 'rgba(155,153,153,0.35)', height: "37px", width: maxWidth}}>
                                                                <div>Рейтинг</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            )
                                        })
                                    }
                                </tr>
                                <tr>
                                    <th className="th_head" >Всего</th>
                                    <th className="th_head">Успех</th>
                                    <th className="th_head th_head_work">В работе</th>
                                    <th className="th_head">Провал</th>
                                    <th className="th_head">Конверсия</th>
                                    <th className="th_head">Доход</th>
                                    <th className="th_head">Реклама</th>
                                    <th className="th_head">Прибыль</th>
                                    {
                                        managersInfo.map((manager, i) => {
                                            return (
                                                <th key={i} className="th_padding_managers">
                                                    <div className="row">
                                                        <div className="col p-0" style={{ padding: ".2rem !important" }}>
                                                            <div className="p2 text-center">{manager.orderCount}</div>
                                                        </div>
                                                        <div className="col p-0" style={{ backgroundColor: 'rgba(152,234,119,0.42)', padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.winCount}</div>
                                                        </div>
                                                        <div className="col p-0" style={{ backgroundColor: 'rgba(169,175,248,0.42)', padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.workCount}</div>
                                                        </div>
                                                        <div className="col p-0" style={{ backgroundColor: 'rgba(248,101,101,0.42)', padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.failedCount}</div>
                                                        </div>
                                                        <div className="col p-0" style={{ backgroundColor: 'rgba(250,243,155,0.42)', width: '520px', padding: ".2rem !important" }}>
                                                            <div className="p2 text-center">{manager.conversion}</div>
                                                        </div>
                                                        <div className="col p-0 incom_el" style={{ backgroundColor: 'rgba(250,169,114,0.42)', width: maxWidthIncom, padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.incomCount}</div>
                                                        </div>
                                                        <div className="col p-0" style={{ backgroundColor: 'rgba(166,205,241,0.42)', padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.middleCashCount}</div>
                                                        </div>
                                                        <div className="col p-0 rating_el" style={{ backgroundColor: 'rgba(155,153,153,0.35)', width: maxWidth, padding: ".2rem !important"}}>
                                                            <div className="p2 text-center">{manager.ratingCount}</div>
                                                        </div>
                                                    </div>
                                                </th>
                                            );
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    dealsStatistics
                                        .sort((a, b) => new Date(b[0]) - new Date(a[0]))
                                        .map((item, index) => {
                                            const filteredOrders = item[1].orders
                                                .filter(order => order.status === null
                                                    // &&
                                                    // order.sentSum !== undefined && order.sentSum !== '' && order.sentSum !== null &&
                                                    // order.sum !== undefined && order.sum !== '' && order.sum !== null &&
                                                    // order.sum !== "0" && order.sentSum !== "0"
                                                );
                                            if (filteredOrders.length === 0) {
                                                return null;
                                            }

                                            const incomeReduce = item[1].orders.reduce((total, orderItem) => {
                                                if ((orderItem.status === null && orderItem.sentSum !== '0' && orderItem.sentSum !== null && orderItem.sentSum !== '' && orderItem.sentSum !== undefined)
                                                    ||
                                                    (orderItem.status === null && orderItem.sum !== '0' && orderItem.sum !== null && orderItem.sum !== '' && orderItem.sum !== undefined)
                                                ) {
                                                    let sum = Math.round(Number(orderItem.sum));
                                                    let sentSum = Math.round(Number(orderItem.sentSum));
                                                    if (sum === 0 || sentSum === 0) {
                                                        return total; // Return current total without adding anything if either sum or sentSum is 0
                                                    } else {
                                                        return total + (sum - sentSum);
                                                    }
                                                }
                                                return total; // Return current total if conditions are not met
                                            }, 0)

                                            // console.log('incomeReduce', incomeReduce)

                                            return (
                                                <tr
                                                    className="main_tr"
                                                    style={{backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : 'transparent'}}
                                                    key={index} onClick={() => {
                                                    showManagersTables(item).then();
                                                    handleClick(item[0]);
                                                }}
                                                    // onMouseEnter={() => handleMouseEnter(item[0])}
                                                >
                                                    <td
                                                        // className="city_item"
                                                        className="first_th" scope="col" style={{ backgroundColor: hoveredItem === item[0] ? '#cbe1e0' : '#ffffff', textAlign: "center", verticalAlign: "middle" }}
                                                    >{reverseDateWithOutYear(item[0])}</td>
                                                    <td
                                                        style={{ backgroundColor: 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                    >{item[1].orders.length}</td>
                                                    <td
                                                        style={{backgroundColor: 'rgba(152,234,119,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                        // className="win_item"
                                                    >
                                                        {filteredOrders.length}
                                                    </td>
                                                    <td
                                                        style={{backgroundColor: 'rgba(169,175,248,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                        // className="work_item"
                                                    >
                                                        {item[1].orders.filter(order => order.status === 'work').length}
                                                    </td>
                                                    <td
                                                        style={{backgroundColor: 'rgba(248,101,101,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                    >
                                                        {item[1].orders.filter(order => order.status === 'failed').length}
                                                    </td>
                                                    <td
                                                        style={{backgroundColor: 'rgba(250,243,155,0.42)', textAlign: "center", verticalAlign: "middle"}}
                                                    >
                                                        {`${(filteredOrders.length * 100 / item[1].orders.length).toFixed(2)}%`}
                                                    </td>
                                                    <td
                                                        style={{ backgroundColor: 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                    >
                                                        {/*{filteredOrders.reduce((total, order) => total + Math.round(Number(order.sum) - Math.round(Number(order.sentSum))), 0)}*/}
                                                        {incomeReduce}
                                                    </td>
                                                    <td
                                                        // className="advTd"
                                                        style={{ backgroundColor: 'transparent', verticalAlign: "middle" }}
                                                        id={`${item[0]}`}>
                                                        {/*<Form.Control*/}
                                                        {/*    style={{maxWidth: "86px", padding: ".15rem .25rem", fontSize: "12px"}}*/}
                                                        {/*    value={advValues[item[0]] ? advValues[item[0]].currentAdv : ''}*/}
                                                        {/*    onChange={(e) => handleInputChange(e, index, item[0])}*/}
                                                        {/*/>*/}
                                                        {advValues[item[0]] ? advValues[item[0]].currentAdv : ''}
                                                    </td>
                                                    <td
                                                        style={{backgroundColor: profitValues[item[0]]?.countAdv < 0 ? 'rgba(250,0,0,0.42)' : 'transparent', textAlign: "center", verticalAlign: "middle" }}
                                                    >
                                                        {profitValues[item[0]] ? profitValues[item[0]]?.countAdv : '--'}
                                                    </td>
                                                    {
                                                        periodManagers.map((manager, j) => (
                                                            <td key={`manager_${j}`} style={{ padding: "0.2rem 0.9rem 0.1rem", backgroundColor: "transparent" }}>
                                                                {
                                                                    Object.entries(item[1].managers).map((item, idxNum) => {
                                                                        if (manager === item[0]) {
                                                                            return (

                                                                                <div key={`manager_${j}_${idxNum}`} className="row">
                                                                                    <div className="col p-0 max_w_48">
                                                                                        <div className="p2 text-center" style={{ padding: ".2rem !important" }}>
                                                                                            {item[1].orderCount}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0 max_w_48">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(152,234,119,0.42)', padding: ".2rem !important" }}>
                                                                                            {item[1].status.win}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0 max_w_48">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(169,175,248,0.42)', padding: ".2rem !important" }}>
                                                                                            {item[1].status.work}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0 max_w_48">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(248,101,101,0.42)', padding: ".2rem !important"}}>
                                                                                            {item[1].status.failed}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(250,243,155,0.42)', padding: ".2rem !important"}}>
                                                                                            {item[1].status.conversion}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(250,169,114,0.42)', padding: ".2rem !important"}}>
                                                                                            {item[1].status.incom}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(166,205,241,0.42)', padding: ".2rem !important" }}>
                                                                                            {item[1].status.middleCash}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col p-0">
                                                                                        <div className="p2 text-center" style={{ backgroundColor: 'rgba(155,153,153,0.35)', padding: ".2rem !important"}}>
                                                                                            {item[1].status.rating}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            );
                                        })
                                }
                                <tr>
                                    <th className="first_th" scope="col" style={{ textAlign: "center" }}>Итого</th>
                                    <th style={{ textAlign: "center" }}>{allTdSum}</th>
                                    <th style={{ textAlign: "center" }}>{winTdSum}</th>
                                    <th style={{ textAlign: "center" }}>{workTdSum}</th>
                                    <th style={{ textAlign: "center" }}>{failedTdSum}</th>
                                </tr>
                                </tbody>
                            </Table>
                            {/*</div>*/}
                        </div>
                        {/*<div style={{marginTop: "20px", display: "flex"}}>*/}
                        {/*    <Table size="sm" responsive bordered style={{ maxWidth: '55%', fontSize: "0.7rem" }} className="tableKPI"*/}
                        {/*    >*/}
                        {/*        <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th rowSpan="2">KPI</th>*/}
                        {/*            <th rowSpan="2">Вес <br/> показателя</th>*/}
                        {/*            <th colSpan="3">Значения</th>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <th>База (если ниже, то работа не эффективна)</th>*/}
                        {/*            <th>Норма (значение, при котором поддерживается положительный баланс)</th>*/}
                        {/*            <th style={{ maxWidth: "50px" }}>Цель</th>*/}
                        {/*        </tr>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        <tr>*/}
                        {/*            <th>Конверсия в продажу, %</th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }} value={formData.conversionRate.weight}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'weight')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.base}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'base')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.norm}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'norm')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.conversionRate.goal}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'conversionRate', 'goal')}/></th>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <th>Успешные сделки, шт</th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.weight}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'weight')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.base}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'base')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.norm}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'norm')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.successfulDeals.goal}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'successfulDeals', 'goal')}/></th>*/}
                        {/*        </tr>*/}
                        {/*        <tr>*/}
                        {/*            <th>Средний чек прибыли, руб</th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.weight}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'weight')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.base}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'base')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.norm}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'norm')}/></th>*/}
                        {/*            <th><Form.Control style={{ fontSize: "0.7rem" }}  value={formData.averageRevenue.goal}*/}
                        {/*                              onChange={(e) => handleInputChangeKPI(e, 'averageRevenue', 'goal')}/></th>*/}
                        {/*        </tr>*/}
                        {/*        </tbody>*/}
                        {/*    </Table>*/}
                        {/*</div>*/}
                        {/*<Button size="sm" style={{marginTop: "20px"}} onClick={updateRatio}>Применить</Button>*/}
                        <div
                            style={{display: noTableData ? 'block' : 'none', marginTop: '20px', fontWeight: "bold"}}>Нет
                            данных
                        </div>
                    </div>
                </>
            );
    }

}

export {TheDealsStatisticsBootstrap};
