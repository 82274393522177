import React, {
    useEffect,
    useRef,
    useState,
    //useHistory,
    useLayoutEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import plusImg from "../img/plus.svg";
import shareImg from "../img/share2.svg";
import searchImg from '../img/search.svg';
import shareQr from "../img/qrcode.svg";
import shareBill from '../img/bill.svg';
import shareBaloon from "../img/baloon.svg";
import shareCake from '../img/cake.svg';
import shareFlowers from "../img/flowers.svg";
import shareStrawberry from '../img/strawberry.svg';
import shareContract from '../img/contract.svg';
import arrowDown from '../img/arrow_down.svg'
import geo from '../img/geo_location.svg'
import {
    Link,
    //useNavigate
} from 'react-router-dom';

// import copyImg from "../img/copy.svg";
// import {TheOrders} from "./TheOrders";
// import filterImg from "../img/filter.svg";
import {getOne, getTrimBD} from "../http/catalogAPI";
import {getCompaniesByCityName, getNumberBD, groupCityI, upDateCities, updateCompanyLive, getTitleNameBD, getCardsWithCoom2, getCardsGeo, getOneCity, cityCoordinatesUpdateBD} from "../http/cityAPI";
import copyImg from "../img/copy.svg";
import preloadImg from "../img/tube-spinner.svg";
import {sortByPriority} from "./functions/sortByPriority";


const TheBody = ({info, setBack, openCardData, openFilter, showFilter, sentCard, searchedValue}) => {
    const navigate = useNavigate();

    const [names, setNames] = useState([]);
    const [bool, setBool] = useState(false);
    const [showCount, setShowCount] = useState(250);
    const [role, setRole] = useState("")
    const [showInput, setShowInput] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const [searchValue, setSearchValue] = useState('')
    const [btnDis, setBtnDis] = useState(false)
    const [reset, setReset] = useState(false)
    const [scrollBlock, setScrollBlock] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [comm, setComm] = useState('')
    const [searchedResult, setSearchedResult] = useState(false)
    const [searchedResultGeo, setSearchedResultGeo] = useState(false)

    const [doublePhones, setDoublePhones] = useState(false)

    const [searchButton,setSearchButton] = useState(false)
    const [userInput, setUserInput] = useState('')
    const [noDataSearch, setNoDataSearch] = useState(false)
    const [coom2DataSearch, setCoom2DataSearch] = useState(false)
    const [geoDataSearch, setGeoDataSearch] = useState(false)

    const [coordinates, setCoordinates] = useState({
        nameCityTitle: '',
        regionTitle: '',
        area_with_typeTitle: '',
        postcode: '',
        geo_lat: '',
        geo_lon: '',
    });

    const [noCoordinatSearch, setNoCoordinatSearch] = useState(false)

    // Добавляем состояние showMorePhones для каждой карточки
    // const initialCardStates = names && names.map((city) => {
    //     return city.companyAlls.map(() => false);
    // });
    // console.log('initialCardStates', initialCardStates)
    const [cardStates, setCardStates] = useState([]);
    // console.log('cardStates', cardStates)

    // const [currentIndex, setCurrentIndex] = useState()

// Функция для управления отображением телефонов для конкретной карточки
//     const handleToggleShowPhones = (companyIndex, cityIndex) => {
//         const newCardStates = [...cardStates];
//         newCardStates[cityIndex][companyIndex] = !newCardStates[cityIndex][companyIndex];
//         console.log('cityIndex', cityIndex)
//         console.log('companyIndex', cityIndex)
//         console.log('newCardStates', newCardStates)
//         setCardStates(newCardStates);
//         console.log('cardStates CLICK', cardStates)
//     };


    // useEffect(() => {
    //     let phoneMore = document.querySelector(`#phoneMore${index}`);
    //
    //     // Проверяем количество дочерних элементов с классом phones_links
    //     if (companyCard.querySelectorAll('.phones_links').length > 1) {
    //         companyCard.classList.add('make');
    //     } else {
    //         companyCard.classList.remove('make');
    //     }
    // }, []);

    const initialCardStatesPhones = names && names.map((city) => {
        return city.companyAlls.map((company) => company.phones && company.phones.length);
    });


    // const initialCardStatesPhones1 = names && names.map((city) => {
    //     return city.companyAlls.map((company) => ({
    //         phoneCount: company.phones && company.phones.length,
    //         phoneCompanyDirector: company.phoneCompanyDirector
    //     }));
    // });

    useEffect(() => {
        // const initialCardStatesPhones1 = names.phoneCompanyDirector;
        console.log('initialCardStatesPhones', initialCardStatesPhones)
    }, [names]);

    //phoneCompanyDirector

    const handleToggleShowPhones = (cityIndex, index) => {
        console.log('cityIndex, index', cityIndex, index);

        let city = document.querySelector(`#city${cityIndex}`);
        let companyCard = document.querySelector(`#companyCard${cityIndex}_${index}`);
        let mainPhone = document.querySelector(`#mainPhone${cityIndex}_${index}`);

        if (city && companyCard.classList.contains('show__phones_links')) {
            companyCard.classList.remove('show__phones_links');
        } else {
            companyCard.classList.add('show__phones_links');
        }

        if (city && mainPhone.classList.contains('hide__phones_links')) {
            mainPhone.classList.remove('hide__phones_links');
        } else {
            mainPhone.classList.add('hide__phones_links');
        }
    }


    useEffect(() => {
        setCardStates(initialCardStatesPhones)
        console.log('cardStates USE EFFECT', cardStates)

        // console.log('cardStates[0][index]', cardStates[0][1])
    }, [names]);

    useEffect(() => {
        setRole(info.role.role)
        console.log('у магаза ИНН', info.role.inn)
    }, [info.role]);

    useEffect(() => {
        document.title = "MyBestFlowers";
    }, []);

    // console.log('THE BODY setCARD', info.contractor)

    // useEffect(() => {
    //     console.log('USEEFFECT work', sentCard)
    //     let res = names.filter(item => item.INN === sentCard[0].INN)
    //     console.log('USEEFFECT res', res, names)
    //     let res1 = names.filter(item => item.INN !== sentCard[0].INN)
    //     console.log('USEEFFECT res1', res1)
    //     const result = [...res, ...res1]
    //     setNames(result)
    //     console.log('USEEFFECT result', result)
    // }, []);

    // useLayoutEffect(() => {
    //     if (info && info.contractor && role === "admin") {
    //         if (searchValue.length === 0) {
    //             setNames(info.contractor);
    //         } else {
    //             console.log('Отсортированно, в TheBody', info.contractor)
    //             const filteredContacts = info.contractor.filter(product =>
    //                 capitalizeFirstLetter(product.city) === capitalizeFirstLetter(searchValue) ||
    //                 normalizePhoneNumber(product.leaderPhone) === normalizePhoneNumber(searchValue) ||
    //                 product.INN === searchValue
    //             );
    //             console.log('Показать больше', filteredContacts)
    //             setNames(filteredContacts);
    //         }
    //
    //         if (showCount < info.contractor.length && searchValue.length === 0) {
    //             setShowMore(true)
    //         } else if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(true)
    //         } else if (showCount > info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(false)
    //         }
    //     } else if (info && info.contractor && role === "shop") {
    //         if (searchValue.length === 0) {
    //             setNames(info.contractor.filter(role => role.INN === info.role.inn));
    //         } else {
    //             const filteredContacts = info.contractor.filter(product =>
    //                 product.leaderPhone === searchValue ||
    //                 product.INN === searchValue ||
    //                 capitalizeFirstLetter(product.city) === capitalizeFirstLetter(searchValue)
    //             );
    //             console.log('Показать больше', filteredContacts)
    //             setNames(filteredContacts);
    //         }
    //
    //         if (showCount < info.contractor.length && searchValue.length === 0) {
    //             setShowMore(true)
    //         } else if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(true)
    //         } else if (showCount > info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(false)
    //         }
    //     } else if (info && info.contractor && role === "manager") {
    //         if (searchValue.length === 0) {
    //             setNames(info.contractor);
    //         } else {
    //             const filteredContacts = info.contractor.filter(product =>
    //                 product.leaderPhone === searchValue ||
    //                 product.INN === searchValue ||
    //                 capitalizeFirstLetter(product.city) === capitalizeFirstLetter(searchValue)
    //             );
    //             console.log('Показать больше', filteredContacts)
    //             setNames(filteredContacts);
    //         }
    //
    //         if (showCount < info.contractor.length && searchValue.length === 0) {
    //             setShowMore(true)
    //         } else if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(true)
    //         } else if (showCount > info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(false)
    //         }
    //     } else if (info && info.contractor && role === "buh") {
    //         if (searchValue.length === 0) {
    //             setNames(info.contractor);
    //         } else {
    //             const filteredContacts = info.contractor.filter(product =>
    //                 product.leaderPhone === searchValue ||
    //                 product.INN === searchValue ||
    //                 capitalizeFirstLetter(product.city) === capitalizeFirstLetter(searchValue)
    //             );
    //             console.log('Показать больше', filteredContacts)
    //             setNames(filteredContacts);
    //         }
    //
    //         if (showCount < info.contractor.length && searchValue.length === 0) {
    //             setShowMore(true)
    //         } else if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(true)
    //         } else if (showCount > info.contractor.filter(city => city.city === searchValue).length) {
    //             setShowMore(false)
    //         }
    //     }
    //     console.log('загрузился Use Effect')
    //
    //     console.log("SCROLL сработал");
    //     const targetElementId = localStorage.getItem('scroll');
    //     if (targetElementId) {
    //         const targetElement = document.getElementById(targetElementId);
    //         if (targetElement) {
    //             targetElement.scrollIntoView({behavior: 'smooth'});
    //             localStorage.removeItem('scroll'); // Очищаем значение после прокрутки
    //         }
    //     }
    //
    //     console.log("localStorage.getItem ДЕРНУЛСЯ", localStorage.getItem("search"));
    //
    //     if (localStorage.getItem("search")) {
    //         console.log("localStorage.getItemСРАБОТАЛ", localStorage.getItem("search"));
    //         const timer = setTimeout(() => {
    //             headerSearch(localStorage.getItem("search"));
    //         }, 1000);
    //         setSearchValue(localStorage.getItem("search"))
    //
    //
    //         return () => clearTimeout(timer); // Очистить таймер при размонтировании компонента или при изменении зависи>
    //     }
    // }, [info, showCount, role, bool]);

    // useLayoutEffect(() => {
    //     console.log("localStorage.getItem ДЕРНУЛСЯ", localStorage.getItem("search"));
    //     if (localStorage.getItem("search")) {
    //         console.log("localStorage.getItemСРАБОТАЛ", localStorage.getItem("search"));
    //         const timer = setTimeout(() => {
    //             headerSearch(localStorage.getItem("search"));
    //         }, 1000);
    //         setSearchValue(localStorage.getItem("search"))
    //
    //         return () => clearTimeout(timer); // Очистить таймер при размонтировании компонента или при изменении зависи>
    //     }
    // }, [localStorage, info]);
    const [preloader, setPreloader] = useState(false)


    const getNamesBD = async (searchBd) => {
        console.log('getNamesBD searchBd', searchBd)
        setPreloader(true)
        try {
            localStorage.setItem('searchedOnBd', JSON.stringify(searchBd));
            const result = await getCompaniesByCityName(searchBd)
            console.log('Result', result)

            if (result.length > 0) { // Check if result array is not empty
                let sortedResult = await sortByPriority(result)
                console.log("sortedResult", sortedResult)
                result[0].companyAlls = sortedResult;
                setNames(result)
            } else {
                // Handle the case when result array is empty
                // Maybe show a message to the user or handle it differently
                console.log("Нет городов рядом")
                setShowMore200km(true)
            }

            setPreloader(false)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {

        const fetch = async () => {
            // setPreloader(true)
            // // const checkboxSearchNoData = localStorage.getItem('checkboxSearchNoData');
            // const searchedValue = localStorage.getItem('searchValue')
            // if (searchedValue) {
            //     setSearchValue(searchedValue)
            //     let sent = await getTitleNameBD(searchedValue);
            //     console.log('Запрос по прямому вхождению: !!!!', sent);
            //     setNames(sent);
            // }
            // setPreloader(false)

            // let noDataSearch = document.querySelector('#noDataSearch');
            // console.log('checkboxSearchNoData', checkboxSearchNoData)
            //
            // if (checkboxSearchNoData === 'true') {
            //     noDataSearch.checked = true
            //     localStorage.setItem('checkboxSearchNoData', true);
            // }
            //
            // if (checkboxSearchNoData === 'false') {
            //     noDataSearch.checked =  false
            //     localStorage.setItem('checkboxSearchNoData', false);
            // }
            // console.log('checkboxSearchNoData', JSON.parse(checkboxSearchNoData))
            //
            //
            // console.log('noDataSearch', noDataSearch)

            // if (showInput) {
            //
            // }
        }

        fetch()

        // Проверяем, существует ли элемент #comm2DataSearch в DOM
        // let checkboxCoom2 = document.querySelector('comm2DataSearch_class');
        // console.log('checkboxCoom2', checkboxCoom2)

        // if (checkboxCoom2) {
        //     // Если элемент существует, выполняем необходимые действия
        //     const checkboxCoom2DataSearch = localStorage.getItem('checkboxCoom2DataSearch');
        //     console.log('checkboxCoom2DataSearch', JSON.parse(checkboxCoom2DataSearch));
        //
        //     // Устанавливаем состояние чекбокса и другие действия
        //     if (checkboxCoom2DataSearch) {
        //         checkboxCoom2.checked = JSON.parse(checkboxCoom2DataSearch);
        //     }
        // } else {
        //     // Если элемент не найден, выводим сообщение об ошибке
        //     console.error('Элемент #comm2DataSearch не найден в DOM.');
        // }
    }, [
        // localStorage,
        // showInput,
        // localStorage.getItem('checkboxSearchNoData')
    ]);

    const [userInputEnter, setUserInputEnter]= useState('')
    useEffect(() => {
        console.log('дернулся UseEffect BODY')
        const fetch = async () => {

            const searchedOnBd = localStorage.getItem('searchedOnBd');
            const searchedValue = localStorage.getItem('searchValue')

            console.log('searchedValue NUMBER', searchedValue)
            console.log('searchedOnBd NUMBER', searchedOnBd)
            if (searchedValue && searchedValue.trim() !== '') {
                const firstWord = searchedValue.split(' ')[0];
                setUserInputEnter(firstWord.charAt(0).toUpperCase() + firstWord.slice(1));
                // setSearchButton(false);
            } else {
                console.error('searchedValue is null, undefined, or empty');
            }
            setSearchButton(false)

            if (searchedValue && !searchedValue[0].match(/[a-zA-Zа-яА-Я]/)) {
                setPreloader(true)
                console.log('No data found in localStorage for key "searchedOnBd"  USE EFFECT', searchedValue);
                localStorage.setItem('searchValue', searchedValue)
                if (searchedValue && searchedValue.trim() !== '') {
                    const firstWord = searchedValue.split(' ')[0];
                    setUserInputEnter(firstWord.charAt(0).toUpperCase() + firstWord.slice(1));
                    // setSearchButton(false);
                } else {
                    console.error('searchedValue is null, undefined, or empty');
                }
                setSearchValue(searchedValue)

                let sent = await getNumberBD({userInput: searchedValue})
                    .then(data => {
                    console.log('data USE EFFECT!!!', data);
                    if (data.length > 0) {
                        setNames(data);
                    } else {
                        console.log('111 USE Effect', data.response.data.message)
                    }

                    setPreloader(false)
                })
                .catch(error => {
                    if (error.response.data.message === "!!!Internal server error!!!") {
                        // localStorage.removeItem("searchedOnBd")
                        // localStorage.removeItem("searchValue")
                        console.log("error 111 USE Effect", error.response.data.message)
                        setShow500(true)

                    }
                });
                console.log('sent getNumberBD', sent)
            } else {
                if (searchedOnBd !== null) {
                    setPreloader(true)
                    const parsedSearchedOnBd = JSON.parse(searchedOnBd);
                    console.log('parsedSearchedOnBd USE EFFECT', parsedSearchedOnBd);
                    localStorage.setItem('searchedOnBd', JSON.stringify(parsedSearchedOnBd));
                    const result = await getCompaniesByCityName(parsedSearchedOnBd);
                    console.log('getNamesBD(parsedSearchedOnBd)  USE EFFECT', result)

                    if (result.length > 0) { // Check if result array is not empty
                        let sortedResult = await sortByPriority(result)
                        result[0].companyAlls = sortedResult;
                        setNames(result)
                    } else {
                        // Handle the case when result array is empty
                        // Maybe show a message to the user or handle it differently
                        console.log("Нет городов рядом")
                        setShowMore200km(true)
                    }

                    setSearchValue(searchedValue)

                    setPreloader(false)
                }
            }
        }

        fetch()
    }, [localStorage, setPreloader]);

    useEffect(() => {
        if (searchValue.length === 0) {
            if (reset) {
                setReset(false)
                // setNames(info.contractor)
                // localStorage.removeItem('search')
            } else {
                setReset(true)
                // setNames(info.contractor)
                // localStorage.removeItem('search')
            }
        }
    }, [info.role.role]);

    // useEffect(() => {
    //     const handleBackButton = () => {
    //         // Ваш код для выполнения функции при нажатии на кнопку "Назад" браузера
    //         console.log("Нажата кнопка назад");
    //     };
    //
    //     window.addEventListener('popstate', handleBackButton);
    //
    //     return () => {
    //         window.removeEventListener('popstate', handleBackButton);
    //     };
    // }, [window.history.length]);

    // const capitalizeFirstLetter = (string) => {
    //     if (/\d/.test(string)) {
    //         return ''; // Если строка содержит цифры, возвращаем пустую строку
    //     }
    //     string = string.replace(/ё/g, 'Ё').replace(/Ё/g, 'е');
    //     return string.trim().replace(/\s*-\s*/g, '-').replace(/\s+/g, ' ').split(/-|\s/)
    //         .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    //         .join('-');
    // }
    // const normalizePhoneNumber = (phoneNumber) => {
    //     if (!phoneNumber || phoneNumber === '') {
    //         return ''; // Handle null or empty input
    //     }
    //
    //     const digits = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
    //     if (digits.length > 10) {
    //         return '+7' + digits.slice(-10); // If more than 10 digits, trim to 10 and add +7 at the beginning
    //     } else {
    //         return '+7' + digits; // If less than or equal to 10 digits, add +7 at the beginning
    //     }
    // }

    // const normalizeINN = (inn) => {
    //     const digits = inn.replace(/\D/g, ''); // Remove all non-numeric characters
    //     if (digits.length === 10) {
    //         return digits; // Return the normalized 10-digit INN
    //     }
    //     // Handle other cases if needed
    // }

    const copyCurrentUrlToClipboard = (path) => {
        const valueText = window.location.toString() + path;
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = valueText;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999);
        document.execCommand('copy');
        alert('Ссылка скопирована в буфер обмена');
        document.body.removeChild(tempTextArea);
    };

    // const copyToClipboardOnClick = (inputText) => {
    //     const tempTextArea = document.createElement('textarea');
    //     tempTextArea.value = inputText;
    //     document.body.appendChild(tempTextArea);
    //     tempTextArea.select();
    //     tempTextArea.setSelectionRange(0, 99999);
    //     document.execCommand('copy');
    //     alert('Текст скопирован в буфер обмена');
    //     document.body.removeChild(tempTextArea);
    // };


    const headerSearchValue = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        // if (e.target.value === '') {
        //     localStorage.removeItem('searchValue')
        // }
    }

    // useEffect(() => {
    //     if (searchValue === '') {
    //         setSearchValue('');
    //     }
    // }, [searchValue]);

    const handleClear = () => {
        localStorage.removeItem('checkboxSearchNoData')
        setSearchValue('')
        localStorage.removeItem('searchValue')
        setBool(!bool)
        setCoordinates({
            nameCityTitle: '',
            regionTitle: '',
            area_with_typeTitle: '',
            postcode: '',
            geo_lat: '',
            geo_lon: '',
        })
        console.log('bool', bool)
        // showMoreButtonActive()
        setNames([]);
        setBtnDis(false)
        setSearchedResult(false)
        localStorage.removeItem('searchedOnBd')
        localStorage.removeItem('scroll');
        setModalResults([]);
        setSearchButton(false)
        setShow500(false)
        setShowMore200km(false)
        console.log(document.querySelector('.search-field'))
        document.querySelector('.search-field').focus();

        document.querySelector('.search-field').addEventListener('focus', function() {
            this.style.setProperty('--caret-width', '2px'); // Устанавливаем желаемую толщину маркера курсора
        });
        // getOne({
        //     name: 'clear'
        // })
    }

    // const headerSearch = async (input) => {
    //
    //     searchedValue(capitalizeFirstLetter(searchValue))
    //
    //     localStorage.setItem("search", `${input}`);
    //     console.log('input', input);
    //
    //     const filteredContacts = info.contractor.filter(product =>
    //         capitalizeFirstLetter(product.city) === capitalizeFirstLetter(searchValue)
    //         ||
    //         product.INN === searchValue
    //         ||
    //         normalizePhoneNumber(product.leaderPhone) === normalizePhoneNumber(searchValue)
    //         ||
    //         normalizePhoneNumber(product.workPhone1) === normalizePhoneNumber(searchValue)
    //         ||
    //         normalizePhoneNumber(product.workPhone2) === normalizePhoneNumber(searchValue)
    //         ||
    //         (product.comment2 && product.comment2.split(',').some(word => word.trim().toLowerCase() === searchValue.trim().toLowerCase()))
    //     );
    //
    //     console.log('Показать больше', filteredContacts)
    //     setNames(filteredContacts);
    //
    //     const data = await getOne({
    //         name: searchValue
    //     });
    //
    //     let groupedOrders = {};
    //     for (let i = 0; i < data.length; i++) {
    //         const order = data[i];
    //         if (!groupedOrders[order.INN]) {
    //             groupedOrders[order.INN] = [order];
    //         } else {
    //             groupedOrders[order.INN].push(order);
    //         }
    //     }
    //
    //     console.log('!!!!groupedOrders', groupedOrders)
    //
    //     const result = [];
    //     Object.keys(groupedOrders).forEach(inn => {
    //         const comp = groupedOrders[inn];
    //         let sumPayment = 0;
    //         comp.forEach(order => {
    //             if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
    //                 const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
    //                 sumPayment += parseFloat(storePayment);
    //             }
    //         });
    //         result.push({
    //             companyName: comp[0].name,
    //             INN: comp[0].INN,
    //             comment1: comp[0].messenger1,
    //             comment2: comp[0].messenger2,
    //             OGRN: comp[0].OGRN,
    //             region: comp[0].region,
    //             city: comp[0].city,
    //             id: comp[0].id,
    //             leaderPhone: comp[0].leaderPhone,
    //             contract: comp[0].contract,
    //             BIK: comp[0].BIK,
    //             QRrs: comp[0].QRrs,
    //             QRstatic: comp[0].QRstatic,
    //             workPhone1: comp[0].workPhone1,
    //             workPhone2: comp[0].workPhone2,
    //             turnover: comp[0].turnover,
    //             rating: comp[0].rating,
    //             quantity: comp[0].quantity,
    //             rs: comp[0].rs,
    //             soc1: comp[0].soc1,
    //             soc2: comp[0].soc2,
    //             siteName: comp[0].siteName,
    //             bankName: comp[0].bankName,
    //
    //             companyOrder: comp.map(order => ({
    //                 city: order.city,
    //                 id: order.idBitrix,
    //                 createData: order.createData,
    //                 updateData: order.updateData,
    //                 moveData: order.moveData,
    //                 price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
    //                 amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло
    //
    //             })),
    //             sumAll: sumPayment,
    //         });
    //     });
    //     console.log('!!!!!!!!!!!res1', result)
    //
    //     result.sort((a, b) => {
    //         const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
    //         const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));
    //
    //         if (completenessB !== completenessA) {
    //             return completenessB - completenessA;
    //         } else {
    //             return b.sumAll - a.sumAll;
    //         }
    //     });

    // if (result && result.length > 0 && result[0].companyAlls) {
    //     result[0].companyAlls.sort((a, b) => {
    //         const completenessA = (a.agreement !== '') * 2 + (a.qrStatic !== '') + (+!!(a.rs || a.qrRs));
    //         const completenessB = (b.agreement !== '') * 2 + (b.qrStatic !== '') + (+!!(b.rs || b.qrRs));
    //
    //         if (completenessB !== completenessA) {
    //             return completenessB - completenessA;
    //         } else {
    //             return b.sumAll - a.sumAll;
    //         }
    //     });
    // } else {
    //     console.error("Result or companyAlls is undefined or empty.");
    // }
    //
    //     console.log('getOne BoDY', data)
    //
    //     setNames(result)
    //     // const filteredByCity = names.filter(contact =>
    //     //     capitalizeFirstLetter(contact.city) === capitalizeFirstLetter(searchValue)
    //     // );
    //     // console.log('Показать больше !!!!', filteredByCity);
    //     // setNames(filteredByCity.slice(0, showCount));
    //
    //     // let cityNorm = capitalizeFirstLetter(searchValue)
    //     // let phoneNumberNorm = normalizePhoneNumber(searchValue)
    //     // let innNorm = normalizeINN(searchValue)
    //     //
    //     // console.log('capitalizeFirstLetter(searchValue)', cityNorm)
    //     // console.log('normalizePhoneNumber(searchValue)', phoneNumberNorm)
    //     // console.log('product.INN', innNorm)
    //
    //     // if (capitalizeFirstLetter(searchValue)) {
    //     //     const filteredByCity = filteredContacts.filter(contact =>
    //     //         capitalizeFirstLetter(contact.city) === capitalizeFirstLetter(searchValue)
    //     //     );
    //     //     console.log('Показать больше !!!!', filteredByCity);
    //     //     setNames(filteredByCity.slice(0, showCount));
    //     // }
    //     // console.log('Показать больше !!!!', filteredByCity);
    //     // setNames(filteredByCity.slice(0, showCount));
    //
    //     // if (phoneNumberNorm || phoneNumberNorm.length === 12) {
    //     //     console.log('Показать больше', filteredContacts);
    //     //     setNames(filteredContacts.slice(0, showCount));
    //     //
    //     // }
    //     //
    //     // if (innNorm || innNorm.length === 10) {
    //     //     console.log('Показать больше', filteredContacts);
    //     //     setNames(filteredContacts.slice(0, showCount));
    //     //
    //     // }
    //
    //     // const finalFilteredContacts = filteredContacts.filter(contact =>
    //     //     capitalizeFirstLetter(contact.city) === capitalizeFirstLetter(searchValue)
    //     // );
    //     //
    //     // console.log('Показать больше !!!!', finalFilteredContacts);
    //     // setNames(finalFilteredContacts.slice(0, showCount));
    // };

    // const showMoreButtonActive = () => {
    //     console.log('searchValue начальное', searchValue)
    //     if (searchValue.length === 0) {
    //         setNames(info.contractor.slice(0, showCount));
    //         console.log('при НЕ пустом searchValue', names)
    //     } else {
    //         let result = info.contractor.filter(city => city.city === searchValue)
    //         console.log('при пустом searchValue', result)
    //         setNames(result)
    //     }
    //
    //     // console.log('result.length', result.length)
    //     // if (result.length > showCount) {
    //     //     setShowMore(true)
    //     //     setRenderCatalogChanged(true)
    //     // } else {
    //     //     setShowMore(false)
    //     // }
    // }

    const handleSearchEnter = (e) => {
        if (e.key === 'Enter') {
            searchBdPhoneAndInn()
        }
    }

    // const handleSearchEnter = (e) => {
    //     console.log('Нажался')
    //     if (e.key === 'Enter') {
    //         console.log('Нажался ENTER')
    //         // headerSearch(searchValue);
    //         // setBool(true);
    //         localStorage.setItem('searchValue', searchValue)
    //         getNamesBD(searchValue)
    //         // if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
    //         //     setShowMore(true);
    //         // } else {
    //         //     setShowMore(false);
    //         // }
    //     }
    // }

    // const parseProductsObj = (obj) => {
    //     console.log()
    //     let products = JSON.parse(obj);
    //     console.log('products BODY', products)
    //     return products
    // }

    // const processLeaderPhone = (phone) => {
    //     return phone.replace(/^\+/, '');
    // };

    useEffect(() => {
        if (!searchValue || noCoordinatSearch || noDataSearch || coom2DataSearch) {
            setSearchButton(false)
            setSearchedResult(false)
        }
    }, [searchValue]);

    const [distance, setDistance] = useState(0)


    // let searchINN = searchValue.replace(/\D/g, '');
    // let searchNum = searchValue.match(/\d{10}$/)[0];

    // if (/^\d+$/.test(searchValue)) {
    //     // Строка содержит только цифры
    //     if (searchValue.length < 10) {
    //         alert('Строка должна содержать более 10 цифр');
    //         return;
    //
    //     }
    // }

    // let data = await getOne({
    //     name: searchValue.trim()
    // });

    const [dataSent, setDataSent] = useState([])






    // let groupedOrders = {};
    // for (let i = 0; i < data.length; i++) {
    //     const order = data[i];
    //     const key = `${order.INN}-${order.citybd}`;
    //
    //     if (!groupedOrders[key]) {
    //         groupedOrders[key] = [order];
    //     } else {
    //         groupedOrders[key].push(order);
    //     }
    // }
    //
    // console.log('!!!!groupedOrders', groupedOrders)
    //
    // const result = [];
    // Object.keys(groupedOrders).forEach(inn => {
    //     const comp = groupedOrders[inn];
    //     let sumPayment = 0;
    //     comp.forEach(order => {
    //         if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
    //             const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
    //             sumPayment += parseFloat(storePayment);
    //         }
    //     });
    //     result.push({
    //         companyName: comp[0].name,
    //         INN: comp[0].INN,
    //         comment1: comp[0].messenger1,
    //         comment2: comp[0].messenger2,
    //         OGRN: comp[0].OGRN,
    //         region: comp[0].region,
    //         city: comp[0].city,
    //         id: comp[0].id,
    //         leaderPhone: comp[0].leaderPhone,
    //         contract: comp[0].contract,
    //         BIK: comp[0].BIK,
    //         QRrs: comp[0].QRrs,
    //         QRstatic: comp[0].QRstatic,
    //         workPhone1: comp[0].workPhone1,
    //         workPhone2: comp[0].workPhone2,
    //         turnover: comp[0].turnover,
    //         rating: comp[0].rating,
    //         quantity: comp[0].quantity,
    //         rs: comp[0].rs,
    //         soc1: comp[0].soc1,
    //         soc2: comp[0].soc2,
    //         siteName: comp[0].siteName,
    //         bankName: comp[0].bankName,
    //
    //         companyOrder: comp.map(order => ({
    //             city: order.city,
    //             id: order.idBitrix,
    //             createData: order.createData,
    //             updateData: order.updateData,
    //             moveData: order.moveData,
    //             price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
    //             amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло
    //
    //         })),
    //         sumAll: sumPayment,
    //     });
    // });
    //console.log('!!!!!!!!!!!res1', result)

    // result.sort((a, b) => {
    //     const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
    //     const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));
    //
    //     if (completenessB !== completenessA) {
    //         return completenessB - completenessA;
    //     } else {
    //         return b.sumAll - a.sumAll;
    //     }
    // });


    // console.log('!!!!!!!!!!!res1', result)


    function isValidJson(jsonString) {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const scrollToElement = (elementId) => {
        localStorage.setItem('scroll', elementId);
        const targetElement = document.getElementById(elementId);
        // setScrollBlock(true)
        // targetElement.classList.add('scrollClick');
        if (targetElement) {
            targetElement.scrollIntoView({behavior: 'smooth'});
        }
    };

    useEffect(() => {
            const timer = setTimeout(() => {
                const targetElementId = localStorage.getItem('scroll');
                if (targetElementId) {
                    console.log("!SCROLL сработал", targetElementId);
                    const targetElement = document.getElementById(targetElementId);
                    console.log('!targetElement BEFORE', targetElement)
                    if (targetElement) {
                        console.log("!!!!SCROLL сработал", targetElement);
                        targetElement.classList.add('blink-border')
                        targetElement.scrollIntoView({behavior: 'smooth'});
                        localStorage.removeItem('scroll'); // Очищаем значение после прокрутки
                    }
                }
            }, 700)
            return () => clearTimeout(timer)
    }, []);

    // useLayoutEffect(() => {
    //     if (localStorage.getItem('searchValue')) {
    //         let parsedValue = localStorage.getItem('searchValue')
    //         setSearchValue(localStorage.getItem('searchValue'))
    //         getNamesBD(parsedValue)
    //     }
    //     const timer = setTimeout(() => {
    //         console.log("{Запустился}");
    //         const targetElementId = localStorage.getItem('scroll');
    //         if (targetElementId) {
    //             console.log("SCROLL сработал", targetElementId);
    //             const targetElement = document.getElementById(targetElementId);
    //             console.log('targetElement BEFORE', targetElement)
    //             if (targetElement) {
    //                 console.log("!!!!SCROLL сработал", targetElement);
    //                 targetElement.scrollIntoView({behavior: 'smooth'});
    //                 localStorage.removeItem('scroll'); // Очищаем значение после прокрутки
    //             }
    //         }
    //     }, 1000)
    //     return () => clearTimeout(timer)
    //
    // }, []);

    // const transliterate = (text) => {
    //     const cyrillicToLatinMap = {
    //         а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ё: 'yo', ж: 'zh',
    //         з: 'z', и: 'i', й: 'y', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o',
    //         п: 'p', р: 'r', с: 's', т: 't', у: 'u', ф: 'f', х: 'h', ц: 'ts',
    //         ч: 'ch', ш: 'sh', щ: 'sch', ь: '', ы: 'y', ъ: '', э: 'e', ю: 'yu',
    //         я: 'ya', А: 'A', Б: 'B', В: 'V', Г: 'G', Д: 'D', Е: 'E', Ё: 'YO',
    //         Ж: 'ZH', З: 'Z', И: 'I', Й: 'Y', К: 'K', Л: 'L', М: 'M', Н: 'N',
    //         О: 'O', П: 'P', Р: 'R', С: 'S', Т: 'T', У: 'U', Ф: 'F', Х: 'H',
    //         Ц: 'TS', Ч: 'CH', Ш: 'SH', Щ: 'SCH', Ь: '', Ы: 'Y', Ъ: '', Э: 'E',
    //         Ю: 'YU', Я: 'YA'
    //     };
    //     return text.split('').map(char => cyrillicToLatinMap[char] || char).join('');
    // };

    const [modalResults, setModalResults] = useState([]);
    const [modalResultsGeo, setModalResultsGeo] = useState([]);

    // const tokenSent = (e) => {
    //     console.log('дернулся tokenSent')
    //     if (e.target.value.length > 3) {
    //         console.log('дернулся tokenSent > 3')
    //         let url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    //         let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
    //         let query = e.target.value;
    //         let options = {
    //             method: "POST",
    //             mode: "cors",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": "Token " + token
    //             },
    //             body: JSON.stringify({query: query})
    //         };
    //
    //         fetch(url, options)
    //             .then(response => response.json())
    //             .then(result => {
    //                 console.log("result ТОКЕН", result);
    //                 const filteredSuggestions = result.suggestions.filter(suggestion =>
    //                     suggestion.data.city === query && suggestion.data.settlement_type === null
    //                 );
    //                 const suggestions = filteredSuggestions.map((suggestion, index) => ({
    //                     postal_code: suggestion.data.postal_code || "",
    //                     city: suggestion.data.city || "",
    //                     country: suggestion.data.country || "",
    //                     region: suggestion.data.region_with_type || "",
    //                     district: suggestion.data.city_district || "",
    //                     geo_lat: suggestion.data.geo_lat || "",
    //                     geo_lon: suggestion.data.geo_lon || ""
    //                 }));
    //                 console.log("filteredSuggestions ТОКЕН", filteredSuggestions);
    //                 console.log("filtered suggestions ТОКЕН", suggestions);
    //                 setModalResults(filteredSuggestions);
    //                 if (filteredSuggestions.length === 1) {
    //                     upDateCities(suggestions);
    //                     console.log("МОЖНО");
    //                 }
    //             })
    //
    //     }
    // };


    const [show500, setShow500] = useState(false)
    const [showMore200km, setShowMore200km] = useState(false)

    const tokenSent = async (e) => {
        const userInput = e.target.value && e.target.value.trim();
            console.log('userInput tokenSent', userInput)
            setUserInputEnter(userInput)
            if (/^[а-яА-Яa-zA-Z]+$/.test(userInput)) {
                setSearchButton(false)
                console.log('Пользователь ввел только буквы');

                if (userInput.toLowerCase() === "петровск") {
                    console.log('NOT userInput',userInput)
                    const suggestions = {
                        postcode: "412540",
                        nameCityTitle: "Петровск",
                        countryTitle: "Россия",
                        regionTitle: "Саратовская обл",
                        area_with_typeTitle: "Петровский р-н",
                        geo_lat: "52.309431",
                        geo_lon: "45.385179",
                        settlement: ""
                    };
                    setModalResults([suggestions]);

                } else {
                    let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
                    let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
                    let query = e.target.value;
                    let options = {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Token " + token
                        },
                        body: JSON.stringify({query: query})
                    }

                    fetch(url, options)
                        .then(response => response.json())
                        .then(result => {

                            console.log("result ЧИСТЫЙ", result)
                            // setCleanResult(result)

                            const filteredSuggestions = result.suggestions.filter(suggestion =>
                                (suggestion.data.city && suggestion.data.city.toLowerCase().includes(query?.toLowerCase())) ||
                                (suggestion.data.settlement && suggestion.data.settlement.toLowerCase().includes(query?.toLowerCase())) ||
                                (suggestion.data.region_with_type && suggestion.data.region_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                                (suggestion.data.settlement_with_type && suggestion.data.settlement_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                                (suggestion.data.settlement_with_typeTitle && suggestion.data.settlement_with_typeTitle.toLowerCase().includes(query?.toLowerCase()))
                            );

                            console.log("filteredSuggestions", filteredSuggestions)

                            const suggestions = filteredSuggestions.map((suggestion, index) => ({
                                postcode: suggestion.data.postal_code || "",
                                nameCityTitle: suggestion.data.city || "",
                                countryTitle: suggestion.data.country || "",
                                regionTitle: suggestion.data.region_with_type || "",
                                area_with_typeTitle: suggestion.data.area_with_type || suggestion.data.settlement_with_type || "",
                                geo_lat: suggestion.data.geo_lat || "",
                                geo_lon: suggestion.data.geo_lon || "",
                                settlement: suggestion.data.settlement || suggestion.data.settlement_with_typeTitle || "",
                            }));
                            setModalResults(suggestions);

                            console.log("Suggestions", suggestions)
                        })
                        .catch(error => console.log("error", error));
                }


            }


            // let normalizedNumber = normalizePhoneNumber7(userInput)
            //
            // if ((normalizedNumber || userInput === '+')) {
            //     console.log('Пользователь ввел только цифры или "+".', userInput);
            //     setModalResults([]);
            //     setUserInput(userInput);
            //     setSearchButton(true);
            // }



            if (normalizePhoneNumber7(userInput) || userInput === '+') {
                console.log('Пользователь ввел только цифры или "+".', userInput);
                setModalResults([]);
                setUserInput(userInput);
                setSearchButton(true);
            }


    }

    const tokenSentGeo = async (city) => {
        // setSearchButton(false)
        console.log('Пользователь ввел только буквы');
        if (city.toLowerCase() === "петровск") {
            console.log('NOT city',city)
            const suggestions = {
                postcode: "412540",
                nameCityTitle: "Петровск",
                countryTitle: "Россия",
                regionTitle: "Саратовская обл",
                area_with_typeTitle: "Петровский р-н",
                geo_lat: "52.309431",
                geo_lon: "45.385179",
                settlement: ""
            };
            setModalResultsGeo([suggestions]);

        } else {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
            let query = city;
            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }
            fetch(url, options)
                .then(response => response.json())
                .then(result => {

                    console.log("result ЧИСТЫЙ", result)
                    // setCleanResult(result)

                    const filteredSuggestions = result.suggestions
                    //     .filter(suggestion =>
                    //     (suggestion.data.city && suggestion.data.city.toLowerCase().includes(query?.toLowerCase())) ||
                    //     (suggestion.data.settlement && suggestion.data.settlement.toLowerCase().includes(query?.toLowerCase())) ||
                    //     (suggestion.data.region_with_type && suggestion.data.region_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                    //     (suggestion.data.settlement_with_type && suggestion.data.settlement_with_type.toLowerCase().includes(query?.toLowerCase()))
                    // );

                    console.log("filteredSuggestions", filteredSuggestions)

                    const suggestions = filteredSuggestions.map((suggestion, index) => ({
                        postcode: suggestion.data.postal_code || "",
                        nameCityTitle: suggestion.data.city || suggestion.data.settlement || "",
                        countryTitle: suggestion.data.country || "",
                        regionTitle: suggestion.data.region_with_type || "",
                        area_with_typeTitle: suggestion.data.area_with_type || suggestion.data.settlement_with_type || "",
                        geo_lat: suggestion.data.geo_lat || "",
                        geo_lon: suggestion.data.geo_lon || "",
                        settlement: suggestion.data.settlement || ""
                    }));
                    console.log("Suggestions", suggestions)
                    setModalResultsGeo(suggestions);
                })
                .catch(error => console.log("error", error));
        }
    }

    const searchBdPhoneAndInn = async () => {
        setNames([])
        setShow500(false)
        setShowMore200km(false)
        setPreloader(true)
        //const result = userInput.replace(/[\s()\-]/g, '');
        let sent = await getNumberBD({userInput}).then(data => {
            console.log('data !!!', data);
            if (data.length > 0) {
                setNames(data);
                localStorage.setItem('searchValue', searchValue)
            } else {
                console.log('111', data.response.data.message)
            }
        }).catch(error => {
            if (error.response.data.message === "!!!Internal server error!!!") {
                // localStorage.removeItem("searchedOnBd")
                // localStorage.removeItem("searchValue")
                console.log("error 111", error.response.data.message)
                setShow500(true)

            }
        });
        console.log('Результат глвный:', sent);
        setPreloader(false)
        //
        // if (sent.length > 0) {
        //     console.log('Результат:', sent);
        //
        //     setNames(sent);
        // } else {
        //     console.log('Результат: {}', sent);
        //     alert("Нет результата")
        // }

    }

    const [havingComm2, setHavingComm2] = useState([])

    const searchBdByTitleName = async () => {
        setPreloader(true)
        let res = searchValue.charAt(0).toUpperCase() + searchValue.slice(1).toLowerCase();
        localStorage.setItem('searchValue', res)
        let sent = await getTitleNameBD(res);
        console.log('Запрос по прямому вхождению:', sent);
        setNames(sent);
        setPreloader(false)
    }

    const noCoordinatSearchBdByTitleName = async () => {
        setPreloader(true)
        let res = searchValue.charAt(0).toUpperCase() + searchValue.slice(1).toLowerCase();
        let sent = await getCardsGeo(res);
        console.log('БЕЗ КООРДИНАТ Запрос по прямому вхождению :', sent);
        setNames(sent);
        setPreloader(false)
    }

    const [showhavingComm2, setShowhavingComm2] = useState(false);

    const searchBdInCoom2 = async () => {
        console.log('searchBdInCoom2')
        setPreloader(true)

        let sent = await getCardsWithCoom2();
        console.log('sent COMM2', sent)
        setNames(sent);

        setPreloader(false)
    }

    // const searchGeoInBD = async () => {
    //     console.log('searchGeoInBD')
    //     setPreloader(true)
    //
    //     let sent = await getCardsGeo();
    //     console.log('sent GEO', sent)
    //     setNames(sent);
    //
    //     setPreloader(false)
    // }

    useEffect(() => {
        if (coom2DataSearch) {
            searchBdInCoom2()
        } else {
            setNames([])
        }
    }, [coom2DataSearch]);

    // useEffect(() => {
    //     if (geoDataSearch) {
    //         searchGeoInBD()
    //     } else {
    //         setNames([])
    //     }
    // }, [geoDataSearch]);

    const updateContractor = (id, bool, liveComm) => {
        updateCompanyLive(id, bool, liveComm)
    }

    const handleSentComm = e => {
        setComm(e.target.value)
    }

    const showChange = (event) => {
        event.stopPropagation()
        // Remove the "active" class from all NavLink elements
        const navLinks = document.querySelectorAll('.search_results .search_results__wrapper');
        navLinks.forEach(link => link.classList.remove('active'));

        // Add the "active" class to the clicked NavLink
        event.target.classList.add('active');
    }
    const upGroup = async () => {
        console.log("ДЕРНУЛСЯ");
        let data = await groupCityI();
        if (dataSent) {
            let groupedOrders = {};
            for (let i = 0; i < data.length; i++) {
                const order = data[i];
                const key = `${order.city}`;

                if (!groupedOrders[key]) {
                    groupedOrders[key] = [order];
                } else {
                    groupedOrders[key].push(order);
                }
            }

            console.log('!!!!groupedOrders', groupedOrders);


            const result = [];
            Object.keys(groupedOrders).forEach(city => {
                const comp = groupedOrders[city];
                let sumPayment = 0;
                comp.forEach(order => {
                    if (order.storePayment) {
                        const storePayment = order.storePayment.replace(/[^\d.]/g, '');
                        sumPayment += parseFloat(storePayment);
                    }
                });
                result.push({
                    city: city,
                    companyCount: comp.length,
                    sumAll: sumPayment,
                });
            });
            setDataSent(result)
            console.log('!!!!!!!!!!!res1', result);
        }
    };

    const handleSearchButtonClick = () => {
        setShowInput(true); // Показать поле ввода
    };

    useEffect(() => {
        if (showInput) {
            console.log(document.querySelector('.search-field'))
            document.querySelector('.search-field').focus();

            document.querySelector('.search-field').addEventListener('focus', function() {
                this.style.setProperty('--caret-width', '2px'); // Устанавливаем желаемую толщину маркера курсора
            });
        }
    }, [showInput]);

    const normalizePhoneNumber = (phoneNumber) => {
        if (phoneNumber !== '') {
            const digits = phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
            if (digits.length > 10) {
                return "+7" + digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
            } else {
                return "+7" + digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
            }
        }
    }

    const normalizePhoneNumber7 = (phoneNumber) => {
        if (phoneNumber !== '') {
            const digits = phoneNumber && phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
            if (digits && digits.length > 10) {
                return digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
            } else {
                return digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
            }
        }
    }

    // const makeGeo = (city, cityIndex) => {
    //     console.log('дернулся Установка координат', city, cityIndex)
    //     #city{cityIndex} search_results
    //     getOneCity(city)
    //     tokenSentGeo(city)
    //     setSearchedResultGeo(true)
    // }

    const [selectedCityIndex, setSelectedCityIndex] = useState(null)
    const searchResultsRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
                setSelectedCityIndex(null); // Закрываем блок при клике вне него
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchResultsRef]);

    const makeGeo = (city, cityIndex, event) => {
        setModalResultsGeo([])
        console.log('дернулся Установка координат', city, cityIndex);

        setSelectedCityIndex(cityIndex)

        if (cityIndex === selectedCityIndex) {
            console.log('cityIndex === selectedCityIndex', cityIndex, selectedCityIndex)
            const searchResults = event.currentTarget.querySelector(`#list_geo${cityIndex}`);

            // Toggle display based on current state
            if (searchResults) {
                if (searchResults.style.display === 'block') {
                    searchResults.style.display = 'none'; // Hide if currently displayed
                } else {
                    searchResults.style.display = 'block'; // Show if currently hidden
                }
            }
        }

        getOneCity(city);
        tokenSentGeo(city);
        // setSearchedResultGeo(true);
    }


    const updateGeo = (cityCoordinates) => {
        console.log('cityCoordinates', cityCoordinates)
        cityCoordinatesUpdateBD(cityCoordinates)
            .then(data => {
                console.log('data mess geo', data)
                alert(data)})
            .catch((error) => {
                setShow500(true)
                console.error('Ошибка при редактировани компании (geo):', error);
                alert('При данной ошибке сообщите администратору о Ваших действиях');
            });
    }


    // useEffect(() => {
    //     if (!noDataSearch) {
    //         localStorage.removeItem('checkboxSearchNoData')
    //     }
    // }, [noDataSearch]);

    // useEffect(() => {
    //     if (names && names.companyAlls.length > 0) {
    //         // names[0] && names[0].companyAlls.map((company, index) => {
    //         //
    //         // });
    //         console.log('!!! company !!!', names.companyAlls.length);
    //         // console.log('!!! company !!!', names.id);
    //     }
    //
    //     console.log('!!! names !!!', names);
    // }, [names]);
    const getTrim = () => {
        getTrimBD()
    }

    switch (role) {
        case 'admin':
            return (
                <div>
                    <div className="catalog__items">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div
                            className="bg"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        ></div>
                        <div
                            className="modal modal_storno"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                контрагента?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        if (comm) {
                                            setShowModal(false);
                                            updateContractor(currentId, false, comm);
                                            setComm('')
                                        } else {
                                            alert("Необходимо ввести причину сторнирования");
                                        }
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        updateContractor(currentId, true)
                                        setComm('')
                                    }}
                                >Нет
                                </button>
                            </div>
                            <div>
                                <textarea
                                    className={comm ? "item_" : "item_-empty"}
                                    // className="item_"
                                    id="comm"
                                    name="comm"
                                    value={comm}
                                    onChange={handleSentComm}
                                    placeholder="Причина сторнирования"
                                    required
                                    rows={5}
                                />
                            </div>
                        </div>
                        <div
                            className="contractor_add"
                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            <Link to={`/create/new/contractor`}>
                                <img src={plusImg} className="logo_img" width="25px"
                                     height="25px"/>
                            </Link>
                        </div>
                        <header
                            className="header header_body"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">

                            </div>

                            <div className="header__right">

                                <div className="input">

                                    <input
                                        type="search"
                                        placeholder="Введите запрос"
                                        className="search-field"
                                        style={showInput ? {display: "block"} : {display: "none"}}
                                        onChange={(e) => {
                                            headerSearchValue(e)
                                            tokenSent(e)
                                            setSearchedResult(true)
                                        }
                                        }
                                        value={searchValue}
                                        disabled={btnDis}
                                        onKeyDown={handleSearchEnter}

                                    />
                                    <div className="clearBtn">
                                        {searchValue &&
                                            <button
                                                className="clear_X"
                                                style={showInput ? {display: "block"} : {display: "none"}}
                                                onClick={() => {
                                                    handleClear();
                                                }}
                                            >
                                                X
                                            </button>
                                        }
                                    </div>
                                    <button
                                        className="search_"
                                        style={searchButton ? {display: "block"} : {display: "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdPhoneAndInn()
                                        }}
                                    >
                                        Поиск
                                    </button>
                                    <button
                                        className="search_"
                                        style={{cursor: "pointer", display: noDataSearch ? "block" : "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdByTitleName()
                                            setSearchedResult(false)
                                            // if (e.key === 'Enter') {
                                            //     searchBdByTitleName()
                                            // }
                                        }}
                                    >
                                        <div>
                                            <div style={{fontSize: "10px"}}>Поиск</div>
                                            <div style={{fontSize: "4px"}}>(наша база)</div>
                                        </div>
                                    </button>
                                    <button
                                        className="search_"
                                        style={{cursor: "pointer", display: noCoordinatSearch ? "block" : "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            noCoordinatSearchBdByTitleName()
                                            setSearchedResult(false)
                                        }}
                                    >
                                        <div>
                                            <div style={{fontSize: "10px"}}>Поиск</div>
                                            <div style={{fontSize: "6px"}}>(города без координат)</div>
                                        </div>
                                    </button>
                                    {/*<button*/}
                                    {/*    className="search_"*/}
                                    {/*    style={{cursor: "pointer", display: coom2DataSearch ? "block" : "none"}}*/}
                                    {/*    disabled={btnDis}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        searchBdInCoom2()*/}
                                    {/*        setSearchedResult(false)*/}
                                    {/*        // if (e.key === 'Enter') {*/}
                                    {/*        //     searchBdByTitleName()*/}
                                    {/*        // }*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <div>*/}
                                    {/*        <div style={{fontSize: "10px"}}>Поиск</div>*/}
                                    {/*        <div style={{fontSize: "6px"}}>(комм. 2)</div>*/}
                                    {/*    </div>*/}
                                    {/*</button>*/}
                                    <button
                                        type="submit"
                                        className="search-button"
                                        onClick={() => {
                                            setShowInput(!showInput)
                                            handleSearchButtonClick()
                                        }}
                                        style={!showInput ? {display: "block"} : {display: "none"}}
                                    >
                                        <img src={searchImg} className="logo_img" width="20px" height="20px"/>
                                    </button>
                                </div>
                            </div>
                        </header>
                        <div
                            className="search_results"
                            style={searchedResult ? {display: "block"} : {display: "none"}}
                        >
                            {modalResults && modalResults.map((result) => {
                                // console.log("FRONT", result)
                                if (result.postal_code !== null) {
                                    // setSearchedResult(true)
                                    return (
                                        <div className="search_results__wrapper" key={result.id} onClick={showChange}>
                                            <div
                                                className="search_results__item"
                                                onClick={() => {
                                                    if (result.geo_lat && result.geo_lon) {
                                                        setBool(true);
                                                        const searchBd = {
                                                            nameCityTitle: result.nameCityTitle || '',
                                                            regionTitle: result.regionTitle || '',
                                                            area_with_typeTitle: result.area_with_typeTitle || '',
                                                            postcode: result.postcode || '',
                                                            geo_lat: result.geo_lat || '',
                                                            geo_lon: result.geo_lon || '',
                                                            settlement: result.settlement || '',
                                                        }
                                                        setCoordinates(searchBd)
                                                        getNamesBD(searchBd)
                                                        setBtnDis(true)
                                                        setSearchedResult(false)
                                                        // const res = `${result.nameCityTitle || ''}, ${result.regionTitle || ''},
                                                        // ${result.area_with_typeTitle || ''}, ${result.postcode || ''}`;
                                                        const res = result.settlement + ' ' + result.nameCityTitle + ' ' + result.regionTitle + ' ' +
                                                            result.area_with_typeTitle + ' ' + result.postcode;
                                                        setSearchValue(res.trim())
                                                        localStorage.setItem('searchValue', res.trim())
                                                    } else {
                                                        alert('Нет координат')
                                                    }
                                                }}
                                            >
                                                <div>
                                                    {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                </div>
                                                {/*<span>{result.unrestricted_value}</span>*/}
                                                {/*<span>{result.data.country},</span>*/}
                                                {/*<span>{result.data.city},</span>*/}
                                                {/*<span>{result.data.region}</span>*/}
                                                {/*<span>{result.district}</span>*/}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null; // Или другое действие, если city пустой
                                }
                            })}
                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                                display: showInput ? "flex" : "none", justifyContent: "center"
                            }}>
                            <input type="checkbox"
                                   id="noDataSearch" onChange={() => {
                                setNoDataSearch(!noDataSearch);
                                setSearchedResult(false)
                                localStorage.setItem('checkboxSearchNoData', !noDataSearch)
                            }}/>
                            <div>Неверно заполненные контрагенты <div style={{fontSize: "10px"}}>(Введите в поле цифру 1
                                и нажмите "Поиск")</div></div>
                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                                display: showInput ? "flex" : "none", justifyContent: "center"
                            }}>
                            <input type="checkbox"
                                   className="comm2DataSearch_class"
                                   id="comm2DataSearch" onChange={() => {
                                setCoom2DataSearch(!coom2DataSearch);
                                setSearchedResult(false);
                                setShowhavingComm2(!showhavingComm2)
                                // localStorage.setItem('checkboxCoom2DataSearch', !coom2DataSearch)
                            }}/>
                            <div>Контрагенты с доп.городами</div>
                        </div>
                        <div
                            style={{
                                cursor: "pointer",
                                display: showInput ? "flex" : "none", justifyContent: "center"
                            }}>
                            <input type="checkbox"
                                   className="geoDataSearch_class"
                                   id="geoDataSearch" onChange={() => {
                                setGeoDataSearch(!geoDataSearch);
                                setNoCoordinatSearch(!noCoordinatSearch)
                                setSearchedResultGeo(false);
                                setSearchedResult(false);
                                // searchedResultGeo
                                // localStorage.setItem('checkboxCoom2DataSearch', !coom2DataSearch)
                            }}/>
                            <div>Города без координат</div>
                        </div>
                        <br/>
                        <h3>Роль: {info.role.role === "admin" ? "Администратор" : ""}</h3>
                        {/*<button*/}
                        {/*onClick={()=>{*/}
                        {/*    getTrim()*/}
                        {/*}}*/}
                        {/*>убрать в ИНН пробелы</button>*/}
                        {/*<button onClick={()=>{*/}
                        {/*    upDateCities("start")*/}
                        {/*}}>Кнопка</button>*/}

                        {/*<button onClick={() => {*/}
                        {/*    upGroup()*/}
                        {/*}}*/}
                        {/*>Получить группу и количество</button>*/}
                        {/*{dataSent && dataSent*/}
                        {/*    .sort((a, b) => b.companyCount - a.companyCount)*/}
                        {/*    .map((data, index) => {*/}
                        {/*        return (*/}
                        {/*            <div key={index}>*/}
                        {/*                {`${data.city} - ${data.companyCount}`}*/}
                        {/*            </div>*/}
                        {/*        );*/}
                        {/*    })*/}
                        {/*}*/}

                        {/*{dataSent &&*/}
                        {/*    <>*/}
                        {/*        <div>Итог: {dataSent.reduce((total, data) => total + data.companyCount, 0)}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {names && names.map((city, cityIndex) => {
                            //console.log('city THE BODY', city)
                            // console.log('siteName', city.typeOfActivity)
                            if (city) {
                                return (
                                    <>
                                        <div key={city.id}
                                             style={{display: "flex", justifyContent: "center", position: "relative"}}
                                             id={`city${cityIndex}`}>
                                            <div style={{textAlign: "center", position: "relative"}}>
                                                {/*<div>{coordinates.geo_lat} {coordinates.geo_lon} {city.distance === 0} Админ</div>*/}
                                                <Link
                                                    to={city.distance !== null || city.distance !== 0 && names.length > 1 ?
                                                        `https://yandex.ru/maps/?rtext=${city.geo_lat},${city.geo_lon}~${coordinates.geo_lat},${coordinates.geo_lon}&rtt=auto` :
                                                        `https://yandex.ru/maps/?ll=${city.geo_lon},${city.geo_lat}&z=12&l=map`
                                                    } target="_blank">

                                                    <div
                                                        className="geo_name"
                                                    >

                                                        <h2>{city.nameCityTitle || city.settlement_with_typeTitle || city.settlement}</h2><img src={geo}
                                                                                          className="share_img products geo_loc"
                                                                                          width="25px"
                                                                                          height="25px"/>
                                                    </div>
                                                    <div><h2>{city.region}</h2></div>
                                                </Link>
                                                <div
                                                    style={{display: city.distance === null || city.distance === 0 ? "none" : "block"}}>{names.length > 1 ? `(Расстояние до ${userInputEnter.charAt(0).toUpperCase() + userInputEnter.slice(1)} ${city.distance} км.)` : null}</div>                                                <button
                                                    onClick={(event) => makeGeo(city.nameCityTitle, cityIndex, event)}
                                                    className="make_geo"
                                                    style={{display: geoDataSearch ? "block" : "none"}}>Установить
                                                    координаты
                                                </button>
                                            </div>
                                            {/*<div style={{display: selectedCityIndex === cityIndex ? "block" : "none"}}*/}
                                            {/*     ref={searchResultsRef}*/}
                                            {/*     onClick={() => updateGeo('Cities')}>*/}
                                            {/*    Cities*/}
                                            {/*</div>*/}
                                            <div
                                                // ref={searchResultsRef}
                                                className="search_results search_results_geo"
                                                // style={{display: selectedCityIndex === cityIndex ? "block" : "none"}}
                                                id={`list_geo${cityIndex}`}
                                            >
                                                {modalResultsGeo && modalResultsGeo.map((result) => {
                                                    if (result.postal_code !== null && selectedCityIndex === cityIndex) {
                                                        // setSearchedResult(true)
                                                        // console.log("FRONT geo", result)
                                                        return (
                                                            <div className="search_results__wrapper" key={result.id}
                                                                 onClick={showChange}>
                                                                <div
                                                                    className="search_results__item"
                                                                    onClick={() => {
                                                                        if (result.geo_lat && result.geo_lon) {
                                                                            // setBool(true);
                                                                            const searchBd = {
                                                                                nameCityTitle: result.nameCityTitle || '',
                                                                                regionTitle: result.regionTitle || '',
                                                                                area_with_typeTitle: result.area_with_typeTitle || '',
                                                                                postcode: result.postcode || '',
                                                                                geo_lat: result.geo_lat || '',
                                                                                geo_lon: result.geo_lon || '',
                                                                                country: result.countryTitle || '',
                                                                                settlement: result.settlement || '',
                                                                                idInCityBD: city.id
                                                                            }
                                                                            console.log('searchBd', searchBd)
                                                                            updateGeo(searchBd)
                                                                            // setCoordinates(searchBd)
                                                                            // getNamesBD(searchBd)
                                                                            // setBtnDis(true)
                                                                            // setSearchedResult(false)
                                                                            // const res = `${result.nameCityTitle || ''}, ${result.regionTitle || ''},
                                                                            // ${result.area_with_typeTitle || ''}, ${result.postcode || ''}`;
                                                                            // const res = result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                                            //     result.area_with_typeTitle + ', ' + result.postcode;
                                                                            // setSearchValue(res)
                                                                            // localStorage.setItem('searchValue', res)
                                                                            // const searchResults = document.querySelector('.search_results_geo');
                                                                            // if (searchResults) {
                                                                            //     searchResults.style.display = 'none';
                                                                            // }
                                                                            setSelectedCityIndex(null)
                                                                        } else {
                                                                            alert('Нет координат')
                                                                        }
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                                    </div>
                                                                    {/*<span>{result.unrestricted_value}</span>*/}
                                                                    {/*<span>{result.data.country},</span>*/}
                                                                    {/*<span>{result.data.city},</span>*/}
                                                                    {/*<span>{result.data.region}</span>*/}
                                                                    {/*<span>{result.district}</span>*/}
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null; // Или другое действие, если city пустой
                                                    }
                                                })}
                                            </div>
                                        </div>
                                        {city.companyAlls && city.companyAlls.map((name, index) => {
                                            //const cityInEnglish = transliterate(name.nameCityTitle);
                                            //console.log('cityname', name)
                                            if (name.live === 'true') {
                                                return (
                                                    <div
                                                        key={index}
                                                        id={`element-${index}`}
                                                        className="item body_item"
                                                        // style={{
                                                        //     backgroundColor: name.live === 'false' ? "#4545451c" : "#ffffff",
                                                        //
                                                        // }}
                                                    >
                                                        <div className="storno"
                                                             style={name.live === 'false' ? {display: "block"} : {display: "none"}}>СТОРНО
                                                        </div>
                                                        <title>1</title>
                                                        {/*<div className="input_item">*/}
                                                        {/*    <div>id</div>*/}
                                                        {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
                                                        {/*    <div className="item_">{name.id}</div>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="share icon"

                                                            style={{cursor: "pointer"}}
                                                            onClick={() => copyCurrentUrlToClipboard(`card/${name.id}/${name.innCompany}`)}
                                                        >
                                                            <img src={shareImg} className="share_img" width="35px"
                                                                 alt="Скопировать"
                                                                 height="35px"/>

                                                        </div>

                                                        <div className="reques__products">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{
                                                                    display:
                                                                        name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).flowers
                                                                            ? "block" : "none"
                                                                }}
                                                            >
                                                                <img src={shareFlowers} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).strawberry ? "block" : "none"}}
                                                            >
                                                                <img src={shareStrawberry}
                                                                     className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).balloon ? "block" : "none"}}
                                                            >
                                                                <img src={shareBaloon} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).cakes ? "block" : "none"}}
                                                            >
                                                                <img src={shareCake} className="share_img products"
                                                                     width="25px"
                                                                     alt="QR"
                                                                     height="25px"/>
                                                            </div>
                                                        </div>
                                                        <div className="reques">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
                                                                style={{display: name.rs || name.QRrs ? "block" : "none"}}
                                                            >
                                                                <div className="name">Р/С</div>
                                                                <img src={shareBill} className="share_img" width="35px"
                                                                     alt="Р/С"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.qrStatic ? "block" : "none"}}
                                                            >
                                                                <img src={shareQr} className="share_img" width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.agreement ? "block" : "none"}}
                                                            >
                                                                <div className="name contractor">№</div>
                                                                <img src={shareContract} className="share_img"
                                                                     width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                        </div>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        <div>Магазин</div>
                                                                        {/*<input className="item_" defaultValue={name.name}/>*/}
                                                                        <div
                                                                            className="item_">{name.nameCompanyTitle}</div>
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>ИНН</div>*/}
                                                                    {/*    <div className="item_">*/}
                                                                    {/*        {name.INN}*/}
                                                                    {/*    </div>*/}
                                                                    {/*    <div className="copy icon"*/}
                                                                    {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
                                                                    {/*        <img src={copyImg} className="logo_img" width="20px"*/}
                                                                    {/*             height="20px"/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Город</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.city}/>*!/*/}
                                                                    {/*    /!*<div className="item_">*!/*/}
                                                                    {/*    /!*    {name.companyOrder.length > 0 &&*!/*/}
                                                                    {/*    /!*        <div>{name.companyOrder[0].city}</div>*!/*/}
                                                                    {/*    /!*    }*!/*/}
                                                                    {/*    /!*</div>*!/*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Регион</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.region}/>*!/*/}
                                                                    {/*    <div className="item_">{name.region}</div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <div className="input_item">
                                                                        <div>Кол-во заказов</div>
                                                                        {/*<input*/}
                                                                        {/*    className="item_"*/}
                                                                        {/*    defaultValue={name.quantity}*/}
                                                                        {/*/>*/}
                                                                        <div
                                                                            className="item_">{name.orders.length}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="input_item">
                                                                        <div>Оборот, руб</div>
                                                                        {/*<input className="item_"*/}
                                                                        {/*       defaultValue={name.turnover}*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            {
                                                                                name.orders.filter(item => item.status === null).reduce((sum, result) => sum + Number(result.sentSum.replace(/\D/g, '')), 0)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div>Баланс, руб</div>*/}
                                                            {/*            <input*/}
                                                            {/*                className="item_"*/}
                                                            {/*                //value={name.balance}*/}
                                                            {/*            />*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div className="input_item">*/}
                                                            {/*                <div>Рейтинг(от 1 до 5)</div>*/}
                                                            {/*                <input*/}
                                                            {/*                    className="item_"*/}
                                                            {/*                    defaultValue={name.rating}*/}
                                                            {/*                />*/}
                                                            {/*                <div className="item_">{name.rating}</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        <div>Телефон</div>
                                                                        {/*<div>!{`${cardStates[index] && cardStates[index][cityIndex]}`}</div>*/}
                                                                        {/*<input className="item_" name="leaderPhone"*/}
                                                                        {/*    // value={leaderPhone}*/}
                                                                        {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
                                                                        {/*    // placeholder={card.leaderPhone}*/}
                                                                        {/*       placeholder="Номер телефона"*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            <div className="show__phones_links"
                                                                                 id={`mainPhone${cityIndex}_${index}`}>
                                                                                <div className="phones_links">
                                                                                    <div
                                                                                        className="phones_links__phone">{name.phones.length === 0 ? name.innCompany : name.phones[0].number}</div>
                                                                                    <div
                                                                                        className="catalog__contacts">
                                                                                        <div
                                                                                            className="phone_links">
                                                                                            <div
                                                                                                className="call icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`tel:${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="wa icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`whatsapp://send?phone=${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="telegram icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`https://t.me/${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="vk icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`viber://add?number=${(name.phones[0].number || '').replace(/^\+/, '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/*<div className="more_phones"*/}
                                                                            {/*     id={`phoneMore${index}`}*/}
                                                                            {/*     onClick={() => handleToggleShowPhones(index)}*/}
                                                                            {/*     style={{display: cardStates[0] && cardStates[0][index] > 1 ? "block" : "none"}}*/}
                                                                            {/*>*/}
                                                                            {/*    <img src={arrowDown}*/}
                                                                            {/*         className="share_img products arrow_down"*/}
                                                                            {/*         width="25px"*/}
                                                                            {/*         height="25px"*/}
                                                                            {/*    />*/}
                                                                            {/*</div>*/}
                                                                            <div className="more_phones"
                                                                                 id={`phoneMore${index}`}
                                                                                 onClick={() => handleToggleShowPhones(cityIndex, index)}
                                                                                 style={{
                                                                                     display: ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector !== "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector === "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] === 1) && name.phoneCompanyDirector !== "") ?
                                                                                         "block" : "none"
                                                                                 }}
                                                                            >
                                                                                <img src={arrowDown}
                                                                                     className="share_img products arrow_down"
                                                                                     width="25px"
                                                                                     height="25px"
                                                                                />
                                                                            </div>
                                                                            <div className="wrapper__phones_links"
                                                                                 id={`companyCard${cityIndex}_${index}`}>
                                                                                {name.phones && name.phones
                                                                                    .sort((a, b) => {
                                                                                        // Проверяем, удовлетворяет ли телефон a условиям сортировки
                                                                                        const aCondition = String(a.dopMain) === String(city.id) && a.dop;
                                                                                        // Проверяем, удовлетворяет ли телефон b условиям сортировки
                                                                                        const bCondition = String(b.dopMain) === String(city.id) && b.dop;

                                                                                        // Если у телефона a удовлетворяет условиям сортировки, он должен идти первым
                                                                                        if (aCondition && !bCondition) {
                                                                                            return -1;
                                                                                        }
                                                                                        // Если у телефона b удовлетворяет условиям сортировки, он должен идти первым
                                                                                        if (!aCondition && bCondition) {
                                                                                            return 1;
                                                                                        }
                                                                                        // Если оба телефона удовлетворяют или не удовлетворяют условиям, сохраняем текущий порядок
                                                                                        return 0;
                                                                                    })
                                                                                    .map((phone, indexKey) => {
                                                                                        //console.log("phoneFRONT", phone.number)
                                                                                        return (
                                                                                            <div
                                                                                                className="phones_links"
                                                                                                key={indexKey}>
                                                                                                <div
                                                                                                    className="phones_links__phone">{phone.number || ''}</div>
                                                                                                <div
                                                                                                    className="catalog__contacts">
                                                                                                    <div
                                                                                                        className="phone_links">
                                                                                                        <div
                                                                                                            className="call icon">
                                                                                                            <a href={`tel:${phone.number}`}>
                                                                                                                <img
                                                                                                                    src={phoneImg}
                                                                                                                    className="logo_img"
                                                                                                                    width="25px"
                                                                                                                    height="25px"/>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="wa icon">
                                                                                                            <a href={`whatsapp://send?phone=${phone.number}`}>
                                                                                                                <img
                                                                                                                    src={waImg}
                                                                                                                    className="logo_img"
                                                                                                                    width="25px"
                                                                                                                    height="25px"/>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="telegram icon">
                                                                                                            <a href={`https://t.me/${phone.number}`}>
                                                                                                                <img
                                                                                                                    src={tgImg}
                                                                                                                    className="logo_img"
                                                                                                                    width="25px"
                                                                                                                    height="25px"/>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="vk icon">
                                                                                                            <a href={`viber://add?number=${(phone.number ? phone.number.replace(/^\+/, '') : '')}`}>
                                                                                                                <img
                                                                                                                    src={vbImg}
                                                                                                                    className="logo_img"
                                                                                                                    width="25px"
                                                                                                                    height="25px"/>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                {name.phoneCompanyDirector !== '' &&
                                                                                    <div className="director_phone"
                                                                                         style={{display: doublePhones ? "none" : "flex"}}>
                                                                                        <div
                                                                                            className="phones_links__phone">{normalizePhoneNumber(name.phoneCompanyDirector)}</div>
                                                                                        <div
                                                                                            className="catalog__contacts">
                                                                                            <div
                                                                                                className="phone_links">
                                                                                                <div
                                                                                                    className="call icon">
                                                                                                    <a href={`tel:${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="wa icon">
                                                                                                    <a href={`whatsapp://send?phone=${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="telegram icon">
                                                                                                    <a href={`https://t.me/${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="vk icon">
                                                                                                    <a href={`viber://add?number=${(name.phoneCompanyDirector ? name.phoneCompanyDirector.replace(/^\+/, '') : '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        {/*Временно удалили*/}
                                                        {name.commentariesMain !== '' && (
                                                            <div className="input_item"
                                                            >
                                                                {/*<div>Комментарий</div>*/}
                                                                {/* <input className="item_" defaultValue={name.name}/> */}
                                                                <div
                                                                    style={{
                                                                        maxHeight: '4.5em', // Максимальная высота блока
                                                                        overflowY: 'auto',   // Прокрутка по вертикали
                                                                        lineHeight: '1.2em', // Высота строки
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 4, // Ограничение по количеству строк
                                                                        WebkitBoxOrient: 'vertical',
                                                                    }}
                                                                    className="item_card__button">{name.commentariesMain}</div>
                                                            </div>
                                                        )}

                                                        <div className="catalog__buttons">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    // setShowCard(true);
                                                                    // setShowCatalog(false);
                                                                    // setSearch(false);
                                                                    openCardData(name.INN);
                                                                    // refreshPage();
                                                                    // changeMenu(false)
                                                                    navigate(`/card/${city.id}/${name.innCompany}`);
                                                                    scrollToElement(`element-${index}`);
                                                                }}
                                                                className="item_card__button"
                                                            > Открыть карточку
                                                                {/*<Link to={`/card/${city.id}/${name.innCompany}`}>Открыть*/}
                                                                {/*    карточку</Link>*/}
                                                            </button>

                                                            <button onClick={() => {
                                                                // openCard(name.INN).then();
                                                                // setShowOrders(true);
                                                                // setShowCash(false);
                                                                // setShowCatalog(false);
                                                                // setShowCreateOrg(false);
                                                                // setShowCard(false);
                                                                // refreshPage();
                                                                setBack(true)
                                                                scrollToElement(`element-${index}`);
                                                            }}
                                                                    className="item_card__button"
                                                            >
                                                                <Link to={`/orders/${name.innCompany}`}>Заказы
                                                                    контрагента</Link>
                                                                {/*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*/}
                                                            </button>
                                                            <button onClick={() => {
                                                                // openCard(name.INN).then();
                                                                // setShowCash(true);
                                                                // setShowOrders(false);
                                                                // setShowCatalog(false);
                                                                // setShowCreateOrg(false);
                                                                // setShowCard(false);
                                                                // refreshPage();
                                                                setBack(true)
                                                            }}
                                                                    className="item_card__button"
                                                            >
                                                                <Link to={`/payments/${name.INN}`}>Деньги
                                                                    контрагента</Link>
                                                            </button>
                                                            <button onClick={(e) => {
                                                                // setShowDeleteMsg(true);
                                                                e.stopPropagation();
                                                                // setDelINN
                                                                setShowModal(true)
                                                                setCurrentId(name.id)
                                                            }}
                                                                    className="item_card__button item_card__delete"
                                                                    style={name.live === 'false' ? {display: "none"} : {display: "block"}}
                                                            >X
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </>
                                )
                            }

                        })}


                        {/*<button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*        onClick={() => {*/}
                        {/*            setShowCount(prevCount => prevCount + 10);*/}
                        {/*            // showMoreButtonActive()*/}
                        {/*        }}*/}
                        {/*        className="item_card__button"*/}
                        {/*>*/}
                        {/*    Показать больше*/}
                        {/*</button>*/}

                        {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}

                        {/*{showCount < info.contractor.length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*<div*/}
                        {/*    className="filter_window"*/}
                        {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        className="filter_window__content"*/}
                        {/*    >*/}
                        {/*        <div className="filter_window__header">*/}
                        {/*            <div>Фильтр</div>*/}
                        {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
                        {/*        </div>*/}
                        {/*        /!*<input className="item_" name="city"*!/*/}
                        {/*        /!*       placeholder="Город"/>*!/*/}
                        {/*        /!*<input className="item_" name="region"*!/*/}
                        {/*        /!*       placeholder="Регион"/>*!/*/}
                        {/*        /!*<input className="item_" name="rating"*!/*/}
                        {/*        /!*       placeholder="Рейтинг"/>*!/*/}
                        {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
                        {/*            <option value="bycity">По городу</option>*/}
                        {/*            <option value="byregion">По региону</option>*/}
                        {/*            <option value="byrating">По рейтингу</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div
                            className="filter_window"
                            style={showFilter ? {display: "block"} : {display: "none"}}
                        >
                            <select
                                name="filterby"
                                className="filterby"
                                aria-label="Поле в списке"
                                onChange={openFilter}
                                defaultValue="all"
                            >
                                <option value="all">Все</option>
                                <option value="by_flowers">Цветы</option>
                                <option value="by_food">Фуд-флорист</option>
                                <option value="by_cakes">Торты</option>
                                <option value="by_baloons">Шары</option>
                            </select>
                        </div>
                    </div>
                    <div style={{display: show500 ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет данных по запросу</h2>
                    </div>
                    <div style={{display: showMore200km ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет магазинов в радиусе 200 км</h2>
                    </div>
                </div>
            );
        case 'buh':
            return (
                <>
                    <div className="catalog__items">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div
                            className="bg"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        ></div>
                        <div
                            className="modal modal_storno"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                контрагента?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        if (comm) {
                                            setShowModal(false);
                                            updateContractor(currentId, false, comm);
                                            setComm('')
                                        } else {
                                            alert("Необходимо ввести причину сторнирования");
                                        }
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        updateContractor(currentId, true)
                                        setComm('')
                                    }}
                                >Нет
                                </button>
                            </div>
                            <div>
                                <textarea
                                    className={comm ? "item_" : "item_-empty"}
                                    // className="item_"
                                    id="comm"
                                    name="comm"
                                    value={comm}
                                    onChange={handleSentComm}
                                    placeholder="Причина сторнирования"
                                    required
                                    rows={5}
                                />
                            </div>
                        </div>
                        <div
                            className="contractor_add"
                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                        >
                            <Link to={`/create`}>
                                <img src={plusImg} className="logo_img" width="25px"
                                     height="25px"/>
                            </Link>
                        </div>
                        <header
                            className="header header_body"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">

                            </div>

                            <div className="header__right">

                                <div className="input">

                                    <input
                                        type="search"
                                        placeholder="Введите запрос"
                                        className="search-field"
                                        style={showInput ? {display: "block"} : {display: "none"}}
                                        onChange={(e) => {
                                            headerSearchValue(e)
                                            tokenSent(e)
                                            setSearchedResult(true)
                                        }
                                        }
                                        value={searchValue}
                                        disabled={btnDis}
                                        onKeyDown={handleSearchEnter}

                                    />
                                    <div className="clearBtn">
                                        {searchValue &&
                                            <button
                                                className="clear_X"
                                                style={showInput ? {display: "block"} : {display: "none"}}
                                                onClick={() => {
                                                    handleClear();
                                                }}
                                            >
                                                X
                                            </button>
                                        }
                                    </div>
                                    <button
                                        className="search_"
                                        style={searchButton ? {display: "block"} : {display: "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdPhoneAndInn()
                                        }}
                                    >
                                        Поиск
                                    </button>
                                    <button
                                        className="search_"
                                        style={{cursor: "pointer", display: noDataSearch ? "block" : "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdByTitleName()
                                            setSearchedResult(false)
                                        }}
                                    >
                                        <div>
                                            <div style={{fontSize: "10px"}}>Поиск</div>
                                            <div style={{fontSize: "4px"}}>(наша база)</div>
                                        </div>
                                    </button>
                                    <button
                                        type="submit"
                                        className="search-button"
                                        onClick={() => {
                                            setShowInput(!showInput)
                                            handleSearchButtonClick()
                                        }}
                                        style={!showInput ? {display: "block"} : {display: "none"}}
                                    >
                                        <img src={searchImg} className="logo_img" width="20px" height="20px"/>
                                    </button>
                                </div>
                            </div>
                        </header>
                        <div
                            className="search_results"
                            style={searchedResult ? {display: "block"} : {display: "none"}}
                        >
                            {modalResults && modalResults.map((result) => {
                                // console.log("FRONT", result)
                                if (result.postal_code !== null) {
                                    // setSearchedResult(true)
                                    return (
                                        <div className="search_results__wrapper" key={result.id} onClick={showChange}>
                                            <div
                                                className="search_results__item"
                                                onClick={() => {
                                                    if (result.geo_lat && result.geo_lon) {
                                                        setBool(true);
                                                        const searchBd = {
                                                            nameCityTitle: result.nameCityTitle || '',
                                                            regionTitle: result.regionTitle || '',
                                                            area_with_typeTitle: result.area_with_typeTitle || '',
                                                            postcode: result.postcode || '',
                                                            geo_lat: result.geo_lat || '',
                                                            geo_lon: result.geo_lon || '',
                                                            settlement: result.settlement || '',
                                                        }
                                                        setCoordinates(searchBd)
                                                        getNamesBD(searchBd)
                                                        setBtnDis(true)
                                                        setSearchedResult(false)
                                                        // const res = `${result.nameCityTitle || ''}, ${result.regionTitle || ''},
                                                        // ${result.area_with_typeTitle || ''}, ${result.postcode || ''}`;
                                                        const res = result.settlement + ' ' + result.nameCityTitle + ' ' + result.regionTitle + ' ' +
                                                            result.area_with_typeTitle + ' ' + result.postcode;
                                                        setSearchValue(res.trim())
                                                        localStorage.setItem('searchValue', res.trim())
                                                    } else {
                                                        alert('Нет координат')
                                                    }
                                                }}
                                            >
                                                <div>
                                                    {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                </div>
                                                {/*<span>{result.unrestricted_value}</span>*/}
                                                {/*<span>{result.data.country},</span>*/}
                                                {/*<span>{result.data.city},</span>*/}
                                                {/*<span>{result.data.region}</span>*/}
                                                {/*<span>{result.district}</span>*/}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null; // Или другое действие, если city пустой
                                }
                            })}
                        </div>
                        {/*<div style={{cursor: "pointer", display: showInput ? "flex" : "none"}}>*/}
                        {/*    <input  type="checkbox"*/}
                        {/*            id="noDataSearch" onChange={() => {*/}
                        {/*        setNoDataSearch(!noDataSearch);*/}
                        {/*        setSearchedResult(false)*/}
                        {/*        // localStorage.setItem('checkboxSearchNoData', )*/}
                        {/*    }}/>*/}
                        {/*    <div>Неверно заполненные контрагенты <div style={{ fontSize: "8px" }}>(Введите в поле цифру 1 и нажмите "Поиск")</div></div>*/}
                        {/*</div>*/}
                        <br/>
                        <h3>Роль: {info.role.role === "buh" ? "Бухгалтер" : ""}</h3>
                        {/*<button onClick={()=>{*/}
                        {/*    upDateCities("start")*/}
                        {/*}}>Кнопка</button>*/}

                        {/*<button onClick={() => {*/}
                        {/*    upGroup()*/}
                        {/*}}*/}
                        {/*>Получить группу и количество</button>*/}
                        {/*{dataSent && dataSent*/}
                        {/*    .sort((a, b) => b.companyCount - a.companyCount)*/}
                        {/*    .map((data, index) => {*/}
                        {/*        return (*/}
                        {/*            <div key={index}>*/}
                        {/*                {`${data.city} - ${data.companyCount}`}*/}
                        {/*            </div>*/}
                        {/*        );*/}
                        {/*    })*/}
                        {/*}*/}

                        {/*{dataSent &&*/}
                        {/*    <>*/}
                        {/*        <div>Итог: {dataSent.reduce((total, data) => total + data.companyCount, 0)}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {names && names.map((city, cityIndex) => {
                            console.log('city THE BODY', city)
                            // console.log('siteName', city.typeOfActivity)
                            if (city) {
                                return (
                                    <>
                                        <div key={city.id} style={{display: "flex", justifyContent: "center"}}
                                             id={`city${cityIndex}`}>
                                            <div style={{textAlign: "center", position: "relative"}}>
                                                {/*<div>{coordinates.geo_lat} {coordinates.geo_lon} {city.distance === 0} МЕНЕДЖЕР</div>*/}
                                                <Link
                                                    to={city.distance !== null || city.distance !== 0 && names.length > 1 ?
                                                        `https://yandex.ru/maps/?rtext=${city.geo_lat},${city.geo_lon}~${coordinates.geo_lat},${coordinates.geo_lon}&rtt=auto` :
                                                        `https://yandex.ru/maps/?ll=${city.geo_lon},${city.geo_lat}&z=12&l=map`
                                                    } target="_blank">
                                                    <div
                                                        className="geo_name"
                                                    >

                                                        <h2>{city.nameCityTitle || city.settlement_with_typeTitle || city.settlement}</h2><img src={geo}
                                                                                          className="share_img products geo_loc"
                                                                                          width="25px"
                                                                                          height="25px"/>
                                                    </div>
                                                    <div><h2>{city.region}</h2></div>
                                                </Link>
                                                <div
                                                    style={{display: city.distance === null || city.distance === 0 ? "none" : "block"}}>{names.length > 1 ? `(Расстояние до ${userInputEnter.charAt(0).toUpperCase() + userInputEnter.slice(1)} ${city.distance} км.)` : null}</div>
                                            </div>
                                        </div>
                                        {city.companyAlls && city.companyAlls.map((name, index) => {
                                            //const cityInEnglish = transliterate(name.nameCityTitle);
                                            //console.log('cityInEnglish',cityInEnglish)
                                            if (name.live === 'true') {
                                                return (
                                                    <div
                                                        key={index}
                                                        id={`element-${index}`}
                                                        className="item body_item"
                                                        // style={{
                                                        //     backgroundColor: name.live === 'false' ? "#4545451c" : "#ffffff",
                                                        //
                                                        // }}
                                                    >

                                                        <div className="storno"
                                                             style={name.live === 'false' ? {display: "block"} : {display: "none"}}>СТОРНО
                                                        </div>
                                                        <title>1</title>
                                                        {/*<div className="input_item">*/}
                                                        {/*    <div>id</div>*/}
                                                        {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
                                                        {/*    <div className="item_">{name.id}</div>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="share icon"

                                                            style={{cursor: "pointer"}}
                                                            onClick={() => copyCurrentUrlToClipboard(`card/${name.id}/${name.innCompany}`)}
                                                        >
                                                            <img src={shareImg} className="share_img" width="35px"
                                                                 alt="Скопировать"
                                                                 height="35px"/>

                                                        </div>

                                                        <div className="reques__products">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{
                                                                    display:
                                                                        name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).flowers
                                                                            ? "block" : "none"
                                                                }}
                                                            >
                                                                <img src={shareFlowers} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).strawberry ? "block" : "none"}}
                                                            >
                                                                <img src={shareStrawberry}
                                                                     className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).balloon ? "block" : "none"}}
                                                            >
                                                                <img src={shareBaloon} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).cakes ? "block" : "none"}}
                                                            >
                                                                <img src={shareCake} className="share_img products"
                                                                     width="25px"
                                                                     alt="QR"
                                                                     height="25px"/>
                                                            </div>
                                                        </div>
                                                        <div className="reques">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
                                                                style={{display: name.rs || name.QRrs ? "block" : "none"}}
                                                            >
                                                                <div className="name">Р/С</div>
                                                                <img src={shareBill} className="share_img" width="35px"
                                                                     alt="Р/С"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.qrStatic ? "block" : "none"}}
                                                            >
                                                                <img src={shareQr} className="share_img" width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.agreement ? "block" : "none"}}
                                                            >
                                                                <div className="name contractor">№</div>
                                                                <img src={shareContract} className="share_img"
                                                                     width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                        </div>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        {/*<div>Магазин</div>*/}
                                                                        {/*<input className="item_" defaultValue={name.name}/>*/}
                                                                        <div
                                                                            className="item_">{name.nameCompanyTitle}</div>
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>ИНН</div>*/}
                                                                    {/*    <div className="item_">*/}
                                                                    {/*        {name.INN}*/}
                                                                    {/*    </div>*/}
                                                                    {/*    <div className="copy icon"*/}
                                                                    {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
                                                                    {/*        <img src={copyImg} className="logo_img" width="20px"*/}
                                                                    {/*             height="20px"/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Город</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.city}/>*!/*/}
                                                                    {/*    /!*<div className="item_">*!/*/}
                                                                    {/*    /!*    {name.companyOrder.length > 0 &&*!/*/}
                                                                    {/*    /!*        <div>{name.companyOrder[0].city}</div>*!/*/}
                                                                    {/*    /!*    }*!/*/}
                                                                    {/*    /!*</div>*!/*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Регион</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.region}/>*!/*/}
                                                                    {/*    <div className="item_">{name.region}</div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>
                                                            {
                                                                // name.comment2 !== '' && <tr>
                                                                //     <td colSpan="2">
                                                                //         <div className="input_item">
                                                                //             <div>Доп. города</div>
                                                                //             {/*<input className="item_" defaultValue={name.name}/>*/}
                                                                //             <div className="more_cities">{name.comment2}</div>
                                                                //         </div>
                                                                //     </td>
                                                                //     <td></td>
                                                                // </tr>
                                                            }
                                                            <tr>
                                                                <td>
                                                                    <div className="input_item">
                                                                        {/*<div>Кол-во заказов</div>*/}
                                                                        {/*<input*/}
                                                                        {/*    className="item_"*/}
                                                                        {/*    defaultValue={name.quantity}*/}
                                                                        {/*/>*/}
                                                                        <div
                                                                            className="item_">{name.orders.length}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="input_item">
                                                                        {/*<div>Оборот, руб</div>*/}
                                                                        {/*<input className="item_"*/}
                                                                        {/*       defaultValue={name.turnover}*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            {
                                                                                `${name.orders.filter(item => item.status === null).reduce((sum, result) => sum + Number(result.sentSum.replace(/\D/g, '')), 0)} руб`
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div>Баланс, руб</div>*/}
                                                            {/*            <input*/}
                                                            {/*                className="item_"*/}
                                                            {/*                //value={name.balance}*/}
                                                            {/*            />*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div className="input_item">*/}
                                                            {/*                <div>Рейтинг(от 1 до 5)</div>*/}
                                                            {/*                <input*/}
                                                            {/*                    className="item_"*/}
                                                            {/*                    defaultValue={name.rating}*/}
                                                            {/*                />*/}
                                                            {/*                <div className="item_">{name.rating}</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        {/*<div>Телефон</div>*/}
                                                                        {/*<div>!{`${cardStates[index] && cardStates[index][cityIndex]}`}</div>*/}
                                                                        {/*<input className="item_" name="leaderPhone"*/}
                                                                        {/*    // value={leaderPhone}*/}
                                                                        {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
                                                                        {/*    // placeholder={card.leaderPhone}*/}
                                                                        {/*       placeholder="Номер телефона"*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            <div className="show__phones_links"
                                                                                 id={`mainPhone${cityIndex}_${index}`}>
                                                                                <div className="phones_links">
                                                                                    <div
                                                                                        className="phones_links__phone">{name.phones.length === 0 ? name.innCompany : name.phones[0].number}</div>
                                                                                    <div
                                                                                        className="catalog__contacts">
                                                                                        <div
                                                                                            className="phone_links">
                                                                                            <div
                                                                                                className="call icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`tel:${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="wa icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`whatsapp://send?phone=${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="telegram icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`https://t.me/${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="vk icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`viber://add?number=${(name.phones[0].number || '').replace(/^\+/, '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/*<div className="more_phones"*/}
                                                                            {/*     id={`phoneMore${index}`}*/}
                                                                            {/*     onClick={() => handleToggleShowPhones(index)}*/}
                                                                            {/*     style={{display: cardStates[0] && cardStates[0][index] > 1 ? "block" : "none"}}*/}
                                                                            {/*>*/}
                                                                            {/*    <img src={arrowDown}*/}
                                                                            {/*         className="share_img products arrow_down"*/}
                                                                            {/*         width="25px"*/}
                                                                            {/*         height="25px"*/}
                                                                            {/*    />*/}
                                                                            {/*</div>*/}
                                                                            <div className="more_phones"
                                                                                 id={`phoneMore${index}`}
                                                                                 onClick={() => handleToggleShowPhones(cityIndex, index)}
                                                                                 style={{
                                                                                     display: ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector !== "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector === "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] === 1) && name.phoneCompanyDirector !== "") ?
                                                                                         "block" : "none"
                                                                                 }}
                                                                            >
                                                                                <img src={arrowDown}
                                                                                     className="share_img products arrow_down"
                                                                                     width="25px"
                                                                                     height="25px"
                                                                                />
                                                                            </div>
                                                                            <div className="wrapper__phones_links"
                                                                                 id={`companyCard${cityIndex}_${index}`}>
                                                                                {name.phoneCompanyDirector !== '' &&
                                                                                    <div className="director_phone">
                                                                                        <div
                                                                                            className="phones_links__phone">{name.phoneCompanyDirector}</div>
                                                                                        <div
                                                                                            className="catalog__contacts">
                                                                                            <div
                                                                                                className="phone_links">
                                                                                                <div
                                                                                                    className="call icon">
                                                                                                    <a href={`tel:${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="wa icon">
                                                                                                    <a href={`whatsapp://send?phone=${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="telegram icon">
                                                                                                    <a href={`https://t.me/${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="vk icon">
                                                                                                    <a href={`viber://add?number=${(name.phoneCompanyDirector ? name.phoneCompanyDirector.replace(/^\+/, '') : '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {name.phones && name.phones.map((phone, indexKey) => {
                                                                                    console.log("phoneFRONT", phone.number)
                                                                                    return (
                                                                                        <div className="phones_links"
                                                                                             key={indexKey}>
                                                                                            <div
                                                                                                className="phones_links__phone">{phone.number || ''}</div>
                                                                                            <div
                                                                                                className="catalog__contacts">
                                                                                                <div
                                                                                                    className="phone_links">
                                                                                                    <div
                                                                                                        className="call icon">
                                                                                                        <a href={`tel:${phone.number}`}>
                                                                                                            <img
                                                                                                                src={phoneImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="wa icon">
                                                                                                        <a href={`whatsapp://send?phone=${phone.number}`}>
                                                                                                            <img
                                                                                                                src={waImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="telegram icon">
                                                                                                        <a href={`https://t.me/${phone.number}`}>
                                                                                                            <img
                                                                                                                src={tgImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="vk icon">
                                                                                                        <a href={`viber://add?number=${(phone.number ? phone.number.replace(/^\+/, '') : '')}`}>
                                                                                                            <img
                                                                                                                src={vbImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        {/*Временно удалили*/}
                                                        {name.commentariesMain !== '' && (
                                                            <div className="input_item"
                                                            >
                                                                {/*<div>Комментарий</div>*/}
                                                                {/* <input className="item_" defaultValue={name.name}/> */}
                                                                <div
                                                                    style={{
                                                                        maxHeight: '4.5em', // Максимальная высота блока
                                                                        overflowY: 'auto',   // Прокрутка по вертикали
                                                                        lineHeight: '1.2em', // Высота строки
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 4, // Ограничение по количеству строк
                                                                        WebkitBoxOrient: 'vertical',
                                                                    }}
                                                                    className="item_card__button">{name.commentariesMain}</div>
                                                            </div>
                                                            )}
                                                        <div className="catalog__buttons">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    // setShowCard(true);
                                                                    // setShowCatalog(false);
                                                                    // setSearch(false);
                                                                    openCardData(name.INN);
                                                                    // refreshPage();
                                                                    // changeMenu(false)

                                                                    scrollToElement(`element-${index}`);
                                                                    navigate(`/card/${city.id}/${name.innCompany}`);
                                                                }}
                                                                className="item_card__button"
                                                            > Открыть карточку
                                                                {/*<Link to={`/card/${city.id}/${name.innCompany}`}>Открыть*/}
                                                                {/*    карточку</Link>*/}
                                                            </button>

                                                            <button onClick={() => {
                                                                // openCard(name.INN).then();
                                                                // setShowOrders(true);
                                                                // setShowCash(false);
                                                                // setShowCatalog(false);
                                                                // setShowCreateOrg(false);
                                                                // setShowCard(false);
                                                                // refreshPage();
                                                                setBack(true)
                                                            }}
                                                                    className="item_card__button"
                                                            >
                                                                <Link to={`/orders/${name.INN}`}>Заказы
                                                                    контрагента</Link>
                                                                {/*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*/}
                                                            </button>
                                                            <button onClick={() => {
                                                                // openCard(name.INN).then();
                                                                // setShowCash(true);
                                                                // setShowOrders(false);
                                                                // setShowCatalog(false);
                                                                // setShowCreateOrg(false);
                                                                // setShowCard(false);
                                                                // refreshPage();
                                                                setBack(true)
                                                            }}
                                                                    className="item_card__button"
                                                            >
                                                                <Link to={`/payments/${name.INN}`}>Деньги
                                                                    контрагента</Link>
                                                            </button>
                                                            {/*<button onClick={(e) => {*/}
                                                            {/*    // setShowDeleteMsg(true);*/}
                                                            {/*    e.stopPropagation();*/}
                                                            {/*    // setDelINN*/}
                                                            {/*    setShowModal(true)*/}
                                                            {/*    setCurrentId(name.id)*/}
                                                            {/*}}*/}
                                                            {/*        className="item_card__button item_card__delete"*/}
                                                            {/*        style={name.live === 'false' ? {display: "none"} : {display: "block"}}*/}
                                                            {/*>X*/}
                                                            {/*</button>*/}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </>
                                )
                            }

                        })}


                        {/*<button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*        onClick={() => {*/}
                        {/*            setShowCount(prevCount => prevCount + 10);*/}
                        {/*            // showMoreButtonActive()*/}
                        {/*        }}*/}
                        {/*        className="item_card__button"*/}
                        {/*>*/}
                        {/*    Показать больше*/}
                        {/*</button>*/}

                        {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}

                        {/*{showCount < info.contractor.length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*<div*/}
                        {/*    className="filter_window"*/}
                        {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        className="filter_window__content"*/}
                        {/*    >*/}
                        {/*        <div className="filter_window__header">*/}
                        {/*            <div>Фильтр</div>*/}
                        {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
                        {/*        </div>*/}
                        {/*        /!*<input className="item_" name="city"*!/*/}
                        {/*        /!*       placeholder="Город"/>*!/*/}
                        {/*        /!*<input className="item_" name="region"*!/*/}
                        {/*        /!*       placeholder="Регион"/>*!/*/}
                        {/*        /!*<input className="item_" name="rating"*!/*/}
                        {/*        /!*       placeholder="Рейтинг"/>*!/*/}
                        {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
                        {/*            <option value="bycity">По городу</option>*/}
                        {/*            <option value="byregion">По региону</option>*/}
                        {/*            <option value="byrating">По рейтингу</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div
                            className="filter_window"
                            style={showFilter ? {display: "block"} : {display: "none"}}
                        >
                            <select
                                name="filterby"
                                className="filterby"
                                aria-label="Поле в списке"
                                onChange={openFilter}
                                defaultValue="all"
                            >
                                <option value="all">Все</option>
                                <option value="by_flowers">Цветы</option>
                                <option value="by_food">Фуд-флорист</option>
                                <option value="by_cakes">Торты</option>
                                <option value="by_baloons">Шары</option>
                            </select>
                        </div>
                    </div>
                    <div style={{display: show500 ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет данных по запросу</h2>
                    </div>
                    <div style={{display: showMore200km ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет магазинов в радиусе 200 км</h2>
                    </div>
                </>
            );
        case 'manager':
            return (
                <>
                    <div className="catalog__items">
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <div
                            className="bg"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        ></div>
                        <div
                            className="modal modal_storno"
                            style={showModal ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                контрагента?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        if (comm) {
                                            setShowModal(false);
                                            updateContractor(currentId, false, comm);
                                            setComm('')
                                        } else {
                                            alert("Необходимо ввести причину сторнирования");
                                        }
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        updateContractor(currentId, true)
                                        setComm('')
                                    }}
                                >Нет
                                </button>
                            </div>
                            <div>
                                <textarea
                                    className={comm ? "item_" : "item_-empty"}
                                    // className="item_"
                                    id="comm"
                                    name="comm"
                                    value={comm}
                                    onChange={handleSentComm}
                                    placeholder="Причина сторнирования"
                                    required
                                    rows={5}
                                />
                            </div>
                        </div>
                        {/*<div*/}
                        {/*    className="contractor_add"*/}
                        {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}*/}
                        {/*>*/}
                        {/*    <Link to={`/create`}>*/}
                        {/*        <img src={plusImg} className="logo_img" width="25px"*/}
                        {/*             height="25px"/>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        <header
                            className="header header_body"
                            // style={headerMenu ? {display: "flex"} : {display: "none"}}
                        >
                            <div className="header__left">

                            </div>

                            <div className="header__right">

                                <div className="input">

                                    <input
                                        type="search"
                                        placeholder="Введите запрос"
                                        className="search-field"
                                        style={showInput ? {display: "block"} : {display: "none"}}
                                        onChange={(e) => {
                                            headerSearchValue(e)
                                            tokenSent(e)
                                            setSearchedResult(true)
                                        }
                                        }
                                        value={searchValue}
                                        disabled={btnDis}
                                        onKeyDown={handleSearchEnter}

                                    />
                                    <div className="clearBtn">
                                        {searchValue &&
                                            <button
                                                className="clear_X"
                                                style={showInput ? {display: "block"} : {display: "none"}}
                                                onClick={() => {
                                                    handleClear();
                                                }}
                                            >
                                                X
                                            </button>
                                        }
                                    </div>
                                    <button
                                        className="search_"
                                        style={searchButton ? {display: "block"} : {display: "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdPhoneAndInn()
                                        }}
                                    >
                                        Поиск
                                    </button>
                                    <button
                                        className="search_"
                                        style={{cursor: "pointer", display: noDataSearch ? "block" : "none"}}
                                        disabled={btnDis}
                                        onClick={() => {
                                            searchBdByTitleName()
                                            setSearchedResult(false)
                                        }}
                                    >
                                        <div>
                                            <div style={{fontSize: "10px"}}>Поиск</div>
                                            <div style={{fontSize: "4px"}}>(наша база)</div>
                                        </div>
                                    </button>
                                    <button
                                        type="submit"
                                        className="search-button"
                                        onClick={() => {
                                            setShowInput(!showInput)
                                            handleSearchButtonClick()
                                        }}
                                        style={!showInput ? {display: "block"} : {display: "none"}}
                                    >
                                        <img src={searchImg} className="logo_img" width="20px" height="20px"/>
                                    </button>
                                </div>
                            </div>
                        </header>
                        <div
                            className="search_results"
                            style={searchedResult ? {display: "block"} : {display: "none"}}
                        >
                            {modalResults && modalResults.map((result) => {
                                // console.log("FRONT", result)
                                if (result.postal_code !== null) {
                                    // setSearchedResult(true)
                                    return (
                                        <div className="search_results__wrapper" key={result.id} onClick={showChange}>
                                            <div
                                                className="search_results__item"
                                                onClick={() => {
                                                    if (result.geo_lat && result.geo_lon) {
                                                        setBool(true);
                                                        const searchBd = {
                                                            nameCityTitle: result.nameCityTitle || '',
                                                            regionTitle: result.regionTitle || '',
                                                            area_with_typeTitle: result.area_with_typeTitle || '',
                                                            postcode: result.postcode || '',
                                                            geo_lat: result.geo_lat || '',
                                                            geo_lon: result.geo_lon || '',
                                                            settlement: result.settlement || '',
                                                        }
                                                        setCoordinates(searchBd)
                                                        getNamesBD(searchBd)
                                                        setBtnDis(true)
                                                        setSearchedResult(false)
                                                        // const res = `${result.nameCityTitle || ''}, ${result.regionTitle || ''},
                                                        // ${result.area_with_typeTitle || ''}, ${result.postcode || ''}`;
                                                        const res = result.settlement + ' ' + result.nameCityTitle + ' ' + result.regionTitle + ' ' +
                                                            result.area_with_typeTitle + ' ' + result.postcode;
                                                        setSearchValue(res.trim())
                                                        localStorage.setItem('searchValue', res.trim())
                                                    } else {
                                                        alert('Нет координат')
                                                    }
                                                }}
                                            >
                                                <div>
                                                    {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                </div>
                                                {/*<span>{result.unrestricted_value}</span>*/}
                                                {/*<span>{result.data.country},</span>*/}
                                                {/*<span>{result.data.city},</span>*/}
                                                {/*<span>{result.data.region}</span>*/}
                                                {/*<span>{result.district}</span>*/}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null; // Или другое действие, если city пустой
                                }
                            })}
                        </div>
                        {/*<div style={{cursor: "pointer", display: showInput ? "flex" : "none"}}>*/}
                        {/*    <input  type="checkbox"*/}
                        {/*            id="noDataSearch" onChange={() => {*/}
                        {/*        setNoDataSearch(!noDataSearch);*/}
                        {/*        setSearchedResult(false)*/}
                        {/*        // localStorage.setItem('checkboxSearchNoData', )*/}
                        {/*    }}/>*/}
                        {/*    <div>Неверно заполненные контрагенты <div style={{ fontSize: "8px" }}>(Введите в поле цифру 1 и нажмите "Поиск")</div></div>*/}
                        {/*</div>*/}
                        <br/>
                        <h3>Роль: {info.role.role === "manager" ? "Менеджер" : ""}</h3>
                        {/*<button onClick={()=>{*/}
                        {/*    upDateCities("start")*/}
                        {/*}}>Кнопка</button>*/}

                        {/*<button onClick={() => {*/}
                        {/*    upGroup()*/}
                        {/*}}*/}
                        {/*>Получить группу и количество</button>*/}
                        {/*{dataSent && dataSent*/}
                        {/*    .sort((a, b) => b.companyCount - a.companyCount)*/}
                        {/*    .map((data, index) => {*/}
                        {/*        return (*/}
                        {/*            <div key={index}>*/}
                        {/*                {`${data.city} - ${data.companyCount}`}*/}
                        {/*            </div>*/}
                        {/*        );*/}
                        {/*    })*/}
                        {/*}*/}

                        {/*{dataSent &&*/}
                        {/*    <>*/}
                        {/*        <div>Итог: {dataSent.reduce((total, data) => total + data.companyCount, 0)}</div>*/}
                        {/*    </>*/}
                        {/*}*/}

                        {names && names.map((city, cityIndex) => {
                            console.log('city THE BODY', city)
                            // console.log('siteName', city.typeOfActivity)
                            if (city) {
                                return (
                                    <>
                                        <div key={city.id} style={{display: "flex", justifyContent: "center"}}
                                             id={`city${cityIndex}`}>
                                            <div style={{textAlign: "center", position: "relative"}}>
                                                {/*<div>{coordinates.geo_lat} {coordinates.geo_lon} {city.distance === 0} БУХ</div>*/}
                                                <Link
                                                    to={city.distance !== null || city.distance !== 0 && names.length > 1 ?
                                                        `https://yandex.ru/maps/?rtext=${city.geo_lat},${city.geo_lon}~${coordinates.geo_lat},${coordinates.geo_lon}&rtt=auto` :
                                                        `https://yandex.ru/maps/?ll=${city.geo_lon},${city.geo_lat}&z=12&l=map`
                                                    } target="_blank">
                                                    <div
                                                        className="geo_name"
                                                    >

                                                        <h2>{city.nameCityTitle || city.settlement_with_typeTitle || city.settlement}</h2><img src={geo}
                                                                                          className="share_img products geo_loc"
                                                                                          width="25px"
                                                                                          height="25px"/>
                                                    </div>
                                                    <div><h2>{city.region}</h2></div>
                                                </Link>
                                                <div
                                                    style={{display: city.distance === null || city.distance === 0 ? "none" : "block"}}>{names.length > 1 ? `(Расстояние до ${userInputEnter.charAt(0).toUpperCase() + userInputEnter.slice(1)} ${city.distance} км.)` : null}</div>                                            </div>
                                        </div>
                                        {city.companyAlls && city.companyAlls.map((name, index) => {
                                            //const cityInEnglish = transliterate(name.nameCityTitle);
                                            //console.log('cityInEnglish',cityInEnglish)
                                            if (name.live === 'true') {
                                                return (
                                                    <div
                                                        key={index}
                                                        id={`element-${index}`}
                                                        className="item body_item"
                                                        // style={{
                                                        //     backgroundColor: name.live === 'false' ? "#4545451c" : "#ffffff",
                                                        //
                                                        // }}
                                                    >
                                                        <div className="storno"
                                                             style={name.live === 'false' ? {display: "block"} : {display: "none"}}>СТОРНО
                                                        </div>
                                                        <title>1</title>
                                                        {/*<div className="input_item">*/}
                                                        {/*    <div>id</div>*/}
                                                        {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
                                                        {/*    <div className="item_">{name.id}</div>*/}
                                                        {/*</div>*/}
                                                        <div
                                                            className="share icon"

                                                            style={{cursor: "pointer"}}
                                                            onClick={() => copyCurrentUrlToClipboard(`card/${name.id}/${name.innCompany}`)}
                                                        >
                                                            <img src={shareImg} className="share_img" width="35px"
                                                                 alt="Скопировать"
                                                                 height="35px"/>

                                                        </div>

                                                        <div className="reques__products">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{
                                                                    display:
                                                                        name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).flowers
                                                                            ? "block" : "none"
                                                                }}
                                                            >
                                                                <img src={shareFlowers} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).strawberry ? "block" : "none"}}
                                                            >
                                                                <img src={shareStrawberry}
                                                                     className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).balloon ? "block" : "none"}}
                                                            >
                                                                <img src={shareBaloon} className="share_img products"
                                                                     width="25px"
                                                                     height="25px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.typeOfActivity && isValidJson(name.typeOfActivity) && JSON.parse(name.typeOfActivity).cakes ? "block" : "none"}}
                                                            >
                                                                <img src={shareCake} className="share_img products"
                                                                     width="25px"
                                                                     alt="QR"
                                                                     height="25px"/>
                                                            </div>
                                                        </div>
                                                        <div className="reques">
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
                                                                style={{display: name.rs || name.QRrs ? "block" : "none"}}
                                                            >
                                                                <div className="name">Р/С</div>
                                                                <img src={shareBill} className="share_img" width="35px"
                                                                     alt="Р/С"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.qrStatic ? "block" : "none"}}
                                                            >
                                                                <img src={shareQr} className="share_img" width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                            <div
                                                                className="icon"
                                                                // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
                                                                style={{display: name.agreement ? "block" : "none"}}
                                                            >
                                                                <div className="name contractor">№</div>
                                                                <img src={shareContract} className="share_img"
                                                                     width="35px"
                                                                     alt="QR"
                                                                     height="35px"/>
                                                            </div>
                                                        </div>
                                                        <table>
                                                            <tbody>
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        {/*<div>Магазин</div>*/}
                                                                        {/*<input className="item_" defaultValue={name.name}/>*/}
                                                                        <div
                                                                            className="item_">{name.nameCompanyTitle}</div>
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>ИНН</div>*/}
                                                                    {/*    <div className="item_">*/}
                                                                    {/*        {name.INN}*/}
                                                                    {/*    </div>*/}
                                                                    {/*    <div className="copy icon"*/}
                                                                    {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
                                                                    {/*        <img src={copyImg} className="logo_img" width="20px"*/}
                                                                    {/*             height="20px"/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Город</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.city}/>*!/*/}
                                                                    {/*    /!*<div className="item_">*!/*/}
                                                                    {/*    /!*    {name.companyOrder.length > 0 &&*!/*/}
                                                                    {/*    /!*        <div>{name.companyOrder[0].city}</div>*!/*/}
                                                                    {/*    /!*    }*!/*/}
                                                                    {/*    /!*</div>*!/*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                                <td>
                                                                    {/*<div className="input_item">*/}
                                                                    {/*    <div>Регион</div>*/}
                                                                    {/*    /!*<input className="item_" defaultValue={name.region}/>*!/*/}
                                                                    {/*    <div className="item_">{name.region}</div>*/}
                                                                    {/*</div>*/}
                                                                </td>
                                                            </tr>
                                                            {
                                                                // name.comment2 !== '' && <tr>
                                                                //     <td colSpan="2">
                                                                //         <div className="input_item">
                                                                //             <div>Доп. города</div>
                                                                //             {/*<input className="item_" defaultValue={name.name}/>*/}
                                                                //             <div className="more_cities">{name.comment2}</div>
                                                                //         </div>
                                                                //     </td>
                                                                //     <td></td>
                                                                // </tr>
                                                            }
                                                            <tr>
                                                                <td>
                                                                    <div className="input_item">
                                                                        {/*<div>Кол-во заказов</div>*/}
                                                                        {/*<input*/}
                                                                        {/*    className="item_"*/}
                                                                        {/*    defaultValue={name.quantity}*/}
                                                                        {/*/>*/}
                                                                        <div
                                                                            className="item_">{name.orders.length}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="input_item">
                                                                        {/*<div>Оборот, руб</div>*/}
                                                                        {/*<input className="item_"*/}
                                                                        {/*       defaultValue={name.turnover}*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            {
                                                                                `${name.orders.filter(item => item.status === null).reduce((sum, result) => sum + Number(result.sentSum.replace(/\D/g, '')), 0)} руб`
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/*<tr>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div>Баланс, руб</div>*/}
                                                            {/*            <input*/}
                                                            {/*                className="item_"*/}
                                                            {/*                //value={name.balance}*/}
                                                            {/*            />*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*    <td>*/}
                                                            {/*        <div className="input_item">*/}
                                                            {/*            <div className="input_item">*/}
                                                            {/*                <div>Рейтинг(от 1 до 5)</div>*/}
                                                            {/*                <input*/}
                                                            {/*                    className="item_"*/}
                                                            {/*                    defaultValue={name.rating}*/}
                                                            {/*                />*/}
                                                            {/*                <div className="item_">{name.rating}</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </td>*/}
                                                            {/*</tr>*/}
                                                            <tr>
                                                                <td colSpan="2">
                                                                    <div className="input_item">
                                                                        {/*<div>Телефон</div>*/}
                                                                        {/*<div>!{`${cardStates[index] && cardStates[index][cityIndex]}`}</div>*/}
                                                                        {/*<input className="item_" name="leaderPhone"*/}
                                                                        {/*    // value={leaderPhone}*/}
                                                                        {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
                                                                        {/*    // placeholder={card.leaderPhone}*/}
                                                                        {/*       placeholder="Номер телефона"*/}
                                                                        {/*/>*/}
                                                                        <div className="item_">
                                                                            <div className="show__phones_links"
                                                                                 id={`mainPhone${cityIndex}_${index}`}>
                                                                                <div className="phones_links">
                                                                                    <div
                                                                                        className="phones_links__phone">{name.phones.length === 0 ? name.innCompany : name.phones[0].number}</div>
                                                                                    <div
                                                                                        className="catalog__contacts">
                                                                                        <div
                                                                                            className="phone_links">
                                                                                            <div
                                                                                                className="call icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`tel:${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="wa icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`whatsapp://send?phone=${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="telegram icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`https://t.me/${name.phones[0].number || ''}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className="vk icon">
                                                                                                {name.phones && name.phones[0] &&
                                                                                                    <a href={`viber://add?number=${(name.phones[0].number || '').replace(/^\+/, '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"
                                                                                                        />
                                                                                                    </a>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/*<div className="more_phones"*/}
                                                                            {/*     id={`phoneMore${index}`}*/}
                                                                            {/*     onClick={() => handleToggleShowPhones(index)}*/}
                                                                            {/*     style={{display: cardStates[0] && cardStates[0][index] > 1 ? "block" : "none"}}*/}
                                                                            {/*>*/}
                                                                            {/*    <img src={arrowDown}*/}
                                                                            {/*         className="share_img products arrow_down"*/}
                                                                            {/*         width="25px"*/}
                                                                            {/*         height="25px"*/}
                                                                            {/*    />*/}
                                                                            {/*</div>*/}
                                                                            <div className="more_phones"
                                                                                 id={`phoneMore${index}`}
                                                                                 onClick={() => handleToggleShowPhones(cityIndex, index)}
                                                                                 style={{
                                                                                     display: ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector !== "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] > 1) && name.phoneCompanyDirector === "") ||
                                                                                     ((cardStates[cityIndex] && cardStates[cityIndex][index] === 1) && name.phoneCompanyDirector !== "") ?
                                                                                         "block" : "none"
                                                                                 }}
                                                                            >
                                                                                <img src={arrowDown}
                                                                                     className="share_img products arrow_down"
                                                                                     width="25px"
                                                                                     height="25px"
                                                                                />
                                                                            </div>
                                                                            <div className="wrapper__phones_links"
                                                                                 id={`companyCard${cityIndex}_${index}`}>
                                                                                {name.phoneCompanyDirector !== '' &&
                                                                                    <div className="director_phone">
                                                                                        <div
                                                                                            className="phones_links__phone">{name.phoneCompanyDirector}</div>
                                                                                        <div
                                                                                            className="catalog__contacts">
                                                                                            <div
                                                                                                className="phone_links">
                                                                                                <div
                                                                                                    className="call icon">
                                                                                                    <a href={`tel:${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={phoneImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="wa icon">
                                                                                                    <a href={`whatsapp://send?phone=${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={waImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="telegram icon">
                                                                                                    <a href={`https://t.me/${name.phoneCompanyDirector}`}>
                                                                                                        <img
                                                                                                            src={tgImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="vk icon">
                                                                                                    <a href={`viber://add?number=${(name.phoneCompanyDirector ? name.phoneCompanyDirector.replace(/^\+/, '') : '')}`}>
                                                                                                        <img
                                                                                                            src={vbImg}
                                                                                                            className="logo_img"
                                                                                                            width="25px"
                                                                                                            height="25px"/>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {name.phones && name.phones.map((phone, indexKey) => {
                                                                                    console.log("phoneFRONT", phone.number)
                                                                                    return (
                                                                                        <div className="phones_links"
                                                                                             key={indexKey}>
                                                                                            <div
                                                                                                className="phones_links__phone">{phone.number || ''}</div>
                                                                                            <div
                                                                                                className="catalog__contacts">
                                                                                                <div
                                                                                                    className="phone_links">
                                                                                                    <div
                                                                                                        className="call icon">
                                                                                                        <a href={`tel:${phone.number}`}>
                                                                                                            <img
                                                                                                                src={phoneImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="wa icon">
                                                                                                        <a href={`whatsapp://send?phone=${phone.number}`}>
                                                                                                            <img
                                                                                                                src={waImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="telegram icon">
                                                                                                        <a href={`https://t.me/${phone.number}`}>
                                                                                                            <img
                                                                                                                src={tgImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="vk icon">
                                                                                                        <a href={`viber://add?number=${(phone.number ? phone.number.replace(/^\+/, '') : '')}`}>
                                                                                                            <img
                                                                                                                src={vbImg}
                                                                                                                className="logo_img"
                                                                                                                width="25px"
                                                                                                                height="25px"/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                        {/*Временно удалили*/}
                                                        {name.commentariesMain !== '' && (
                                                            <div className="input_item"
                                                            >
                                                                {/*<div>Комментарий</div>*/}
                                                                {/* <input className="item_" defaultValue={name.name}/> */}
                                                                <div
                                                                    style={{
                                                                        maxHeight: '4.5em', // Максимальная высота блока
                                                                        overflowY: 'auto',   // Прокрутка по вертикали
                                                                        lineHeight: '1.2em', // Высота строки
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 4, // Ограничение по количеству строк
                                                                        WebkitBoxOrient: 'vertical',
                                                                    }}
                                                                    className="item_card__button">{name.commentariesMain}</div>
                                                            </div>
                                                        )}
                                                        <div className="catalog__buttons">
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    // setShowCard(true);
                                                                    // setShowCatalog(false);
                                                                    // setSearch(false);
                                                                    openCardData(name.INN);
                                                                    // refreshPage();
                                                                    // changeMenu(false)

                                                                    scrollToElement(`element-${index}`);
                                                                    navigate(`/card/${city.id}/${name.innCompany}`);
                                                                }}
                                                                className="item_card__button"
                                                            >
                                                                {/*<Link to={`/card/${city.id}/${name.innCompany}`}>Открыть*/}
                                                                {/*    карточку</Link>*/}
                                                                Открыть карточку
                                                            </button>


                                                            {/*<button onClick={() => {*/}
                                                            {/*    // openCard(name.INN).then();*/}
                                                            {/*    // setShowOrders(true);*/}
                                                            {/*    // setShowCash(false);*/}
                                                            {/*    // setShowCatalog(false);*/}
                                                            {/*    // setShowCreateOrg(false);*/}
                                                            {/*    // setShowCard(false);*/}
                                                            {/*    // refreshPage();*/}
                                                            {/*    setBack(true)*/}
                                                            {/*}}*/}
                                                            {/*        className="item_card__button"*/}
                                                            {/*>*/}
                                                            {/*    <Link to={`/orders/${name.INN}`}>Заказы*/}
                                                            {/*        контрагента</Link>*/}
                                                            {/*    /!*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*!/*/}
                                                            {/*</button>*/}
                                                            {/*<button onClick={() => {*/}
                                                            {/*    // openCard(name.INN).then();*/}
                                                            {/*    // setShowCash(true);*/}
                                                            {/*    // setShowOrders(false);*/}
                                                            {/*    // setShowCatalog(false);*/}
                                                            {/*    // setShowCreateOrg(false);*/}
                                                            {/*    // setShowCard(false);*/}
                                                            {/*    // refreshPage();*/}
                                                            {/*    setBack(true)*/}
                                                            {/*}}*/}
                                                            {/*        className="item_card__button"*/}
                                                            {/*>*/}
                                                            {/*    <Link to={`/payments/${name.INN}`}>Деньги*/}
                                                            {/*        контрагента</Link>*/}
                                                            {/*</button>*/}
                                                            {/*<button onClick={(e) => {*/}
                                                            {/*    // setShowDeleteMsg(true);*/}
                                                            {/*    e.stopPropagation();*/}
                                                            {/*    // setDelINN*/}
                                                            {/*    setShowModal(true)*/}
                                                            {/*    setCurrentId(name.id)*/}
                                                            {/*}}*/}
                                                            {/*        className="item_card__button item_card__delete"*/}
                                                            {/*        style={name.live === 'false' ? {display: "none"} : {display: "block"}}*/}
                                                            {/*>X*/}
                                                            {/*</button>*/}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </>
                                )
                            }

                        })}


                        {/*<button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*        onClick={() => {*/}
                        {/*            setShowCount(prevCount => prevCount + 10);*/}
                        {/*            // showMoreButtonActive()*/}
                        {/*        }}*/}
                        {/*        className="item_card__button"*/}
                        {/*>*/}
                        {/*    Показать больше*/}
                        {/*</button>*/}

                        {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}

                        {/*{showCount < info.contractor.length && (*/}
                        {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
                        {/*            onClick={() => {*/}
                        {/*                setShowCount(prevCount => prevCount + 10);*/}
                        {/*                // showMoreButtonActive()*/}
                        {/*            }}*/}
                        {/*            className="item_card__button"*/}
                        {/*    >*/}
                        {/*        Показать больше*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*<div*/}
                        {/*    className="filter_window"*/}
                        {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        className="filter_window__content"*/}
                        {/*    >*/}
                        {/*        <div className="filter_window__header">*/}
                        {/*            <div>Фильтр</div>*/}
                        {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
                        {/*        </div>*/}
                        {/*        /!*<input className="item_" name="city"*!/*/}
                        {/*        /!*       placeholder="Город"/>*!/*/}
                        {/*        /!*<input className="item_" name="region"*!/*/}
                        {/*        /!*       placeholder="Регион"/>*!/*/}
                        {/*        /!*<input className="item_" name="rating"*!/*/}
                        {/*        /!*       placeholder="Рейтинг"/>*!/*/}
                        {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
                        {/*            <option value="bycity">По городу</option>*/}
                        {/*            <option value="byregion">По региону</option>*/}
                        {/*            <option value="byrating">По рейтингу</option>*/}
                        {/*        </select>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div
                            className="filter_window"
                            style={showFilter ? {display: "block"} : {display: "none"}}
                        >
                            <select
                                name="filterby"
                                className="filterby"
                                aria-label="Поле в списке"
                                onChange={openFilter}
                                defaultValue="all"
                            >
                                <option value="all">Все</option>
                                <option value="by_flowers">Цветы</option>
                                <option value="by_food">Фуд-флорист</option>
                                <option value="by_cakes">Торты</option>
                                <option value="by_baloons">Шары</option>
                            </select>
                        </div>
                    </div>
                    <div style={{display: show500 ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет данных по запросу</h2>
                    </div>
                    <div style={{display: showMore200km ? "block" : "none", textAlign: "center"}}>
                        <h2>Нет магазинов в радиусе 200 км</h2>
                    </div>
                </>
            );
        // case 'buh':
        //     return (
        //         <>
        //             <div className="catalog__items">
        //                 {/*<div*/}
        //                 {/*    className="contractor_add"*/}
        //                 {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}*/}
        //                 {/*>*/}
        //                 {/*    <Link to={`/create`}>*/}
        //                 {/*        <img src={plusImg} className="logo_img" width="25px"*/}
        //                 {/*             height="25px"/>*/}
        //                 {/*    </Link>*/}
        //                 {/*</div>*/}
        //                 <header
        //                     className="header header_body"
        //                     // style={headerMenu ? {display: "flex"} : {display: "none"}}
        //                 >
        //                     <div className="header__left">
        //
        //                     </div>
        //
        //                     <div className="header__right">
        //
        //                         <div className="input">
        //                             <input
        //                                 type="search"
        //                                 placeholder="Введите запрос"
        //                                 className="search-field"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 onChange={(e) => headerSearchValue(e)}
        //                                 value={searchValue}
        //                                 onKeyDown={handleSearchEnter}
        //                             />
        //                             <div className="clearBtn">
        //                                 {searchValue &&
        //                                     <button
        //                                         className="clear_X"
        //                                         style={showInput ? {display: "block"} : {display: "none"}}
        //                                         onClick={() => {
        //                                             handleClear();
        //                                         }}
        //                                     >
        //                                         X
        //                                     </button>
        //                                 }
        //                             </div>
        //                             <button
        //                                 className="search_"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 disabled={btnDis}
        //                                 onClick={() => {
        //                                     // headerSearch(searchValue);
        //                                     setBool(true);
        //                                     getNamesBD(searchValue)
        //                                     localStorage.setItem('searchValue', searchValue)
        //                                     // if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
        //                                     //     setShowMore(true);
        //                                     // } else {
        //                                     //     setShowMore(false);
        //                                     // }
        //                                 }}
        //                             >
        //                                 Поиск
        //                             </button>
        //                             <button
        //                                 type="submit"
        //                                 className="search-button"
        //                                 onClick={() => {
        //                                     setShowInput(!showInput)
        //                                 }}
        //                                 style={!showInput ? {display: "block"} : {display: "none"}}
        //                             >
        //                                 <img src={searchImg} className="logo_img" width="20px" height="20px"/>
        //                             </button>
        //                         </div>
        //                     </div>
        //
        //                 </header>
        //                 <h1>{info.role.role}</h1>
        //                 {names && names.map((name, index) => {
        //                     const cityInEnglish = transliterate(name.city);
        //                     return (
        //                         <div
        //                             key={index}
        //                             id={`element-${index}`}
        //                             className="item body_item"
        //                             style={{cursor: "pointer"}}
        //                         >
        //                             <title>1</title>
        //                             {/*<div className="input_item">*/}
        //                             {/*    <div>id</div>*/}
        //                             {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
        //                             {/*    <div className="item_">{name.id}</div>*/}
        //                             {/*</div>*/}
        //                             <div
        //                                 className="share icon"
        //                                 onClick={() => copyCurrentUrlToClipboard(`card/${cityInEnglish}/${name.INN}`)}
        //                             >
        //                                 <img src={shareImg} className="share_img" width="35px" alt="Скопировать"
        //                                      height="35px"/>
        //                             </div>
        //                             <div className="reques__products">
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{
        //                                         display: (name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).flowers) || (name.companyName.trim() !== '' && !/^фл/i.test(name.companyName)) ? "block" : "none"
        //                                     }}
        //                                 >
        //                                     <img src={shareFlowers} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).strawberry ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareStrawberry} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).balloon ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareBaloon} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).cakes ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareCake} className="share_img products" width="25px"
        //                                          alt="QR"
        //                                          height="25px"/>
        //                                 </div>
        //                             </div>
        //                             <div className="reques">
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
        //                                     style={{display: name.rs || name.QRrs ? "block" : "none"}}
        //                                 >
        //                                     <div className="name">Р/С</div>
        //                                     <img src={shareBill} className="share_img" width="35px" alt="Р/С"
        //                                          height="35px"/>
        //                                 </div>
        //                                 {/*<div*/}
        //                                 {/*    className="icon"*/}
        //                                 {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
        //                                 {/*    style={{display: name.QRrs ? "block" : "none"}}*/}
        //                                 {/*>*/}
        //                                 {/*    <div className="name">Р/С</div>*/}
        //                                 {/*    <img src={shareQr} className="share_img" width="35px" alt="QR"*/}
        //                                 {/*         height="35px"/>*/}
        //                                 {/*</div>*/}
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.QRstatic ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareQr} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.contract ? "block" : "none"}}
        //                                 >
        //                                     <div className="name contractor">№</div>
        //                                     <img src={shareContract} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                             </div>
        //                             <table>
        //                                 <tbody>
        //                                 <tr>
        //                                     <td colSpan="2">
        //                                         <div className="input_item">
        //                                             <div>Магазин</div>
        //                                             {/*<input className="item_" defaultValue={name.name}/>*/}
        //                                             <div className="item_">{name.companyName}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         {/*<div className="input_item">*/}
        //                                         {/*    <div>ИНН</div>*/}
        //                                         {/*    <div className="item_">*/}
        //                                         {/*        {name.INN}*/}
        //                                         {/*    </div>*/}
        //                                         {/*    <div className="copy icon"*/}
        //                                         {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
        //                                         {/*        <img src={copyImg} className="logo_img" width="20px"*/}
        //                                         {/*             height="20px"/>*/}
        //                                         {/*    </div>*/}
        //                                         {/*</div>*/}
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Город</div>
        //                                             {/*<input className="item_" defaultValue={name.city}/>*/}
        //                                             <div className="item_">
        //                                                 {name.companyOrder.length > 0 &&
        //                                                     <div>{name.companyOrder[0].city}</div>
        //                                                 }
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Регион</div>
        //                                             {/*<input className="item_" defaultValue={name.region}/>*/}
        //                                             <div className="item_">{name.region}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 {
        //                                     name.comment2 !== '' && <tr>
        //                                         <td colSpan="2">
        //                                             <div className="input_item">
        //                                                 <div>Доп. города</div>
        //                                                 {/*<input className="item_" defaultValue={name.name}/>*/}
        //                                                 <div className="more_cities">{name.comment2}</div>
        //                                             </div>
        //                                         </td>
        //                                         <td></td>
        //                                     </tr>
        //                                 }
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Кол-во заказов</div>
        //                                             {/*<input*/}
        //                                             {/*    className="item_"*/}
        //                                             {/*    defaultValue={name.quantity}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.companyOrder.length}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Оборот, руб</div>
        //                                             {/*<input className="item_"*/}
        //                                             {/*       defaultValue={name.turnover}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.sumAll}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 {/*<tr>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div>Баланс, руб</div>*/}
        //                                 {/*            <input*/}
        //                                 {/*                className="item_"*/}
        //                                 {/*                //value={name.balance}*/}
        //                                 {/*            />*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div className="input_item">*/}
        //                                 {/*                <div>Рейтинг(от 1 до 5)</div>*/}
        //                                 {/*                <input*/}
        //                                 {/*                    className="item_"*/}
        //                                 {/*                    defaultValue={name.rating}*/}
        //                                 {/*                />*/}
        //                                 {/*                <div className="item_">{name.rating}</div>*/}
        //                                 {/*            </div>*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*</tr>*/}
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Телефон</div>
        //                                             {/*<input className="item_" name="leaderPhone"*/}
        //                                             {/*    // value={leaderPhone}*/}
        //                                             {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
        //                                             {/*    // placeholder={card.leaderPhone}*/}
        //                                             {/*       placeholder="Номер телефона"*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.leaderPhone}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="catalog__contacts">
        //                                             <div className="phone_links">
        //                                                 <div className="call icon">
        //                                                     <a href={`tel:${name.leaderPhone}`}>
        //                                                         <img src={phoneImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="wa icon">
        //                                                     <a href={`whatsapp://send?phone=${name.leaderPhone}`}>
        //                                                         <img src={waImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="telegram icon">
        //                                                     <a href={`https://t.me/${name.leaderPhone}`}>
        //                                                         <img src={tgImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="vk icon">
        //                                                     <a href={`viber://add?number=${(name.leaderPhone).replace(/^\+/, '')}`}>
        //                                                         <img src={vbImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 </tbody>
        //                             </table>
        //                             {/*Временно удалили*/}
        //                             {/*<button onClick={(e) => {*/}
        //                             {/*    setShowDeleteMsg(true);*/}
        //                             {/*    e.stopPropagation();*/}
        //                             {/*    setDelINN(name.INN)*/}
        //                             {/*}}*/}
        //                             {/*        className="item_card__button"*/}
        //                             {/*>Удалить организацию*/}
        //                             {/*</button>*/}
        //                             <div className="catalog__buttons">
        //                                 <button onClick={(e) => {
        //                                     e.stopPropagation();
        //                                     // setShowCard(true);
        //                                     // setShowCatalog(false);
        //                                     // setSearch(false);
        //                                     openCardData(name.INN);
        //                                     // refreshPage();
        //                                     // changeMenu(false)
        //                                     scrollToElement(`element-${index}`);
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/card/${cityInEnglish}/${name.INN}`}>Открыть карточку</Link>
        //                                 </button>
        //
        //                                 <button onClick={() => {
        //                                     // openCard(name.INN).then();
        //                                     // setShowOrders(true);
        //                                     // setShowCash(false);
        //                                     // setShowCatalog(false);
        //                                     // setShowCreateOrg(false);
        //                                     // setShowCard(false);
        //                                     // refreshPage();
        //                                     setBack(true)
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/orders/${name.INN}`}>Заказы контрагента</Link>
        //                                     {/*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*/}
        //                                 </button>
        //                                 <button onClick={() => {
        //                                     // openCard(name.INN).then();
        //                                     // setShowCash(true);
        //                                     // setShowOrders(false);
        //                                     // setShowCatalog(false);
        //                                     // setShowCreateOrg(false);
        //                                     // setShowCard(false);
        //                                     // refreshPage();
        //                                     setBack(true)
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/payments/${name.INN}`}>Деньги контрагента</Link>
        //                                 </button>
        //                             </div>
        //                         </div>
        //                     )
        //                 })}
        //
        //                 {/*<button style={{display: role === "buh" && showMore ? "block" : "none"}}*/}
        //                 {/*        onClick={() => {*/}
        //                 {/*            setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*            // showMoreButtonActive()*/}
        //                 {/*        }}*/}
        //                 {/*        className="item_card__button"*/}
        //                 {/*>*/}
        //                 {/*    Показать больше*/}
        //                 {/*</button>*/}
        //
        //                 {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //
        //                 {/*{showCount < info.contractor.length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //                 {/*<div*/}
        //                 {/*    className="filter_window"*/}
        //                 {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
        //                 {/*>*/}
        //                 {/*    <div*/}
        //                 {/*        className="filter_window__content"*/}
        //                 {/*    >*/}
        //                 {/*        <div className="filter_window__header">*/}
        //                 {/*            <div>Фильтр</div>*/}
        //                 {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
        //                 {/*        </div>*/}
        //                 {/*        /!*<input className="item_" name="city"*!/*/}
        //                 {/*        /!*       placeholder="Город"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="region"*!/*/}
        //                 {/*        /!*       placeholder="Регион"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="rating"*!/*/}
        //                 {/*        /!*       placeholder="Рейтинг"/>*!/*/}
        //                 {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
        //                 {/*            <option value="bycity">По городу</option>*/}
        //                 {/*            <option value="byregion">По региону</option>*/}
        //                 {/*            <option value="byrating">По рейтингу</option>*/}
        //                 {/*        </select>*/}
        //                 {/*    </div>*/}
        //                 {/*</div>*/}
        //                 <div
        //                     className="filter_window"
        //                     style={showFilter ? {display: "block"} : {display: "none"}}
        //                 >
        //                     <select
        //                         name="filterby"
        //                         className="filterby"
        //                         aria-label="Поле в списке"
        //                         onChange={openFilter}
        //                         defaultValue="all"
        //                     >
        //                         <option value="all">Все</option>
        //                         <option value="by_flowers">Цветы</option>
        //                         <option value="by_food">Фуд-флорист</option>
        //                         <option value="by_cakes">Торты</option>
        //                         <option value="by_baloons">Шары</option>
        //                     </select>
        //                 </div>
        //             </div>
        //         </>
        //     );
        // case 'manager':
        //     return (
        //         <>
        //             <div className="catalog__items">
        //                 {/*<div*/}
        //                 {/*    className="contractor_add"*/}
        //                 {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}*/}
        //                 {/*>*/}
        //                 {/*    <Link to={`/create`}>*/}
        //                 {/*        <img src={plusImg} className="logo_img" width="25px"*/}
        //                 {/*             height="25px"/>*/}
        //                 {/*    </Link>*/}
        //                 {/*</div>*/}
        //                 <header
        //                     className="header header_body"
        //                     // style={headerMenu ? {display: "flex"} : {display: "none"}}
        //                 >
        //                     <div className="header__left">
        //
        //                     </div>
        //
        //                     <div className="header__right">
        //
        //                         <div className="input">
        //                             <input
        //                                 type="search"
        //                                 placeholder="Введите запрос"
        //                                 className="search-field"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 onChange={(e) => headerSearchValue(e)}
        //                                 value={searchValue}
        //                                 onKeyDown={handleSearchEnter}
        //                             />
        //                             <div className="clearBtn">
        //                                 {searchValue &&
        //                                     <button
        //                                         className="clear_X"
        //                                         style={showInput ? {display: "block"} : {display: "none"}}
        //                                         onClick={() => {
        //                                             handleClear();
        //                                         }}
        //                                     >
        //                                         X
        //                                     </button>
        //                                 }
        //                             </div>
        //                             <button
        //                                 className="search_"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 disabled={btnDis}
        //                                 onClick={() => {
        //                                     // headerSearch(searchValue);
        //                                     setBool(true);
        //                                     getNamesBD(searchValue)
        //                                     localStorage.setItem('searchValue', searchValue)
        //                                     // if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
        //                                     //     setShowMore(true);
        //                                     // } else {
        //                                     //     setShowMore(false);
        //                                     // }
        //                                 }}
        //                             >
        //                                 Поиск
        //                             </button>
        //                             <button
        //                                 type="submit"
        //                                 className="search-button"
        //                                 onClick={() => {
        //                                     setShowInput(!showInput)
        //                                 }}
        //                                 style={!showInput ? {display: "block"} : {display: "none"}}
        //                             >
        //                                 <img src={searchImg} className="logo_img" width="20px" height="20px"/>
        //                             </button>
        //                         </div>
        //                     </div>
        //
        //                 </header>
        //                 <h1>{info.role.role}</h1>
        //                 {names && names.map((name, index) => {
        //                     const cityInEnglish = transliterate(name.city);
        //                     return (
        //                         <div
        //                             key={index}
        //                             id={`element-${index}`}
        //                             className="item body_item"
        //                             style={{cursor: "pointer"}}
        //                         >
        //                             <title>1</title>
        //                             {/*<div className="input_item">*/}
        //                             {/*    <div>id</div>*/}
        //                             {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
        //                             {/*    <div className="item_">{name.id}</div>*/}
        //                             {/*</div>*/}
        //                             <div
        //                                 className="share icon"
        //                                 onClick={() => copyCurrentUrlToClipboard(`card/${cityInEnglish}/${name.INN}`)}
        //                             >
        //                                 <img src={shareImg} className="share_img" width="35px" alt="Скопировать"
        //                                      height="35px"/>
        //                             </div>
        //                             <div className="reques__products">
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{
        //                                         display: (name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).flowers) || (name.companyName.trim() !== '' && !/^фл/i.test(name.companyName)) ? "block" : "none"
        //                                     }}
        //                                 >
        //                                     <img src={shareFlowers} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).strawberry ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareStrawberry} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).balloon ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareBaloon} className="share_img products" width="25px"
        //                                          height="25px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.siteName && isValidJson(name.siteName) && JSON.parse(name.siteName).cakes ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareCake} className="share_img products" width="25px"
        //                                          alt="QR"
        //                                          height="25px"/>
        //                                 </div>
        //                             </div>
        //                             <div className="reques">
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
        //                                     style={{display: name.rs || name.QRrs ? "block" : "none"}}
        //                                 >
        //                                     <div className="name">Р/С</div>
        //                                     <img src={shareBill} className="share_img" width="35px" alt="Р/С"
        //                                          height="35px"/>
        //                                 </div>
        //                                 {/*<div*/}
        //                                 {/*    className="icon"*/}
        //                                 {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
        //                                 {/*    style={{display: name.QRrs ? "block" : "none"}}*/}
        //                                 {/*>*/}
        //                                 {/*    <div className="name">Р/С</div>*/}
        //                                 {/*    <img src={shareQr} className="share_img" width="35px" alt="QR"*/}
        //                                 {/*         height="35px"/>*/}
        //                                 {/*</div>*/}
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.QRstatic ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareQr} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.contract ? "block" : "none"}}
        //                                 >
        //                                     <div className="name contractor">№</div>
        //                                     <img src={shareContract} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                             </div>
        //                             <table>
        //                                 <tbody>
        //                                 <tr>
        //                                     <td colSpan="2">
        //                                         <div className="input_item">
        //                                             <div>Магазин</div>
        //                                             {/*<input className="item_" defaultValue={name.name}/>*/}
        //                                             <div className="item_">{name.companyName}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         {/*<div className="input_item">*/}
        //                                         {/*    <div>ИНН</div>*/}
        //                                         {/*    <div className="item_">*/}
        //                                         {/*        {name.INN}*/}
        //                                         {/*    </div>*/}
        //                                         {/*    <div className="copy icon"*/}
        //                                         {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
        //                                         {/*        <img src={copyImg} className="logo_img" width="20px"*/}
        //                                         {/*             height="20px"/>*/}
        //                                         {/*    </div>*/}
        //                                         {/*</div>*/}
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Город</div>
        //                                             {/*<input className="item_" defaultValue={name.city}/>*/}
        //                                             <div className="item_">
        //                                                 {name.companyOrder.length > 0 &&
        //                                                     <div>{name.companyOrder[0].city}</div>
        //                                                 }
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Регион</div>
        //                                             {/*<input className="item_" defaultValue={name.region}/>*/}
        //                                             <div className="item_">{name.region}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 {
        //                                     name.comment2 !== '' && <tr>
        //                                         <td colSpan="2">
        //                                             <div className="input_item">
        //                                                 <div>Доп. города</div>
        //                                                 {/*<input className="item_" defaultValue={name.name}/>*/}
        //                                                 <div className="more_cities">{name.comment2}</div>
        //                                             </div>
        //                                         </td>
        //                                         <td></td>
        //                                     </tr>
        //                                 }
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Кол-во заказов</div>
        //                                             {/*<input*/}
        //                                             {/*    className="item_"*/}
        //                                             {/*    defaultValue={name.quantity}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.companyOrder.length}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Оборот, руб</div>
        //                                             {/*<input className="item_"*/}
        //                                             {/*       defaultValue={name.turnover}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.sumAll}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 {/*<tr>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div>Баланс, руб</div>*/}
        //                                 {/*            <input*/}
        //                                 {/*                className="item_"*/}
        //                                 {/*                //value={name.balance}*/}
        //                                 {/*            />*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div className="input_item">*/}
        //                                 {/*                <div>Рейтинг(от 1 до 5)</div>*/}
        //                                 {/*                <input*/}
        //                                 {/*                    className="item_"*/}
        //                                 {/*                    defaultValue={name.rating}*/}
        //                                 {/*                />*/}
        //                                 {/*                <div className="item_">{name.rating}</div>*/}
        //                                 {/*            </div>*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*</tr>*/}
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Телефон</div>
        //                                             {/*<input className="item_" name="leaderPhone"*/}
        //                                             {/*    // value={leaderPhone}*/}
        //                                             {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
        //                                             {/*    // placeholder={card.leaderPhone}*/}
        //                                             {/*       placeholder="Номер телефона"*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.leaderPhone}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="catalog__contacts">
        //                                             <div className="phone_links">
        //                                                 <div className="call icon">
        //                                                     <a href={`tel:${name.leaderPhone}`}>
        //                                                         <img src={phoneImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="wa icon">
        //                                                     <a href={`whatsapp://send?phone=${name.leaderPhone}`}>
        //                                                         <img src={waImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="telegram icon">
        //                                                     <a href={`https://t.me/${name.leaderPhone}`}>
        //                                                         <img src={tgImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="vk icon">
        //                                                     <a href={`viber://add?number=${(name.leaderPhone).replace(/^\+/, '')}`}>
        //                                                         <img src={vbImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 </tbody>
        //                             </table>
        //                             {/*Временно удалили*/}
        //                             {/*<button onClick={(e) => {*/}
        //                             {/*    setShowDeleteMsg(true);*/}
        //                             {/*    e.stopPropagation();*/}
        //                             {/*    setDelINN(name.INN)*/}
        //                             {/*}}*/}
        //                             {/*        className="item_card__button"*/}
        //                             {/*>Удалить организацию*/}
        //                             {/*</button>*/}
        //                             <div className="catalog__buttons" style={{textAlign: "center"}}>
        //                                 {/*<Link to={`/card/${cityInEnglish}/${name.INN}`}>*/}
        //                                 {/*      onClick={(e) => {*/}
        //                                 {/*        e.stopPropagation();*/}
        //                                 {/*        // setShowCard(true);*/}
        //                                 {/*        // setShowCatalog(false);*/}
        //                                 {/*        // setSearch(false);*/}
        //                                 {/*        openCardData(name.INN);*/}
        //                                 {/*        // refreshPage();*/}
        //                                 {/*        // changeMenu(false)*/}
        //                                 {/*        scrollToElement(`element-${index}`);*/}
        //                                 {/*    }} className="item_card__button"*/}
        //
        //                                 {/*>*/}
        //                                 {/*    Открыть карточку*/}
        //                                 {/*</Link>*/}
        //                                 <Link to={`/card/${cityInEnglish}/${name.INN}`}
        //                                       onClick={(e) => {
        //                                           e.stopPropagation();
        //                                           openCardData(name.INN);
        //                                           scrollToElement(`element-${index}`);
        //                                       }}
        //                                       className="item_card__button" // добавьте класс для стилей
        //                                 >
        //                                     Открыть карточку
        //                                 </Link>
        //
        //                                 {/*<button onClick={() => {*/}
        //                                 {/*    // openCard(name.INN).then();*/}
        //                                 {/*    // setShowOrders(true);*/}
        //                                 {/*    // setShowCash(false);*/}
        //                                 {/*    // setShowCatalog(false);*/}
        //                                 {/*    // setShowCreateOrg(false);*/}
        //                                 {/*    // setShowCard(false);*/}
        //                                 {/*    // refreshPage();*/}
        //                                 {/*    setBack(true)*/}
        //                                 {/*}}*/}
        //                                 {/*        className="item_card__button"*/}
        //                                 {/*>*/}
        //                                 {/*    <Link to={`/orders/${name.INN}`}>Заказы контрагента</Link>*/}
        //                                 {/*    /!*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*!/*/}
        //                                 {/*</button>*/}
        //                                 {/*<button onClick={() => {*/}
        //                                 {/*    // openCard(name.INN).then();*/}
        //                                 {/*    // setShowCash(true);*/}
        //                                 {/*    // setShowOrders(false);*/}
        //                                 {/*    // setShowCatalog(false);*/}
        //                                 {/*    // setShowCreateOrg(false);*/}
        //                                 {/*    // setShowCard(false);*/}
        //                                 {/*    // refreshPage();*/}
        //                                 {/*    setBack(true)*/}
        //                                 {/*}}*/}
        //                                 {/*        className="item_card__button"*/}
        //                                 {/*>*/}
        //                                 {/*    <Link to={`/payments/${name.INN}`}>Деньги контрагента</Link>*/}
        //                                 {/*</button>*/}
        //                             </div>
        //                         </div>
        //                     )
        //                 })}
        //
        //                 {/*<button style={{display: role === "manager" && showMore ? "block" : "none"}}*/}
        //                 {/*        onClick={() => {*/}
        //                 {/*            setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*            // showMoreButtonActive()*/}
        //                 {/*        }}*/}
        //                 {/*        className="item_card__button"*/}
        //                 {/*>*/}
        //                 {/*    Показать больше*/}
        //                 {/*</button>*/}
        //
        //                 {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //
        //                 {/*{showCount < info.contractor.length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //                 {/*<div*/}
        //                 {/*    className="filter_window"*/}
        //                 {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
        //                 {/*>*/}
        //                 {/*    <div*/}
        //                 {/*        className="filter_window__content"*/}
        //                 {/*    >*/}
        //                 {/*        <div className="filter_window__header">*/}
        //                 {/*            <div>Фильтр</div>*/}
        //                 {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
        //                 {/*        </div>*/}
        //                 {/*        /!*<input className="item_" name="city"*!/*/}
        //                 {/*        /!*       placeholder="Город"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="region"*!/*/}
        //                 {/*        /!*       placeholder="Регион"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="rating"*!/*/}
        //                 {/*        /!*       placeholder="Рейтинг"/>*!/*/}
        //                 {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
        //                 {/*            <option value="bycity">По городу</option>*/}
        //                 {/*            <option value="byregion">По региону</option>*/}
        //                 {/*            <option value="byrating">По рейтингу</option>*/}
        //                 {/*        </select>*/}
        //                 {/*    </div>*/}
        //                 {/*</div>*/}
        //                 <div
        //                     className="filter_window"
        //                     style={showFilter ? {display: "block"} : {display: "none"}}
        //                 >
        //                     <select
        //                         name="filterby"
        //                         className="filterby"
        //                         aria-label="Поле в списке"
        //                         onChange={openFilter}
        //                         defaultValue="all"
        //                     >
        //                         <option value="all">Все</option>
        //                         <option value="by_flowers">Цветы</option>
        //                         <option value="by_food">Фуд-флорист</option>
        //                         <option value="by_cakes">Торты</option>
        //                         <option value="by_baloons">Шары</option>
        //                     </select>
        //                 </div>
        //             </div>
        //         </>
        //     );
        // case 'shop':
        //     return (
        //         <>
        //             <div className="catalog__items">
        //                 <div
        //                     className="contractor_add"
        //                     style={{display: "flex", justifyContent: "center", alignItems: "center"}}
        //                 >
        //                     <Link to={`/create`}>
        //                         <img src={plusImg} className="logo_img" width="25px"
        //                              height="25px"/>
        //                     </Link>
        //                 </div>
        //                 <header
        //                     className="header header_body"
        //                     // style={headerMenu ? {display: "flex"} : {display: "none"}}
        //                 >
        //                     <div className="header__left">
        //
        //                     </div>
        //
        //                     <div className="header__right">
        //
        //                         <div className="input">
        //                             <input
        //                                 type="search"
        //                                 placeholder="Введите запрос"
        //                                 className="search-field"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 onChange={(e) => headerSearchValue(e)}
        //                                 value={searchValue}
        //                                 onKeyDown={handleSearchEnter}
        //                             />
        //                             <div className="clearBtn">
        //                                 {searchValue &&
        //                                     <button
        //                                         className="clear_X"
        //                                         style={showInput ? {display: "block"} : {display: "none"}}
        //                                         onClick={() => {
        //                                             handleClear();
        //                                         }}
        //                                     >
        //                                         X
        //                                     </button>
        //                                 }
        //                             </div>
        //                             <button
        //                                 className="search_"
        //                                 style={showInput ? {display: "block"} : {display: "none"}}
        //                                 disabled={btnDis}
        //                                 onClick={() => {
        //                                     // headerSearch(searchValue);
        //                                     setBool(true);
        //                                     getNamesBD(searchValue)
        //                                     localStorage.setItem('searchValue', searchValue)
        //                                     // if (showCount < info.contractor.filter(city => city.city === searchValue).length) {
        //                                     //     setShowMore(true);
        //                                     // } else {
        //                                     //     setShowMore(false);
        //                                     // }
        //                                 }}
        //                             >
        //                                 Поиск
        //                             </button>
        //                             <button
        //                                 type="submit"
        //                                 className="search-button"
        //                                 onClick={() => {
        //                                     setShowInput(!showInput)
        //                                 }}
        //                                 style={!showInput ? {display: "block"} : {display: "none"}}
        //                             >
        //                                 <img src={searchImg} className="logo_img" width="20px" height="20px"/>
        //                             </button>
        //                         </div>
        //                     </div>
        //
        //                 </header>
        //                 <h1>{info.role.role}</h1>
        //                 {names && names.map((name, index) => {
        //                     const cityInEnglish = transliterate(name.city);
        //                     return (
        //                         <div
        //                             key={index}
        //                             id={`element-${index}`}
        //                             className="item body_item"
        //                             style={{cursor: "pointer"}}
        //                         >
        //                             <title>1</title>
        //                             {/*<div className="input_item">*/}
        //                             {/*    <div>id</div>*/}
        //                             {/*    /!*<input className="item_" defaultValue={name.id}/>*!/*/}
        //                             {/*    <div className="item_">{name.id}</div>*/}
        //                             {/*</div>*/}
        //                             <div
        //                                 className="share icon"
        //                                 onClick={() => copyCurrentUrlToClipboard(`card/${cityInEnglish}/${name.INN}`)}
        //                             >
        //                                 <img src={shareImg} className="share_img" width="35px" alt="Скопировать"
        //                                      height="35px"/>
        //                             </div>
        //                             <div className="reques">
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.rs}`)}
        //                                     style={{display: name.rs || name.QRrs ? "block" : "none"}}
        //                                 >
        //                                     <div className="name">Р/С</div>
        //                                     <img src={shareBill} className="share_img" width="35px" alt="Р/С"
        //                                          height="35px"/>
        //                                 </div>
        //                                 {/*<div*/}
        //                                 {/*    className="icon"*/}
        //                                 {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
        //                                 {/*    style={{display: name.QRrs ? "block" : "none"}}*/}
        //                                 {/*>*/}
        //                                 {/*    <div className="name">Р/С</div>*/}
        //                                 {/*    <img src={shareQr} className="share_img" width="35px" alt="QR"*/}
        //                                 {/*         height="35px"/>*/}
        //                                 {/*</div>*/}
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.QRstatic ? "block" : "none"}}
        //                                 >
        //                                     <img src={shareQr} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                                 <div
        //                                     className="icon"
        //                                     // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}
        //                                     style={{display: name.contract ? "block" : "none"}}
        //                                 >
        //                                     <div className="name contractor">№</div>
        //                                     <img src={shareContract} className="share_img" width="35px" alt="QR"
        //                                          height="35px"/>
        //                                 </div>
        //                             </div>
        //                             <table>
        //                                 <tbody>
        //                                 <tr>
        //                                     <td colSpan="2">
        //                                         <div className="input_item">
        //                                             <div>Магазин</div>
        //                                             {/*<input className="item_" defaultValue={name.name}/>*/}
        //                                             <div className="item_">{name.companyName}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         {/*<div className="input_item">*/}
        //                                         {/*    <div>ИНН</div>*/}
        //                                         {/*    <div className="item_">*/}
        //                                         {/*        {name.INN}*/}
        //                                         {/*    </div>*/}
        //                                         {/*    <div className="copy icon"*/}
        //                                         {/*         onClick={() => copyToClipboardOnClick(name.companyName)}>*/}
        //                                         {/*        <img src={copyImg} className="logo_img" width="20px"*/}
        //                                         {/*             height="20px"/>*/}
        //                                         {/*    </div>*/}
        //                                         {/*</div>*/}
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Город</div>
        //                                             {/*<input className="item_" defaultValue={name.city}/>*/}
        //                                             <div className="item_">
        //                                                 {name.companyOrder.length > 0 &&
        //                                                     <div>{name.companyOrder[0].city}</div>
        //                                                 }
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Регион</div>
        //                                             {/*<input className="item_" defaultValue={name.region}/>*/}
        //                                             <div className="item_">{name.region}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Кол-во заказов</div>
        //                                             {/*<input*/}
        //                                             {/*    className="item_"*/}
        //                                             {/*    defaultValue={name.quantity}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.companyOrder.length}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Оборот, руб</div>
        //                                             {/*<input className="item_"*/}
        //                                             {/*       defaultValue={name.turnover}*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.sumAll}</div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 {/*<tr>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div>Баланс, руб</div>*/}
        //                                 {/*            <input*/}
        //                                 {/*                className="item_"*/}
        //                                 {/*                //value={name.balance}*/}
        //                                 {/*            />*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*    <td>*/}
        //                                 {/*        <div className="input_item">*/}
        //                                 {/*            <div className="input_item">*/}
        //                                 {/*                <div>Рейтинг(от 1 до 5)</div>*/}
        //                                 {/*                <input*/}
        //                                 {/*                    className="item_"*/}
        //                                 {/*                    defaultValue={name.rating}*/}
        //                                 {/*                />*/}
        //                                 {/*                <div className="item_">{name.rating}</div>*/}
        //                                 {/*            </div>*/}
        //                                 {/*        </div>*/}
        //                                 {/*    </td>*/}
        //                                 {/*</tr>*/}
        //                                 <tr>
        //                                     <td>
        //                                         <div className="input_item">
        //                                             <div>Телефон</div>
        //                                             {/*<input className="item_" name="leaderPhone"*/}
        //                                             {/*    // value={leaderPhone}*/}
        //                                             {/*    // onChange={handleSentLeaderPhone} disabled={dis}*/}
        //                                             {/*    // placeholder={card.leaderPhone}*/}
        //                                             {/*       placeholder="Номер телефона"*/}
        //                                             {/*/>*/}
        //                                             <div className="item_">{name.leaderPhone}</div>
        //                                         </div>
        //                                     </td>
        //                                     <td>
        //                                         <div className="catalog__contacts">
        //                                             <div className="phone_links">
        //                                                 <div className="call icon">
        //                                                     <a href={`tel:${name.leaderPhone}`}>
        //                                                         <img src={phoneImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="wa icon">
        //                                                     <a href={`whatsapp://send?phone=${name.leaderPhone}`}>
        //                                                         <img src={waImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="telegram icon">
        //                                                     <a href={`https://t.me/${name.leaderPhone}`}>
        //                                                         <img src={tgImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                                 <div className="vk icon">
        //                                                     <a href={`viber://add?number=${(name.leaderPhone).replace(/^\+/, '')}`}>
        //                                                         <img src={vbImg} className="logo_img" width="25px"
        //                                                              height="25px"/>
        //                                                     </a>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </td>
        //                                 </tr>
        //                                 </tbody>
        //                             </table>
        //                             {/*Временно удалили*/}
        //                             {/*<button onClick={(e) => {*/}
        //                             {/*    setShowDeleteMsg(true);*/}
        //                             {/*    e.stopPropagation();*/}
        //                             {/*    setDelINN(name.INN)*/}
        //                             {/*}}*/}
        //                             {/*        className="item_card__button"*/}
        //                             {/*>Удалить организацию*/}
        //                             {/*</button>*/}
        //                             <div className="catalog__buttons">
        //                                 <button onClick={(e) => {
        //                                     e.stopPropagation();
        //                                     // setShowCard(true);
        //                                     // setShowCatalog(false);
        //                                     // setSearch(false);
        //                                     openCardData(name.INN);
        //                                     // refreshPage();
        //                                     // changeMenu(false)
        //                                     scrollToElement(`element-${index}`);
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/card/${cityInEnglish}/${name.INN}`}>Открыть карточку</Link>
        //                                 </button>
        //
        //                                 <button onClick={() => {
        //                                     // openCard(name.INN).then();
        //                                     // setShowOrders(true);
        //                                     // setShowCash(false);
        //                                     // setShowCatalog(false);
        //                                     // setShowCreateOrg(false);
        //                                     // setShowCard(false);
        //                                     // refreshPage();
        //                                     setBack(true)
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/orders/${name.INN}`}>Заказы контрагента</Link>
        //                                     {/*<Link to={goToComponentB(name.INN)}>Заказы контрагента</Link>*/}
        //                                 </button>
        //                                 <button onClick={() => {
        //                                     // openCard(name.INN).then();
        //                                     // setShowCash(true);
        //                                     // setShowOrders(false);
        //                                     // setShowCatalog(false);
        //                                     // setShowCreateOrg(false);
        //                                     // setShowCard(false);
        //                                     // refreshPage();
        //                                     setBack(true)
        //                                 }}
        //                                         className="item_card__button"
        //                                 >
        //                                     <Link to={`/payments/${name.INN}`}>Деньги контрагента</Link>
        //                                 </button>
        //                             </div>
        //                         </div>
        //                     )
        //                 })}
        //
        //                 {/*<button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*        onClick={() => {*/}
        //                 {/*            setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*            // showMoreButtonActive()*/}
        //                 {/*        }}*/}
        //                 {/*        className="item_card__button"*/}
        //                 {/*>*/}
        //                 {/*    Показать больше*/}
        //                 {/*</button>*/}
        //
        //                 {/*{showCount < info.contractor.filter(city => city.city === searchValue).length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //
        //                 {/*{showCount < info.contractor.length && (*/}
        //                 {/*    <button style={{display: role === "admin" && showMore ? "block" : "none"}}*/}
        //                 {/*            onClick={() => {*/}
        //                 {/*                setShowCount(prevCount => prevCount + 10);*/}
        //                 {/*                // showMoreButtonActive()*/}
        //                 {/*            }}*/}
        //                 {/*            className="item_card__button"*/}
        //                 {/*    >*/}
        //                 {/*        Показать больше*/}
        //                 {/*    </button>*/}
        //                 {/*)}*/}
        //                 {/*<div*/}
        //                 {/*    className="filter_window"*/}
        //                 {/*    style={showFilter ? {display: "block"} : {display: "none"}}*/}
        //                 {/*>*/}
        //                 {/*    <div*/}
        //                 {/*        className="filter_window__content"*/}
        //                 {/*    >*/}
        //                 {/*        <div className="filter_window__header">*/}
        //                 {/*            <div>Фильтр</div>*/}
        //                 {/*            /!*<div className="filter_window__close" onClick={openFilter}>X</div>*!/*/}
        //                 {/*        </div>*/}
        //                 {/*        /!*<input className="item_" name="city"*!/*/}
        //                 {/*        /!*       placeholder="Город"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="region"*!/*/}
        //                 {/*        /!*       placeholder="Регион"/>*!/*/}
        //                 {/*        /!*<input className="item_" name="rating"*!/*/}
        //                 {/*        /!*       placeholder="Рейтинг"/>*!/*/}
        //                 {/*        <select name="filterby" className="filterby" aria-label="Полк в списке">*/}
        //                 {/*            <option value="bycity">По городу</option>*/}
        //                 {/*            <option value="byregion">По региону</option>*/}
        //                 {/*            <option value="byrating">По рейтингу</option>*/}
        //                 {/*        </select>*/}
        //                 {/*    </div>*/}
        //                 {/*</div>*/}
        //                 <div
        //                     className="filter_window"
        //                     style={showFilter ? {display: "block"} : {display: "none"}}
        //                 >
        //                     <select
        //                         name="filterby"
        //                         className="filterby"
        //                         aria-label="Поле в списке"
        //                         onChange={openFilter}
        //                         defaultValue="all"
        //                     >
        //                         <option value="all">Все</option>
        //                         <option value="by_flowers">Цветы</option>
        //                         <option value="by_food">Фуд-флорист</option>
        //                         <option value="by_cakes">Торты</option>
        //                         <option value="by_baloons">Шары</option>
        //                     </select>
        //                 </div>
        //             </div>
        //         </>
        //     );
    }
};
export {TheBody};
