import './App.css';
import {TheHeader} from "./components/TheHeader";
import {TheBody} from "./components/TheBody";
import {TheFooter} from "./components/TheFooter";
import {TheCard} from "./components/TheCard";
import {TheCardViewer} from "./components/TheCardViewer";
import {TheCreate} from "./components/TheCreate";
import {TheOrderCreate} from "./components/TheOrderCreate";
import {ThePayments} from "./components/ThePayments";
import {ThePayCard} from "./components/ThePayCard";
import {TheOrders} from "./components/TheOrders";
import {TheOrderCard} from "./components/TheOrderCard";
import {TheEmployees} from "./components/TheEmployees"
import {TheDeletedContractors} from "./components/TheDeletedContractors"
import {TheNotifications} from "./components/TheNotifications"
import {TheStatistics} from "./components/TheStatistics"
import {TheCityStatistics} from "./components/TheCityStatistics"
import {TheCityStatisticsTest} from "./components/TheCityStatisticsTest"
import {TheAppReg} from "./components/TheAppReg"
import React, {useEffect, useState} from "react";
import {
    //getCompany,
    getOne} from "./http/catalogAPI";
import {Route, Routes, BrowserRouter, NavLink} from "react-router-dom";
import preloadImg from "./img/tube-spinner.svg";
//import {render} from "@testing-library/react";
import {getUser} from "./http/userAPI";
import {TheUpdate} from "./components/TheUpdate";
import {TheDeals} from "./components/TheDeals";
import {TheDealsStatistics} from "./components/TheDealsStatistics";
import {TheDealsStatisticsBootstrap} from "./components/TheDealsStatisticsBootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import TheBotOne from "./components/TheBotOne";
import TheOrderShop from "./components/TheOrderShop";
import TheMessageAnalysis from "./components/TheMessageAnalysis";

function App({role}) {
    const [company, setCompany] = useState([]);
    const [backOnPay, setBackOnPay] = useState(false);
    const [open, setOpen] = useState(false)
    const [inn, setInn] = useState('')
    const [showFilter, setShowFilter] = useState(false);
    const [roleNow, setRoleNow] = useState('');
    const [renderClick, setRenderClick] = useState(false)
    const [preloader, setPreloader] = useState(false)
    const [users, setUsers] = useState([])
    const [sentCard, setSentCard] = useState([])
    const [cardOrCity, setCardOrCity] = useState('')
    const [cityEn, setCityEn] = useState('')


    useEffect(() => {
        setRoleNow(role)
    }, []);

    useEffect(() => {
        const fetch = async () => {
            const result = await getUser();
            console.log('result', result)

            if (role.role === 'admin') {
                const filterManager = result.filter(role => role.email !== 'super@mail.ru' && role.email !== 'web-fishers@yandex.ru')
                setUsers(filterManager)
            } else {
                const filterManager = result.filter(role => role.email !== 'super@mail.ru' && role.email !== 'web-fishers@yandex.ru')
                setUsers(filterManager)
            }
        }
        fetch();
    }, []);


    // useEffect(() => {
    //     const currentPage = window.location.pathname;
    //     const encodedPage = encodeURIComponent(currentPage);
    //     localStorage.setItem('currentPage', encodedPage);
    // }, [window.location.pathname]);

    // useEffect(() => {
    //     console.log('useEffect App.js')
    //
    //     async function fetchData() {
    //         setPreloader(true)
    //
    //         const sentBd = {
    //             name: cardOrCity
    //         }
    //
    //         // const data = await getOne(sentBd)
    //         const data = []
    //         console.log('getOne', sentBd)
    //
    //             // await getCompany('all')
    //         setPreloader(false)
    //         console.log("data !!! APP!!!", data)
    //         let groupedOrders = {};
    //         for (let i = 0; i < data.length; i++) {
    //             const order = data[i];
    //             if (!groupedOrders[order.INN]) {
    //                 groupedOrders[order.INN] = [order];
    //             } else {
    //                 groupedOrders[order.INN].push(order);
    //             }
    //         }
    //
    //         console.log('!!!!groupedOrders', groupedOrders)
    //
    //         const result = [];
    //         Object.keys(groupedOrders).forEach(inn => {
    //             const comp = groupedOrders[inn];
    //             let sumPayment = 0;
    //             comp.forEach(order => {
    //                 if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
    //                     const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
    //                     sumPayment += parseFloat(storePayment);
    //                 }
    //             });
    //             result.push({
    //                 companyName: comp[0].name,
    //                 INN: comp[0].INN,
    //                 comment1: comp[0].messenger1,
    //                 comment2: comp[0].messenger2,
    //                 OGRN: comp[0].OGRN,
    //                 region: comp[0].region,
    //                 city: comp[0].city,
    //                 citybd: comp[0].citybd,
    //                 id: comp[0].id,
    //                 leaderPhone: comp[0].leaderPhone,
    //                 contract: comp[0].contract,
    //                 BIK: comp[0].BIK,
    //                 QRrs: comp[0].QRrs,
    //                 QRstatic: comp[0].QRstatic,
    //                 workPhone1: comp[0].workPhone1,
    //                 workPhone2: comp[0].workPhone2,
    //                 turnover: comp[0].turnover,
    //                 rating: comp[0].rating,
    //                 quantity: comp[0].quantity,
    //                 rs: comp[0].rs,
    //                 soc1: comp[0].soc1,
    //                 soc2: comp[0].soc2,
    //                 siteName: comp[0].siteName,
    //                 bankName: comp[0].bankName,
    //
    //                 companyOrder: comp.map(order => ({
    //                     city: order.city,
    //                     id: order.idBitrix,
    //                     createData: order.createData,
    //                     updateData: order.updateData,
    //                     moveData: order.moveData,
    //                     price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
    //                     amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло
    //
    //                 })),
    //                 sumAll: sumPayment,
    //             });
    //         });
    //         console.log('!!!!!!!!!!!res1', result)
    //
    //         result.sort((a, b) => {
    //             const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
    //             const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));
    //
    //             if (completenessB !== completenessA) {
    //                 return completenessB - completenessA;
    //             } else {
    //                 return b.sumAll - a.sumAll;
    //             }
    //         });
    //
    //         console.log('отсортированное по', result)
    //         console.log('!!!ПЕРЕДАЛИ с ФРОНТА!!!', cardOrCity)
    //         setCompany(result)
    //     }
    //
    //     fetchData().then();
    // }, [renderClick, cardOrCity, setPreloader]);

    const info = {
        contractor: company,
        role: role,
        userCreate: users,
    }

    const [empData, setEmpData] = useState(info.userCreate)

    const updateData = (updatedArray) => {
        setEmpData(updatedArray);
        //console.log('updatedArray', empData)
    };

    const fromCardToPayments = (bool) => {
        setBackOnPay(bool)
    }

    const openCard = (inn) => {
        setOpen(true)
        setInn(inn)
    }

    const openFilter = () => {
        //console.log('openFilter', !showFilter)
        setShowFilter(!showFilter)
    }

    console.log('info', info);

    const setRender = () => {
        //console.log('дернулся рендер')
        if (!renderClick) {
            setRenderClick(true)
        } else {
            setRenderClick(false)
        }
    }

    const resCard = (resultCard) => {
        setSentCard(resultCard)
    }

    const searchedValue = (value) => {
        setCardOrCity(value)
    }

    switch (roleNow.role) {
        case 'admin':
            // console.log('roleNow admin', roleNow.role)
            // console.log('roleNowInfo', info)

            return (
                <BrowserRouter>
                    <div className="wrapper"
                         style={preloader ? {overflow: "hidden"} : {overflow: "scroll"}}
                    >
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <TheHeader openFilter={openFilter} info={info} empData={empData} update={updateData}/>
                        <div className="content">
                            <Routes>
                                <Route path="/" element={<TheBody info={info} setBack={fromCardToPayments}
                                                                  openCardData={openCard} openFilter={openFilter}
                                                                  showFilter={showFilter} sentCard={sentCard}
                                                                  searchedValue={searchedValue}
                                />}/>
                                {/*<Route path="/card/:INN"*/}
                                {/*       element={<TheCard info={info} setBack={fromCardToPayments}*/}
                                {/*                         open={open} inn={inn} setRender={setRender} role={role}*/}
                                {/*                         setResultCard={resCard}*/}
                                {/*       />*/}
                                {/*       }/>*/}
                                <Route path="/card/:id/:INN"
                                       element={<TheCardViewer info={info} setBack={fromCardToPayments}
                                                         open={open} inn={inn} setRender={setRender} role={role}
                                                         setResultCard={resCard}
                                       />
                                       }/>
                                {/*<Route path="/upload/update" element={<TheUpdate/>}/>*/}
                                {/*{*/}
                                {/*    createNumber*/}
                                {/*        ?*/}
                                {/*        <Route path="/create/:data" element={<TheCreate info={info}  setRender={setRender}/>}/>*/}
                                {/*        :*/}
                                {/*        <Route path="/create" element={<TheCreate info={info} setRender={setRender}/>}/>*/}
                                {/*}*/}
                                <Route path="/create/:data/:id" element={<TheCreate info={info}  setRender={setRender}/>}/>
                                <Route path="/payments/:INN"
                                       element={<ThePayments info={info} show={backOnPay}/>}/>
                                <Route path="/pay/:INN" element={<ThePayCard info={info}/>}/>
                                <Route path="/orders/:INN"
                                       element={<TheOrders info={info} show={backOnPay}/>}/>
                                <Route path="/order/:INN/:orderId" element={<TheOrderCard info={info}/>}/>
                                <Route path="/createOrder/:INN" element={<TheOrderCreate info={info}/>}/>
                                <Route path="/employees" element={<TheEmployees info={info}/>}/>
                                <Route path="/deletedContractors" element={<TheDeletedContractors info={info}/>}/>
                                <Route path="/notifications" element={<TheNotifications info={info}/>}/>
                                <Route path="/statistics" element={<TheStatistics info={info}/>}/>
                                <Route path="/deals" element={<TheDeals info={info} show={backOnPay}/>}/>
                                {/*<Route path="/dealsStatistics" element={<TheDealsStatistics info={info} show={backOnPay}/>}/>*/}
                                <Route path="/dealsStatistics" element={<TheDealsStatisticsBootstrap info={info} show={backOnPay}/>}/>
                                <Route path="/cityStatistics" element={<TheCityStatistics info={info} show={backOnPay}/>}/>
                                <Route path="/cityStatisticsTest" element={<TheCityStatisticsTest info={info} show={backOnPay}/>}/>
                                <Route path="/bot" element={<TheBotOne info={info}/>}/>
                                <Route path="/orderShop" element={<TheOrderShop info={info}/>}/>
                                <Route path="/appreg" element={<TheAppReg info={info}/>}/>
                                <Route path="/messageAnalysis" element={<TheMessageAnalysis info={info}/>}/>
                            </Routes>
                        </div>
                        <TheFooter/>
                    </div>
                </BrowserRouter>
            );
        case 'buh':
            console.log('roleNow buh', roleNow.role)
            console.log('roleNowInfo', info)
            return (
                <BrowserRouter>
                    <div className="wrapper"
                         style={preloader ? {overflow: "hidden"} : {overflow: "scroll"}}
                    >
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <TheHeader openFilter={openFilter} info={info}/>
                        <div className="content">
                            <Routes>
                                <Route path="/" element={<TheBody info={info} setBack={fromCardToPayments}
                                                                  openCardData={openCard} openFilter={openFilter}
                                                                  showFilter={showFilter}/>}/>
                                <Route path="/card/:id/:INN"
                                       element={<TheCardViewer info={info} setBack={fromCardToPayments}
                                                               open={open} inn={inn} setRender={setRender} role={role}
                                                               setResultCard={resCard}
                                       />
                                       }/>
                                {/*<Route path="/upload/update" element={<TheUpdate/>}/>*/}
                                <Route path="/create" element={<TheCreate info={info} setRender={setRender}/>}/>
                                <Route path="/payments/:INN"
                                       element={<ThePayments info={info} show={backOnPay}/>}/>
                                <Route path="/pay/:INN" element={<ThePayCard info={info}/>}/>
                                <Route path="/orders/:INN"
                                       element={<TheOrders info={info} show={backOnPay}/>}/>
                                <Route path="/order/:INN" element={<TheOrderCard info={info}/>}/>
                                <Route path="/createOrder/:INN" element={<TheOrderCreate info={info}/>}/>
                                <Route path="/employees" element={<TheEmployees info={info}/>}/>
                                <Route path="/statistics" element={<TheStatistics info={info}/>}/>
                                <Route path="/cityStatisticsTest" element={<TheCityStatisticsTest info={info} show={backOnPay}/>}/>
                                {/*<Route path="/dealsStatistics" element={<TheDealsStatistics info={info} show={backOnPay}/>}/>*/}
                                {/*<Route path="/cityStatistics" element={<TheCityStatistics info={info} show={backOnPay}/>}/>*/}
                                <Route path="/dealsStatistics" element={<TheDealsStatisticsBootstrap info={info} show={backOnPay}/>}/>
                            </Routes>
                        </div>
                        <TheFooter/>
                    </div>
                </BrowserRouter>
            );
        case 'manager':
            console.log('roleNow manager', roleNow.role)
            console.log('roleNowInfo', info)
            return (
                <BrowserRouter>
                    <div className="wrapper"
                         style={preloader ? {overflow: "hidden"} : {overflow: "scroll"}}
                    >
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <TheHeader openFilter={openFilter} info={info}/>
                        <div className="content">
                            <Routes>
                                <Route path="/" element={<TheBody info={info} setBack={fromCardToPayments}
                                                                  openCardData={openCard} openFilter={openFilter}
                                                                  showFilter={showFilter}/>}/>
                                <Route path="/card/:id/:INN"
                                       element={<TheCardViewer info={info} setBack={fromCardToPayments}
                                                               open={open} inn={inn} setRender={setRender} role={role}
                                                               setResultCard={resCard}
                                       />
                                       }/>
                                {/*<Route path="/upload/update" element={<TheUpdate/>}/>*/}
                                <Route path="/create" element={<TheCreate info={info} setRender={setRender}/>}/>
                                <Route path="/payments/:INN"
                                       element={<ThePayments info={info} show={backOnPay}/>}/>
                                <Route path="/pay/:INN" element={<ThePayCard info={info}/>}/>
                                <Route path="/orders/:INN"
                                       element={<TheOrders info={info} show={backOnPay}/>}/>
                                <Route path="/order/:INN" element={<TheOrderCard info={info}/>}/>
                                <Route path="/createOrder/:INN" element={<TheOrderCreate info={info}/>}/>
                                <Route path="/statistics" element={<TheStatistics info={info}/>}/>
                                <Route path="/cityStatisticsTest" element={<TheCityStatisticsTest info={info} show={backOnPay}/>}/>
                                <Route path="/employees" element={<TheEmployees info={info}/>}/>
                            </Routes>
                        </div>
                        <TheFooter/>
                    </div>
                </BrowserRouter>
            );
        case 'shop':
            console.log('roleNowShop', roleNow.role)
            console.log('roleNowInfo', info)
            return (
                <BrowserRouter>
                    <div className="wrapper"
                         style={preloader ? {overflow: "hidden"} : {overflow: "scroll"}}
                    >
                        <div
                            className="preloader"
                            style={preloader ? {display: "block"} : {display: "none"}}
                        >
                            <img src={preloadImg} className="logo_img" width="125px"
                                 height="125px"/>
                        </div>
                        <TheHeader openFilter={openFilter} info={info}/>
                        <div className="content">
                            <Routes>
                                <Route path="/" element={<TheBody info={info} setBack={fromCardToPayments}
                                                                  openCardData={openCard} openFilter={openFilter}
                                                                  showFilter={showFilter}/>}/>
                                <Route path="/card/:city/:INN"
                                       element={<TheCard info={info} setBack={fromCardToPayments}
                                                         open={open} inn={inn} setRender={setRender} role={role}
                                       />
                                       }/>
                                {/*<Route path="/upload/update" element={<TheUpdate/>}/>*/}
                                <Route path="/create" element={<TheCreate info={info} setRender={setRender}/>}/>
                                <Route path="/payments/:INN"
                                       element={<ThePayments info={info} show={backOnPay}/>}/>
                                <Route path="/pay/:INN" element={<ThePayCard info={info}/>}/>
                                <Route path="/orders/:INN"
                                       element={<TheOrders info={info} show={backOnPay}/>}/>
                                <Route path="/order/:INN" element={<TheOrderCard info={info}/>}/>
                                <Route path="/createOrder/:INN" element={<TheOrderCreate info={info}/>}/>
                            </Routes>
                        </div>
                        <TheFooter/>
                    </div>
                </BrowserRouter>
            );
    }
}

export default App;
