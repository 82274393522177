import React, {useEffect, useRef, useState, createRef} from 'react';
import ReactDOM from 'react-dom';
import {createCompanyBD, getOne, newUpdateBDCompanies, enterMainPhoneBD} from "../http/catalogAPI";
import {Link, useLocation} from "react-router-dom";
import InputMask from 'react-input-mask';

import shareBaloon from "../img/baloon.svg";
import shareCake from '../img/cake.svg';
import shareFlowers from "../img/flowers.svg";
import shareStrawberry from '../img/strawberry.svg';
import {newCreateBDCompanies} from "../http/catalogAPI";
import copyImg from "../img/copy.svg";
import shareImg from "../img/share2.svg";
import leftImg from "../img/left_arrow.svg";

import phoneImg from "../img/phone.svg";
import waImg from "../img/whatsapp.svg";
import tgImg from "../img/telegram.svg";
import vbImg from "../img/viber.svg";
import rightImg from "../img/right-arrow.svg";

const TheCardViewer = ({info, setRender, role}) => {
    const [card, setCard] = useState([]);

    const [cities, setCities] = useState([]);
    const [dis, setDis] = useState(true);
    const [id, setId] = useState(0);
    const [idCity, setIdCity] = useState('')
    const [name, setName] = useState("");
    const [INN, setINN] = useState("");
    const [OGRN, setOGRN] = useState("");
    const [rs, setRS] = useState("");
    const [bank, setBank] = useState("");
    const [BIK, setBIK] = useState("");
    const [contract, setContract] = useState("");
    const [city, setCity] = useState([]);
    const [cityMain, setCityMain] = useState("");
    const [region, setRegion] = useState("");
    const [leaderPhone, setLeaderPhone] = useState("");
    const [workPhone1, setWorkPhone1] = useState("");
    const [workPhone2, setWorkPhone2] = useState("");
    const [messenger1, setMessenger1] = useState("");
    const [messenger2, setMessenger2] = useState("");
    const [site, setSite] = useState('');
    const [QRrs, setQRrs] = useState("");
    const [QRstatic, setQRstatic] = useState("");
    const [soc1, setSoc1] = useState("");
    const [soc2, setSoc2] = useState("");
    const [saveBtn, showSaveBtn] = useState(false);
    const [innState, setInnState] = useState('');
    const [addressCompany, setAddressCompany] = useState('')
    const [updateState,setUpdateState] = useState([])
    const [derg, setDerg] = useState(false)

    const location = useLocation();

    const [indexNumber, setIndexNumber] = useState('')
    const [idCityPhone, setIdCityPhone] = useState('')
    const [formDataId, setFormDataId] = useState('')

    const [cityName, setCityName] = useState('')

    const [clickNewNumber, setClickNewNumber] = useState(false)

    const [checked, setChecked] = useState(
        {
            flowers: false,
            strawberry: false,
            balloon: false,
            cakes: false,
        }
    );

    const [inputChange, setInputChange] = useState(true)
    const [bg, setBg] = useState(true)

    const [disAddCity, setDisAddCity] = useState(false)
    const [disAddPhone, setDisAddPhone] = useState(false)

    const [additionalCity, setAdditionalCity] = useState([])
    const [additionalPhone, setAdditionalPhone] = useState([])
    const [openSearchResult, setOpenSearchResult] = useState(false)
    const [openSearchResultAddress, setOpenSearchResultAddress] = useState(false)
    const [openSearchResultLettersAddress, setOpenSearchResultLettersAddress] = useState(false)
    const [openSearchResultBanks, setOpenSearchResultBanks] = useState(false)
    const [colorPhone, setColorPhone] = useState(additionalPhone.map(() => false));

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        INN: '',
        OGRN: '',
        rs: '',
        region: '',
        city: '',
        citybd: '',
        leaderPhone: '',
        messenger1: '',
        messenger2: '',
        contract: '',
        BIK: '',
        QRrs: '',
        QRstatic: '',
        workPhone1: '',
        workPhone2: '',
        soc1: '',
        soc2: '',
        amount: '',
        storePayment: '',
        siteName: '',
        bankName: '',
        lettersAddress: '',
        cityReg: '',
        districtReg: '',
        postcode: '',
    });

    // const regAddress = `${},`

    const [searchedResult, setSearchedResult] = useState(false)

    const [modalResults, setModalResults] = useState([]);
    const [modalResultsLettersAddress, setModalResultsLettersAddress] = useState([]);
    const [modalResultsINN, setModalResultsINN] = useState([]);
    const [modalResultsBanks, setModalResultsBanks] = useState([]);

    const [checks, setChecks] = useState('')

    useEffect(() => {
        // console.log('ИЗМЕНЕНИЯ после СОХРАНЕНИЯ', info.contractor)
        let parts = location.pathname.split('/');
        let lastWord = parts[parts.length - 1];
        let lastWord2 = decodeURIComponent(parts[parts.length - 2]).replace(/[\s-(){}\[\].,]/g, "").toLowerCase();
        let preLastWord = parts[parts.length - 2]
        // const cityInEnglish = transliterate(lastWord2);
        console.log("lastWord ЧЧЧ", lastWord)
        console.log("lastWord2", lastWord2)
        console.log("preLastWord", preLastWord)
        setIdCity(preLastWord)

        // const fetch = async () => {
        //     let data = await getOne({
        //         name: lastWord
        //     });
        //
        //     let groupedOrders = {};
        //     for (let i = 0; i < data.length; i++) {
        //         const order = data[i];
        //         const key = `${order.INN}-${order.citybd}`;
        //
        //         if (transliterate(order.citybd) === cityInEnglish) {
        //             if (!groupedOrders[key]) {
        //                 groupedOrders[key] = [order];
        //             } else {
        //                 groupedOrders[key].push(order);
        //             }
        //         }
        //     }
        //
        //     console.log('!!!!groupedOrders', groupedOrders)
        //
        //     const result = [];
        //     Object.keys(groupedOrders).forEach(inn => {
        //         const comp = groupedOrders[inn];
        //         let sumPayment = 0;
        //         comp.forEach(order => {
        //             if (order.storePayment) { // Добавляем проверку на существование свойств amount и profit
        //                 const storePayment = order.storePayment.replace(/[^\d.]/g, ''); // Оставляем только цифры в amount
        //                 sumPayment += parseFloat(storePayment);
        //             }
        //         });
        //         result.push({
        //             companyName: comp[0].name,
        //             INN: comp[0].INN,
        //             comment1: comp[0].messenger1,
        //             comment2: comp[0].messenger2,
        //             OGRN: comp[0].OGRN,
        //             region: comp[0].region,
        //             city: comp[0].city,
        //             id: comp[0].id,
        //             leaderPhone: comp[0].leaderPhone,
        //             contract: comp[0].contract,
        //             BIK: comp[0].BIK,
        //             QRrs: comp[0].QRrs,
        //             QRstatic: comp[0].QRstatic,
        //             workPhone1: comp[0].workPhone1,
        //             workPhone2: comp[0].workPhone2,
        //             turnover: comp[0].turnover,
        //             rating: comp[0].rating,
        //             quantity: comp[0].quantity,
        //             rs: comp[0].rs,
        //             soc1: comp[0].soc1,
        //             soc2: comp[0].soc2,
        //             siteName: comp[0].siteName,
        //             bankName: comp[0].bankName,
        //
        //             companyOrder: comp.map(order => ({
        //                 city: order.city,
        //                 id: order.idBitrix,
        //                 createData: order.createData,
        //                 updateData: order.updateData,
        //                 moveData: order.moveData,
        //                 price: parseFloat(order.storePayment.replace(/[^\d.]/g, '')), // Оплачено магазину
        //                 amount: parseFloat(order.amount.replace(/[^\d.]/g, '')),   // всего пришло
        //
        //             })),
        //             sumAll: sumPayment,
        //         });
        //     });
        //     console.log('!!!!!!!!!!!res1', result)
        //
        //     result.sort((a, b) => {
        //         const completenessA = (a.contract !== '') * 2 + (a.QRstatic !== '') + (+(a.rs || a.QRrs ? 1 : 0));
        //         const completenessB = (b.contract !== '') * 2 + (b.QRstatic !== '') + (+(b.rs || b.QRrs ? 1 : 0));
        //
        //         if (completenessB !== completenessA) {
        //             return completenessB - completenessA;
        //         } else {
        //             return b.sumAll - a.sumAll;
        //         }
        //     });
        //
        //     console.log('result THE CARD', result)
        //
        //     // let cardOne = result;
        //
        //     let cardOne1 = result.filter(card => card.INN === lastWord);
        //     //let cardResult = cardOne1.filter(card => card.citybd === lastWord2)
        //     console.log("result", result)
        //     console.log("cardOne1", cardOne1)
        //     setCard(cardOne1)
        //     console.log('lastCard1111', cardOne1)
        //     if (cardOne1.length > 0) {
        //         console.log('lastCard2222', cardOne1)
        //         setName(cardOne1[0].companyName || '');
        //         setINN(cardOne1[0].INN || '');
        //         setOGRN(cardOne1[0].OGRN || '');
        //         setBIK(cardOne1[0].BIK || '');
        //         setContract(cardOne1[0].contract || '');
        //         setCity(cardOne1[0].city || '');
        //         setRegion(cardOne1[0].region || '');
        //         setLeaderPhone(cardOne1[0].leaderPhone || '');
        //         setWorkPhone1(cardOne1[0].workPhone1 || '');
        //         setWorkPhone2(cardOne1[0].workPhone2 || '');
        //         setMessenger1(cardOne1[0].comment1 || '');
        //         setMessenger2(cardOne1[0].comment2 || '');
        //         setSoc1(cardOne1[0].soc1 || '');
        //         setSoc2(cardOne1[0].soc2 || '');
        //         setQRrs(cardOne1[0].QRrs || '');
        //         setQRstatic(cardOne1[0].QRstatic || '');
        //         setBank(cardOne1[0].bankName || '');
        //         setRS(cardOne1[0].rs || '');
        //         setSite(cardOne1[0].siteName || '');
        //
        //         if (cardOne1.length > 0 && cardOne1[0].siteName) {
        //             try {
        //                 const jsonCheck = JSON.parse(cardOne1[0].siteName);
        //                 console.log('Card0', jsonCheck)
        //                 // const companyNameStartsWithFl = cardOne1[0].companyName.toLowerCase().startsWith('фл');
        //
        //                 // if (companyNameStartsWithFl) {
        //                 //     setChecked(prevData => ({
        //                 //             ...prevData,
        //                 //             flowers: false,
        //                 //             strawberry: true,
        //                 //             baloon: jsonCheck.baloon,
        //                 //             cakes: jsonCheck.cakes,
        //                 //         })
        //                 //     )
        //                 //
        //                 //     return
        //                 // }
        //
        //                 setChecked(prevData => ({
        //                         ...prevData,
        //                         flowers: jsonCheck.flowers,
        //                         strawberry: jsonCheck.strawberry,
        //                         balloon: jsonCheck.balloon,
        //                         cakes: jsonCheck.cakes,
        //                     })
        //                 )
        //
        //             } catch (error) {
        //                 console.error("Error parsing JSON:", error);
        //             }
        //         }
        //
        //     }
        //     console.log('lastCard', cardOne1)
        // }
        //
        // fetch()

        // useEffect(() => {
        //     if (!searchValue) {
        //         setSearchButton(false)
        //         setSearchedResult(false)
        //     }
        // }, [searchValue]);

        const fetch = async () => {
            let data = await getOne({
                name: lastWord
            });

            setCard(data)

            setFormData(prevState => ({
                ...prevState,
                id: data[0].id || '',
                name: data[0].nameCompanyTitle || '',
                INN: data[0].innCompany || '',
                OGRN: data[0].ogRn || '',
                rs: data[0].rs || '',
                leaderPhone: data[0].phoneCompanyDirector || '',
                messenger1: data[0].commentariesMain || '',
                messenger2: data[0].commentariesFirst || '',
                contract: data[0].agreement || '',
                BIK: data[0].bik || '',
                QRrs: data[0].qrRs || '',
                QRstatic: data[0].qrStatic || '',
                soc1: data[0].siteCompanyMain || '',
                soc2: data[0].siteCompanyDop || '',
                // siteName: data[0].typeOfActivity || '',
                bankName: data[0].bankName || '',
                lettersAddress: '',
                cityReg: data[0].addressCompany || '',
            }));
            console.log('data THE CARD', data)
            setUpdateState(data)

            setAdditionalCity(data[0].cities || '')
            setAdditionalPhone(data[0].phones || '')
            setAddressCompany(data[0].addressCompany || '')
            setChecks(data[0].typeOfActivity || '')

            if (additionalPhone) {
                let resultIndex = additionalPhone.findIndex(city => city.dopMain === idCity);
                console.log('additionalPhone useEffect', additionalPhone)
                console.log('idCity USE EFFECT', idCity, resultIndex)

                setColorPhone(additionalPhone.map((city, index) => index === resultIndex));

                console.log('DATA STATE', additionalPhone.map((city, index) => index === resultIndex))
            }

            // if (additionalCity) {
            //     let cityNameForCard = additionalCity.filter(city => city.id === Number(idCity))
            //
            //     setCityName(cityNameForCard)
            // }

            // console.log('ягодки', JSON.parse(checks))
        }

        fetch()

    }, [location, info, setId, setName, setINN, setCard, setChecked, checks]);

    // useEffect(() => {
    //     if (additionalPhone) {
    //         let resultIndex = additionalPhone.findIndex(city => city.dopMain === idCity);
    //         console.log('additionalPhone useEffect', additionalPhone)
    //         console.log('idCity USE EFFECT', idCity, resultIndex)
    //
    //         setColorPhone(additionalPhone.map((city, index) => index === resultIndex));
    //
    //         console.log('DATA STATE', additionalPhone.map((city, index) => index === resultIndex))
    //     }
    // }, [derg]);

    useEffect(() => {
        if (checks) {
            try {
                const formDataParsed = JSON.parse(checks);
                setChecked(prevState => {
                    return {
                        ...prevState,
                        flowers: formDataParsed.flowers || prevState.flowers,
                        strawberry: formDataParsed.strawberry || prevState.strawberry,
                        balloon: formDataParsed.balloon || prevState.balloon,
                        cakes: formDataParsed.cakes || prevState.cakes,
                    };
                });
            } catch (error) {
                console.error('Invalid JSON format:', error);
            }
        }
    }, [checks]);

    useEffect(() => {
        // setFormData(prevData => ({
        //     ...prevData,
        //     siteName: checked,
        // }));
        console.log("checks UPDATED", checks)
    }, [checks]);

    const sentBDUpdate = () => {
        console.log("НАЖАЛ СОХРАНИТЬ", formData)
        if (formData.INN && formData.INN !== '' && formData.INN !== ' ' && formData.INN !== '  ' &&
            (additionalCity && additionalCity.length !== 0 && additionalCity[0].address_custom !== '') &&
            (additionalPhone && additionalPhone.length !== 0 && additionalPhone[0].workPhone !== '')) {
            const update = {
                state: updateState || [],
                cities: additionalCity || [],
                workPhone: additionalPhone || [],
                innCompany: formData.INN || '',
                bankName: formData.bankName || '',
                rs: formData.rs || '',
                bik: formData.BIK || '',
                ogRn: formData.OGRN || '',
                qrRs: formData.QRrs || '',
                qrStatic: formData.QRstatic || '',
                agreement: formData.contract || '',
                nameCompanyTitle: formData.name || '',
                phoneCompanyMain: formData.leaderPhone.replace(/[\s-()]/g, '') || '',
                commentariesMain: formData.messenger1 || '',
                commentariesFirst: formData.messenger2 || '',
                typeOfActivity: checked || '',
                live: true,
                siteCompanyMain: formData.soc1 || '',
                siteCompanyDop: formData.soc2 || '',
                addressCompany: formData.cityReg || '',
                indexNumber: indexNumber || '',
                idCityPhone: idCityPhone || '',
                formDataId: formDataId || '',
            }
            console.log("update sentBDUpdate", update)
            newUpdateBDCompanies(update)
                .then(data => {
                console.log('data mess', data)
                alert(data)})
                .catch((error) => {
                    console.error('Ошибка при редактировани компании:', error);
                    alert('Произошла ошибка при редактировани компании');
                });
        } else {
            alert('Заполните обязательные поля ИНН, город и рабочий телефон')
        }
    }

    const handleCheckboxChange = (key) => {
        setChecked(prevChecked => {
            const updatedChecked = {
                ...prevChecked,
                [key]: !prevChecked[key]
            };
            delete updatedChecked.true;
            console.log("THE CREATE", updatedChecked);
            return updatedChecked;
        });
    };

    useEffect(() => {
        console.log('on create', info.contractor)
    }, [info]);

    // useEffect(() => {
    //     console.log('companyInfo', modalResultsINN)
    // }, [modalResultsINN]);

    const handleInputChange = (e, field) => {
        const {name, value} = e.target;
        if (field === "inn") {
            if (value.length === 0) {
                setOpenSearchResult(false)
            } else {
                setOpenSearchResult(true)
            }
        }
        if (field === "bik") {
            if (value.length === 0) {
                setOpenSearchResultBanks(false)
            } else {
                setOpenSearchResultBanks(true)
            }
        }

        // if (name === "city") {
        //     setFormData(prevData => ({
        //         ...prevData,
        //         [name]: value,
        //     }));
        // } else {
        //     setFormData(prevData => ({...prevData, [name]: value}));
        // }

        setFormData(prevData => ({...prevData, [name]: value}));

        console.log('formDataСОЗДАНИЕ', formData)
    };


    const normalizePhoneNumber = (phoneNumber) => {
        if (phoneNumber !== '') {
            const digits = phoneNumber.replace(/\D/g, ''); // Удаление всех нечисловых символов
            if (digits.length > 10) {
                return "+7" + digits.slice(-10); // Если больше 10 цифр, обрезаем до 10 и добавляем +7 в начале
            } else {
                return "+7" + digits; // Если меньше или равно 10 цифр, добавляем +7 в начале
            }
        }
    }

    const normalizeINN = (inn) => {
        if (inn !== '') {
            return inn.replace(/\D/g, ''); // Remove non-numeric characters
        }
    }

    // const createCompany = (e) => {
    //
    //     let inns = info.contractor.filter(inn => inn.INN === formData.INN)
    //     console.log('inns', inns)
    //     if (inns.length > 0) {
    //         alert('Введенный ИНН уже существует!');
    //         e.preventDefault();
    //         return;
    //     }
    //
    //     let nums = info.contractor.filter(num => num.leaderPhone === formData.leaderPhone)
    //     console.log('nums', nums)
    //     if (nums.length > 0) {
    //         alert('Введенный телефон руководителя уже существует!');
    //         e.preventDefault();
    //         return;
    //     }
    //
    //     if (!formData.INN || !formData.leaderPhone) {
    //         alert('Пожалуйста введите ИНН и телефон руководителя');
    //         e.preventDefault();
    //         return; // Прерываем дальнейшее выполнение функции
    //     } else {
    //         formData.leaderPhone = normalizePhoneNumber(formData.leaderPhone);
    //         formData.workPhone1 = normalizePhoneNumber(formData.workPhone1); // Применяем normalizePhoneNumber к workPhone1 перед отправкой
    //         // formData.workPhone2 = normalizePhoneNumber(formData.workPhone2); // Применяем normalizePhoneNumber к workPhone2 перед отправкой
    //
    //         // createCompanyBD(formData).then(async data => {
    //         //     alert(data);
    //         //     setFormData({
    //         //         name: '',
    //         //         INN: '',
    //         //         OGRN: '',
    //         //         rs: '',
    //         //         region: '',
    //         //         city: null,
    //         //         citybd: '',
    //         //         leaderPhone: '',
    //         //         contract: '',
    //         //         BIK: '',
    //         //         QRrs: '',
    //         //         QRstatic: '',
    //         //         workPhone1: '',
    //         //         workPhone2: '',
    //         //         siteName: '',
    //         //         bankName: '',
    //         //         messenger1: '',
    //         //         messenger2: '',
    //         //         soc1: '',
    //         //         soc2: '',
    //         //         amount: '0',
    //         //         storePayment: '0',
    //         //     })
    //         //     console.log('DATA create', data)
    //         // })
    //         //     .catch(error => {
    //         //         alert(error.message);
    //         //     });
    //
    //         console.log('formDATA create', formData)
    //
    //         setRender();
    //     }
    //
    // }

    // const tokenSent = (e, aim) => {
    //     if (e.target.value.length > 3) {
    //         let url = "http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
    //         let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
    //         let query = e.target.value;
    //
    //         let options = {
    //             method: "POST",
    //             mode: "cors",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Accept": "application/json",
    //                 "Authorization": "Token " + token
    //             },
    //             body: JSON.stringify({query: query})
    //         }
    //
    //         fetch(url, options)
    //             .then(response => response.json())
    //             .then(result => {
    //
    //                 // const suggestions = result.suggestions.map((suggestion, index) => ({
    //                 //     // index: suggestion.data.postal_code || "",
    //                 //     // city: suggestion.data.city || "",
    //                 //     // country: suggestion.data.country || "",
    //                 //     // region: suggestion.data.region_with_type || "",
    //                 //     // // district: suggestion.data.city_district || ""
    //                 // }));
    //
    //                 if (aim === 'address_letters') {
    //                     console.log("result", result.suggestions)
    //                     setModalResultsLettersAddress(result.suggestions);
    //                     setOpenSearchResultLettersAddress(true)
    //                 } else {
    //                     console.log("result", result.suggestions)
    //                     setModalResults(result.suggestions);
    //                     setOpenSearchResultAddress(true)
    //                 }
    //             })
    //             .catch(error => console.log("error", error));
    //     }
    // }

    const tokenSentCity = (e, aim) => {
        if (e.target.value.length > 1) {

            if (e.target.value.toLowerCase() === "петровск") {
                console.log('NOT e.target.value',e.target.value)
                const suggestions = {
                    postcode: "412540",
                    nameCityTitle: "Петровск",
                    countryTitle: "Россия",
                    regionTitle: "Саратовская обл",
                    area_with_typeTitle: "Петровский р-н",
                    geo_lat: "52.309431",
                    geo_lon: "45.385179",
                    settlement: ""
                };
                setModalResults([suggestions]);

            } else {
                let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
                let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
                let query = e.target.value;

                let options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }

                fetch(url, options)
                    .then(response => response.json())
                    .then(result => {
                        console.log("result ЧИСТЫЙ", result)

                        const filteredSuggestions = result.suggestions.filter(suggestion =>
                            (suggestion.data.city && suggestion.data.city.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement && suggestion.data.settlement.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.region_with_type && suggestion.data.region_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement_with_type && suggestion.data.settlement_with_type.toLowerCase().includes(query?.toLowerCase())) ||
                            (suggestion.data.settlement_with_typeTitle && suggestion.data.settlement_with_typeTitle.toLowerCase().includes(query?.toLowerCase()))
                        );



                        console.log("filteredSuggestions THE CREATE", filteredSuggestions)

                        const suggestions = filteredSuggestions.map((suggestion, index) => ({
                            postcode: suggestion.data.postal_code || "",
                            nameCityTitle: suggestion.data.city || "",
                            countryTitle: suggestion.data.country || "",
                            regionTitle: suggestion.data.region_with_type || "",
                            area_with_typeTitle: suggestion.data.area_with_type || suggestion.data.settlement_with_type || "",
                            geo_lat: suggestion.data.geo_lat || "",
                            geo_lon: suggestion.data.geo_lon || "",
                            settlement: suggestion.data.settlement || suggestion.data.settlement_with_typeTitle || "",
                        }));
                        console.log("filteredSuggestions THE CREATE suggestions", suggestions)
                        setModalResults(suggestions);

                    })
                    .catch(error => console.log("error", error));
            }
        }
    }

    const tokenSentForINN = (e) => {
        if (e.target.value.length > 3) {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234";
            let query = e.target.value;

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            fetch(url, options)
                .then(response => response.json())
                .then(result => {
                    let arr = result.suggestions;
                    for (let i = 0; i < arr.length; i++) {
                        let objINN = arr[i].data.state
                        console.log('objINN', objINN)
                        if (objINN.status === 'ACTIVE') {
                            console.log("result INN", arr[i])
                            setModalResultsINN([arr[i]]);
                            setOpenSearchResult(true)
                        }
                    }
                    // console.log("result INN", result.suggestions)
                    // setModalResultsINN(result.suggestions);
                    // setOpenSearchResult(true)
                })
                .catch(error => console.log("error", error));
        }
    }

    const tokenSentForBanks = (e) => {
        if (e.target.value.length > 3) {
            let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank"; // Изменяем URL для получения данных по банкам
            let token = "80a7758e1c4d6412649c75b3f4e070057bef8234"; // Ваш токен авторизации
            let query = e.target.value;

            let options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: query})
            }

            fetch(url, options)
                .then(response => response.json())
                .then(result => {
                    console.log("result BANKS", result.suggestions)
                    setModalResultsBanks(result.suggestions);
                    setOpenSearchResultBanks(true)
                })
                .catch(error => console.log("error", error));
        }
    }

    const arrangementCompanyData = () => {
        let data = modalResultsINN[0]
        console.log('modalResultsINN[0]', data)

        setFormData(prevData => ({
            ...prevData,
            name: data.value,
            OGRN: data.data.ogrn,
            cityReg: data.data.address ? data.data.address.unrestricted_value : 'Нет данных',
            region: data.data.address ? data.data.address.data.region_with_type : 'Нет данных',
            districtReg: data.data.address ? data.data.address.data.area_with_type : 'Нет данных',
            postcode: data.data.address ? data.data.address.data.postal_code : 'Нет данных',
        }));
    }

    const arrangementBankBIK = () => {
        let data = modalResultsBanks[0]

        setFormData(prevData => ({
            ...prevData,
            BIK: data.data.bic,
            bankName: data.value,
        }));
    }

    const arrangementLettersAddress = () => {
        let data = modalResultsLettersAddress[0]

        setFormData(prevData => ({
            ...prevData,
            lettersAddress: data.unrestricted_value,
        }));
    }

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,2})(\d{0,2})$/);

        if (match) {
            return `+7(${match[1]}${match[2] ? `)${match[2]}` : ''}${match[3] ? `-${match[3]}` : ''}${match[4] ? `-${match[4]}` : ''}`;
        }

        return phoneNumber;
    };

    const [countPhone, setCountPhone] = useState(2)


    const handleAddCity = () => {
        setAdditionalCity([...additionalCity, {address_custom: ''}]);
    };

    const handleAddPhone = () => {
        setAdditionalPhone([...additionalPhone, {workPhone: ''}]);
    };

    const handleCityChange = (index, e) => {
        const {value} = e.target;

        if (value.length === 0) {
            console.log('handleCityChange value', value.length)
            setSearchedResult(false)
        } else {
            setSearchedResult(true);
        }

        const updatedCities = [...additionalCity]; // Создаем копию массива additionalCity
        updatedCities[index] = {...updatedCities[index], address_custom: value, disabled: false}; // Обновляем объект города по индексу
        setAdditionalCity(updatedCities); // Устанавливаем обновленный массив в состояние
    };


    const showChange = (event) => {
        event.stopPropagation()
        // Remove the "active" class from all NavLink elements
        const navLinks = document.querySelectorAll('.search_results .search_results__wrapper');
        navLinks.forEach(link => link.classList.remove('active'));

        // Add the "active" class to the clicked NavLink
        event.target.classList.add('active');
    }


    useEffect(() => {
        console.log('formData ADDED CITY', formData)
        console.log('formData ADDED DOP CITY', additionalCity)
        console.log('formData ADDED DOP PHONE', additionalPhone)
        console.log('formData indexNumber', indexNumber)
    }, [formData, additionalCity, additionalPhone, indexNumber]);

    const arrangementAddress = (address, result) => {
        setAdditionalCity(prevState => {
            const createCity = {
                ...prevState,
                postcode: result.postcode || "",
                nameCityTitle: result.nameCityTitle || "",
                countryTitle: result.countryTitle || "",
                regionTitle: result.regionTitle || "",
                area_with_typeTitle: result.area_with_typeTitle || "",
                settlement_with_typeTitle: result.settlement_with_typeTitle || result.settlement || "",
                geo_lat: result.geo_lat || "",
                geo_lon: result.geo_lon || "",
                settlement: result.settlement || "",
                address_custom: `${result.settlement_with_typeTitle || result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`
            };
            return [...prevState.slice(0, prevState.length - 1), createCity];
        });
        console.log('result suggestions', result);
        console.log('address', address);
    }

    const handleDeleteCity = (index) => {
        setAdditionalCity(prev => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
        setDisAddCity(false);
    };

    const handleDeletePhone = (index) => {
        setAdditionalPhone(prev => {
            const newData = [...prev];
            newData.splice(index, 1);
            return newData;
        });
        setDisAddPhone(false);
    };

    const [openInput, setOpenInput] = useState([])

    const inputRefs = {
        textToCopyINNRef: useRef(null),
        textToCopyRSRef: useRef(null),
        textToCopyBIKRef: useRef(null),
        textToCopyOGRNRef: useRef(null),
        textToCopyLeaderPhoneRef: useRef(null),
    };

    const inputWorkPhonesRefs = useRef([]);

    // Используем useEffect для заполнения массива рефов при изменении additionalPhone
    useEffect(() => {
        // Проверяем, что additionalPhone не пустой
        if (additionalPhone.length > 0) {
            // Заполняем массив рефов
            inputWorkPhonesRefs.current = additionalPhone.map(() => createRef());

            console.log("11111 !!!!inputWorkPhonesRefs", inputWorkPhonesRefs)
        }
    }, [additionalPhone]);

    useEffect(() => {
        console.log('!!!inputWorkPhonesRefs', inputWorkPhonesRefs)
        console.log('!!!additionalPhone', additionalPhone)
    }, [additionalPhone]);

    const copyToClipboardOnClick = (inputName) => {
        if (inputRefs[inputName].current) {
            const valueText = inputRefs[inputName].current.value;

            // Создаем временный элемент textarea
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = valueText;
            document.body.appendChild(tempTextArea);

            // Выделяем текст внутри textarea
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

            // Копируем текст в буфер обмена
            document.execCommand('copy');
            alert('Текст скопирован в буфер обмена');

            // Удаляем временный элемент
            document.body.removeChild(tempTextArea);
        }
    };

    const copyToClipboardOnClickWorkPhones = (index) => {
        const inputName = `workPhone${index + 1}`;
        const inputValue = document.querySelector(`input[name='${inputName}']`).value;
        console.log('inputValue', inputValue)

        if (inputValue) {
            const valueText = inputValue;
            console.log('valueText', valueText);

            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = valueText;
            document.body.appendChild(tempTextArea);

            // Выделяем текст внутри textarea
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

            // Копируем текст в буфер обмена
            document.execCommand('copy');
            alert('Текст скопирован в буфер обмена');

            // Удаляем временный элемент
            document.body.removeChild(tempTextArea);
        }
    };

    // const copyToClipboardOnClickWorkPhones = (inputName) => {
    //     console.log('inputName', inputName)
    //     if (inputName) {
    //         const valueText = inputName.value;
    //         console.log('valueText', valueText);
    //         Создаем временный элемент textarea
    //         const tempTextArea = document.createElement('textarea');
    //         tempTextArea.value = valueText;
    //         document.body.appendChild(tempTextArea);
    //
    //         // Выделяем текст внутри textarea
    //         tempTextArea.select();
    //         tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах
    //
    //         // Копируем текст в буфер обмена
    //         document.execCommand('copy');
    //         alert('Текст скопирован в буфер обмена');
    //
    //         // Удаляем временный элемент
    //         document.body.removeChild(tempTextArea);
    //     }
    // };

    const copyCurrentUrlToClipboard = () => {


        const valueText = window.location.toString();

        // Создаем временный элемент textarea
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = valueText;
        document.body.appendChild(tempTextArea);

        // Выделяем текст внутри textarea
        tempTextArea.select();
        tempTextArea.setSelectionRange(0, 99999); // Для поддержки выбора текста в различных браузерах

        // Копируем текст в буфер обмена
        document.execCommand('copy');
        alert('Ссылка скопирована в буфер обмена');

        // Удаляем временный элемент
        document.body.removeChild(tempTextArea);

    };

    const [showModal, setShowModal] = useState(false)

    const [showModalDeletePhone, setShowModalDeletePhone] = useState(false)
    const [showModalDeleteAddress, setShowModalDeleteAddress] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null)

    const [showFullAddress, setShowFullAddress] = useState(false)

    const deletePhone = (index) => {
        setDeleteIndex(index);
        setShowModalDeletePhone(true);
        setShowModal(true)
    }

    const deleteCity = (index) => {
        setDeleteIndex(index);
        setShowModalDeleteAddress(true);
        setShowModal(true)
    }

    useEffect(() => {
        console.log('colorPhone', colorPhone)
    }, [colorPhone]);

    const enterMainPhone = async (phoneNumber, idCity, companyId) => {
        let result = {
            phoneNumber: phoneNumber, idCity: idCity, companyId: companyId
        }
        await enterMainPhoneBD(result).then(
            data => {
                if (data.message === "win") {
                    alert("Вы выбрали главный телефон для компании в этом городе");
                    setDerg(!derg);
                }
            }
        );
    }


    switch (info.role.role) {
        case 'admin':
            return (
                <>
                    <header
                        className="header save__header"
                    >
                        <div className="header__left">
                            <div className="header__left">
                                <div>
                                    <Link to={`/`}
                                          onClick={setRender}
                                    >
                                        <img src={leftImg} className="logo_img" width="25px"
                                             height="25px"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                cityName
                            }
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                            <div
                                style={{cursor: "pointer"}}
                            >
                                {/*<Link to={`/`} onClick={createCompany}>СОХРАНИТЬ</Link>*/}
                                {/*<Link to={`/`} onClick={sentBDCreate}>СОХРАНИТЬ</Link>*/}
                                <a
                                    style={{display: bg ? "block" : "none"}}
                                    onClick={() => {
                                        setBg(!bg);
                                    }
                                    }>РЕДАКТИРОВАТЬ</a>
                                <a
                                    style={{display: !bg ? "block" : "none"}}
                                    onClick={() => {
                                        setBg(!bg);
                                        sentBDUpdate()
                                        setClickNewNumber(false)
                                    }}>СОХРАНИТЬ</a>
                            </div>
                        </div>
                    </header>
                    <div className="share icon "
                         onClick={(e) => {
                             e.stopPropagation();
                             copyCurrentUrlToClipboard();
                         }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>Поделиться</div>
                            <img src={shareImg} className="share_img share_link" width="20px"
                                 height="20px"/>
                        </div>

                        <div className="item_card__products" style={{display: "flex"}}>
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{*/}
                            {/*        display: checked.flowers ? "block" : "none"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <img src={shareFlowers} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.strawberry ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareStrawberry} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.balloon ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareBaloon} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.cakes ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareCake} className="share_img products" width="25px"*/}
                            {/*         alt="QR"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div
                        className="bg"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    ></div>
                    <div
                        className="modal modal_storno"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    >
                        <div
                            style={showModalDeletePhone ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                номер телефона?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false);
                                        setShowModalDeletePhone(false)
                                        handleDeletePhone(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeletePhone(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                        <div
                            style={showModalDeleteAddress ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                город?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                        handleDeleteCity(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="create_organizations card_viewer"
                    >
                        <div className="bg" style={{display: bg ? "block" : "none"}}></div>
                        <div className="create_products">
                            <div>
                                <label htmlFor="flowers" className="create_products__label">
                                    <input
                                        type="checkbox"
                                        id="flowers"
                                        className="create_checkbox"
                                        onChange={() => handleCheckboxChange("flowers")}
                                        checked={checked.flowers}
                                    />
                                    <img src={shareFlowers} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="strawberry" className="create_products__label">
                                    <input type="checkbox" id="strawberry" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("strawberry")} checked={checked.strawberry}/>
                                    <img src={shareStrawberry} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="balloon" className="create_products__label">
                                    <input type="checkbox" id="balloon" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("balloon")} checked={checked.balloon}/>
                                    <img src={shareBaloon} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="cake" className="create_products__label">
                                    <input type="checkbox" id="cake" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("cakes")} checked={checked.cakes}/>
                                    <img src={shareCake} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                        </div>
                        <div>Название организации:</div>
                        <div className="create_fields">
                            <input className="item_" name="name" value={inputChange ? formData.name : name}
                                   onChange={handleInputChange}
                                   placeholder="Введите название"/>
                            <div>
                                <div>ИНН:</div>
                                <div className="reques_item">
                                    <input className={formData.INN ? "item_" : "item_-empty"}
                                           name="INN"
                                           value={inputChange ? normalizeINN(formData.INN) : INN}
                                           onChange={
                                               (e) => {
                                                   tokenSentForINN(e);
                                                   handleInputChange(e, 'inn');
                                               }
                                           }
                                           placeholder="Введите ИНН"
                                           ref={inputRefs.textToCopyINNRef}
                                           required
                                    />
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResult ? "block" : "none"}}
                                >
                                    {modalResultsINN && modalResultsINN.map((result) => {
                                        console.log("INN datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        // setBool(true);
                                                        // getNamesBD(result.unrestricted_value, 56.183759, 70.463626, 56.11055, 69.479639)
                                                        // localStorage.setItem('searchValue CREATE', result.unrestricted_value)
                                                        arrangementCompanyData()
                                                        setOpenSearchResult(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                    {/*<span>{result.unrestricted_value}</span>*/}
                                                    {/*<span>{result.data.country},</span>*/}
                                                    {/*<span>{result.data.city},</span>*/}
                                                    {/*<span>{result.data.region}</span>*/}
                                                    {/*<span>{result.district}</span>*/}

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>Расчетный счет:</div>
                            <div className="reques_item">
                                <input className="item_" name="rs" value={inputChange ? formData.rs : rs}
                                       ref={inputRefs.textToCopyRSRef}
                                       onChange={handleInputChange}
                                       placeholder="Введите Р/С"/>
                                <div className="copy icon"
                                     onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                    <img src={copyImg} className="logo_img" width="20px"
                                         height="20px"/>
                                </div>
                            </div>
                            <div>ОГРН:</div>
                            <div className="reques_item">
                                <input className="item_" name="OGRN" value={inputChange ? formData.OGRN : OGRN}
                                       ref={inputRefs.textToCopyOGRNRef}
                                       onChange={handleInputChange}
                                       placeholder="Введите ОГРН"/>
                                <div className="copy icon"
                                     onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                    <img src={copyImg} className="logo_img" width="20px"
                                         height="20px"/>
                                </div>
                            </div>
                            <div>
                                <div>БИК:</div>
                                <div className="reques_item">
                                    <input className="item_" name="BIK" value={inputChange ? formData.BIK : BIK}
                                           ref={inputRefs.textToCopyBIKRef}
                                           onChange={
                                               (e) => {
                                                   tokenSentForBanks(e);
                                                   handleInputChange(e, "bik");
                                               }

                                           } placeholder="Введите БИК"/>
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResultBanks ? "block" : "none"}}
                                >
                                    {modalResultsBanks && modalResultsBanks.map((result) => {
                                        console.log("Banks datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        arrangementBankBIK()
                                                        setOpenSearchResultBanks(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>Название банка:</div>
                            <input className="item_" name="bankName" value={inputChange ? formData.bankName : bank}
                                   onChange={handleInputChange} placeholder="Введите название банка"/>
                            {/*----*/}
                            <div>QR на РС:</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <input className="item_"
                                       name="QRrs"
                                       value={inputChange ? formData.QRrs : QRrs}
                                       onChange={handleInputChange}
                                       placeholder="Введите QR на р/с"/>
                                <a
                                    href={formData.QRrs}
                                    target="_blank" className="copy icon"
                                    style={formData.QRrs ?
                                        {display: "block", zIndex: "205"} : {display: "none"}}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{marginLeft: "10px"}}/>
                                </a>
                            </div>
                            <div>QR статичный:</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <input className="item_"
                                       name="QRstatic"
                                       value={inputChange ? formData.QRstatic : QRstatic}
                                       onChange={handleInputChange}
                                       placeholder="Введите QR на статичный"/>
                                <a
                                    href={formData.QRstatic}
                                    target="_blank" className="copy icon"
                                    style={formData.QRstatic ?
                                        {display: "block", zIndex: "205"} : {display: "none"}}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{marginLeft: "10px"}}/>
                                </a>
                            </div>
                            <div>Договор:</div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <input
                                    className="item_"
                                    name="contract"
                                    value={inputChange ? formData.contract : contract}
                                    onChange={handleInputChange}
                                    placeholder="Введите договор"
                                />
                                <a
                                    href={formData.contract}
                                    target="_blank"
                                    className="copy icon"
                                    style={formData.contract ?
                                        {display: "block", zIndex: "205"} : {display: "none"}}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{marginLeft: "10px"}}/>
                                </a>
                            </div>
                            {/*----*/}
                            <div>Адрес регистрации ЮЛ:</div>
                            <input className="item_" name="cityReg"
                                   value={inputChange ? formData.cityReg : addressCompany}
                                   onChange={handleInputChange}
                                   placeholder="Адрес регистрации ЮЛ"/>
                            <div className="create_cities">
                                {/*<input className="item_" name="city" value={additionalCity[0]?.address_custom} onChange={*/}
                                {/*    (e) => {*/}
                                {/*        tokenSentCity(e);*/}
                                {/*        handleCityChange(0, e);*/}
                                {/*        setSearchedResult(true)*/}
                                {/*    }*/}
                                {/*}*/}
                                {/*       placeholder="Введите город"*/}
                                {/*/>*/}
                                <div style={{display: !bg ? "block" : "none"}} className="click_plus">
                                    Нажмите на <b>+</b> чтобы добавить город
                                </div>
                                <button
                                    style={{display: !bg ? "block" : "none"}}
                                    disabled={disAddCity ? true : false} className="add_city" onClick={() => {
                                    handleAddCity();
                                    setDisAddCity(true)
                                    setOpenInput(prevState =>
                                        [...prevState, false]
                                    )
                                }}>+
                                </button>
                                {/* Дополнительные города */}
                                {additionalCity && additionalCity.map((cityItem, index) => {
                                    console.log('cityItem', cityItem);
                                    console.log('additionalCity', additionalCity);
                                    const valueItem = `${cityItem.settlement || ''} ${cityItem.nameCityTitle || ''} ${cityItem.region || ''} ${cityItem.area_with_typeTitle || ''} ${cityItem.settlement_with_typeTitle || ''} ${cityItem.postcode || ''}` || ''
                                    console.log("valueItem", cityItem)
                                    return (
                                        <div key={index} className="input_item">
                                            <div>Город {index + 1} </div>
                                            <div
                                                style={{position: "relative"}}
                                            >
                                                <input
                                                    className="item_"
                                                    disabled={openInput[index]}
                                                    name={`city${index + 1}`}
                                                    value={cityItem.nameCityTitle}
                                                    onChange={(e) => {
                                                        handleCityChange(index, e);
                                                        tokenSentCity(e);
                                                    }}
                                                    placeholder={`Введите город ${index + 1}`}
                                                />
                                                <div
                                                    className="item_-fullAddress"
                                                    style={{display: (cityItem.nameCityTitle !== '' || cityItem.settlement !== '') ? 'block' : 'none'}}
                                                >{cityItem.settlement || cityItem.settlement_with_typeTitle || ''} {cityItem.nameCityTitle || ''} {cityItem.regionTitle || cityItem.region || ''} {cityItem.area_with_typeTitle || ''} {cityItem.postcode || ''}</div>
                                            </div>
                                            <button style={{display: !bg ? "block" : "none"}} className="delete_city"
                                                    onClick={() => {
                                                        deleteCity(index);
                                                    }}>x
                                            </button>
                                        </div>
                                    )
                                })}
                                <div
                                    className="search_results"
                                    style={searchedResult ? {display: "block"} : {display: "none"}}
                                >
                                    {modalResults && modalResults.map((result) => {
                                        console.log("FRONT", result)
                                        if (result.postal_code !== null) {
                                            // setSearchedResult(true)
                                            return (
                                                <div className="search_results__wrapper" key={result.id}
                                                     onClick={showChange}>
                                                    <div
                                                        className="search_results__item"
                                                        onClick={() => {
                                                            setShowFullAddress(true)
                                                            setSearchedResult(false)
                                                            const res = result.settlement + ', ' + result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                                result.area_with_typeTitle + ', ' + result.postcode + ', ' + result.settlement_with_typeTitle;
                                                            console.log("const res", res)
                                                            arrangementAddress(res, result)
                                                            setDisAddCity(false)
                                                            setOpenInput(prevState => {
                                                                return prevState.map((item, index) => (index === prevState.length - 1 ? true : item));
                                                            });
                                                        }}
                                                    >
                                                        <div>
                                                            {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null; // Или другое действие, если city пустой
                                        }
                                    })}
                                </div>
                            </div>
                            {/*<input className="item_" name="region" value={formData.region} onChange={handleInputChange}*/}
                            {/*       placeholder="Введите регион"/>*/}
                            {/*<div>*/}
                            {/*    <input className="item_" name="lettersAddress" value={formData.lettersAddress} onChange={*/}
                            {/*        (e) => {*/}
                            {/*            tokenSentCity(e, 'address_letters');*/}
                            {/*            handleInputChange(e);*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*           placeholder="Введите адрес для писем"/>*/}
                            {/*    <div*/}
                            {/*        className="search_results"*/}
                            {/*        style={{display: openSearchResultLettersAddress ? "block" : "none"}}*/}
                            {/*    >*/}
                            {/*        {modalResultsLettersAddress && modalResultsLettersAddress.map((result) => {*/}
                            {/*            console.log("FRONT", result)*/}
                            {/*            if (result.data.postal_code !== null) {*/}
                            {/*                return (*/}
                            {/*                    <div className="search_results__wrapper" key={result.id}>*/}
                            {/*                        <div*/}
                            {/*                            className="search_results__item"*/}
                            {/*                            onClick={() => {*/}
                            {/*                                setOpenSearchResultLettersAddress(false)*/}
                            {/*                                arrangementLettersAddress()*/}
                            {/*                            }}*/}
                            {/*                        >*/}
                            {/*                            {result.unrestricted_value}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                );*/}
                            {/*            } else {*/}
                            {/*                return null; // Или другое действие, если city пустой*/}
                            {/*            }*/}
                            {/*        })}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<input name="leaderPhone" value={formData.leaderPhone}*/}
                            {/*       className={formData.leaderPhone ? "item_" : "item_-empty"}*/}
                            {/*       onChange={handleInputChange} placeholder="Введите телефон руководителя" required/>*/}
                            {/*<div*/}
                            {/*    style={{position: "relative"}}*/}
                            {/*>*/}

                            <div className="create_phones">
                                <div className="create_phone">
                                    <div style={{display: !bg ? "block" : "none"}} className="click_plus">
                                        Нажмите на <b>+</b> чтобы добавить рабочий телефон
                                    </div>
                                    <button
                                        style={{display: !bg ? "block" : "none"}}
                                        disabled={disAddPhone ? true : false} className="add_phone" onClick={() => {
                                        handleAddPhone();
                                        setDisAddPhone(true)
                                        setClickNewNumber(true)
                                    }}>+
                                    </button>
                                    {additionalPhone && additionalPhone.map((phone, index) => (
                                        <div key={index} className="input_item">
                                            <div style={{ display: additionalCity.filter(city => city.id === Number(phone.dopMain)).length > 0 ? "none" : "block" }}>Рабочий телефон {index + 1}</div>
                                            {additionalCity.filter(city => city.id === Number(phone.dopMain)).map((city, idx) => {
                                                console.log('дернулся !!!', colorPhone[idx])
                                                return (
                                                    <div key={idx}>{city.nameCityTitle} {city.region}</div>
                                                )
                                            })}
                                            <div style={{position: "relative"}}>
                                                {/*{inputWorkPhonesRefs.current[index] && ( // Условная отрисовка InputMask*/}
                                                {/*    */}
                                                {/*)}*/}
                                                <InputMask
                                                    className="item_"
                                                    mask="+7(999)999-99-99"
                                                    maskChar="_"
                                                    name={`workPhone${index + 1}`}
                                                    style={{
                                                        // color: String(phone.dopMain) === String(idCity) && phone.dop === "check" || colorPhone[index] ? "red" : "black",
                                                        color: colorPhone[index] ? "red" : "black",
                                                        cursor: "pointer"
                                                    }}
                                                    title="Нажмите чтобы сделать главным"
                                                    onClick={() => {
                                                        if (!clickNewNumber) {
                                                            // if (phone.number && phone.dopMain ) {
                                                            //     alert("Номер принадлежит другому городу этой компании")
                                                            //     return
                                                            // }
                                                            const updatedColors = additionalPhone.map((_, idx) => idx === index ? true : false);
                                                            console.log('updatedColors', updatedColors)
                                                            setColorPhone(updatedColors);
                                                            // setBg(!bg);
                                                            setDerg(!derg)
                                                            setIndexNumber(additionalPhone[index].number)
                                                            setIdCityPhone(idCity)
                                                            setFormDataId(formData.id)
                                                        }

                                                    }}
                                                    value={additionalPhone[index].number}
                                                    ref={(el) => (inputWorkPhonesRefs.current[index] = el)} // Передача соответствующего рефа
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        // console.log('additionalPhone[index].number', additionalPhone[index].number)
                                                        setAdditionalPhone((prevData) => {
                                                            const updatedPhones = [...prevData];
                                                            updatedPhones[index] = {
                                                                ...updatedPhones[index],
                                                                workPhone: normalizePhoneNumber(value),
                                                                dopMain: idCity,
                                                                dop: 'check',
                                                            };
                                                            return updatedPhones;
                                                        });

                                                        // Проверка и установка состояния кнопки в зависимости от того, была ли введена последняя цифра
                                                        setTimeout(() => {
                                                            const lastChar = value[value.length - 1];
                                                            if (lastChar !== "_") {
                                                                setDisAddPhone(false); // Разрешаем кнопку, если введена последняя цифра
                                                                setClickNewNumber(false)
                                                            } else {
                                                                setDisAddPhone(true); // Запрещаем кнопку, если не введена последняя цифра
                                                            }
                                                        }, 0); // Нулевая задержка для моментальной проверки
                                                    }}
                                                    placeholder={`Введите рабочий телефон ${index + 1}`}
                                                />
                                                <div className="copy icon copyWorkPhone"
                                                     onClick={() => copyToClipboardOnClickWorkPhones(index)}
                                                >
                                                    <img src={copyImg} className="logo_img" width="20px" height="20px"/>
                                                </div>
                                                <div className="catalog__contacts phones_links">
                                                    <div className="phone_links theCard_phones">
                                                        <div className="call icon">
                                                            <a href={`tel:${additionalPhone[index].number}`}>
                                                                <img src={phoneImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="wa icon">
                                                            <a href={`whatsapp://send?phone=${additionalPhone[index].number}`}>
                                                                <img src={waImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="telegram icon">
                                                            <a href={`https://t.me/${additionalPhone[index].number}`}>
                                                                <img src={tgImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="vk icon">
                                                            <a href={`viber://add?number=${additionalPhone[index].number && (additionalPhone[index].number).replace(/^\+/, '')}`}>
                                                                <img src={vbImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<button className="delete_phone" onClick={() => handleDeletePhone(index)}>x</button>*/}
                                            <button style={{display: !bg ? "block" : "none"}} className="delete_phone"
                                                    onClick={() => deletePhone(index)}>x
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div>Телефон руководителя:</div>
                                <InputMask mask="+7(999)999-99-99" maskChar="_" name="leaderPhone"
                                           value={inputChange ? formData.leaderPhone : leaderPhone}
                                           onChange={handleInputChange}
                                           ref={inputRefs.textToCopyLeaderPhoneRef}
                                           style={{width: "54%"}}
                                           placeholder="Введите телефон руководителя"
                                           className={formData.leaderPhone ? "item_" : "item_-empty"}/>
                                <div className="copy icon copyPhone"
                                     onClick={() => copyToClipboardOnClick('textToCopyLeaderPhoneRef')}>
                                    <img src={copyImg} className="logo_img" width="20px"
                                         height="20px"/>
                                </div>
                                <div className="catalog__contacts phones_links"
                                     style={{top: "13px"}}
                                >
                                    <div className="phone_links theCard_phones">
                                        <div className="call icon">
                                            <a href={`tel:${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={phoneImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="wa icon">
                                            <a href={`whatsapp://send?phone=${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={waImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="telegram icon">
                                            <a href={`https://t.me/${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={tgImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="vk icon">
                                            <a href={`viber://add?number=${formData.leaderPhone && (formData.leaderPhone).replace(/^\+/, '')}`}>
                                                <img src={vbImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*</div>*/}
                            <div>Социальная сеть:</div>
                            <input className="item_" name="soc1" value={inputChange ? formData.soc1 : soc1}
                                   onChange={handleInputChange} placeholder="Введите соцсеть 1"/>
                            <div>Социальная сеть:</div>
                            <input className="item_" name="soc2" value={inputChange ? formData.soc2 : soc2}
                                   onChange={handleInputChange} placeholder="Введите соцсеть 2"/>
                            {/*<input className="item_" name="siteName" value={formData.siteName}*/}
                            {/*       onChange={handleInputChange} placeholder="Введите название сайта"/>*/}
                            <div>Комментарий:</div>
                            <textarea className="item_" name="messenger1"
                                      value={inputChange ? formData.messenger1 : messenger1}
                                      onChange={handleInputChange} placeholder="Комментарий 1"
                                      style={{height: "300px"}}/>
                            {/*<div>Комментарий:</div>*/}
                            {/*<textarea className="item_" name="messenger2"*/}
                            {/*          value={inputChange ? formData.messenger2 : messenger2}*/}
                            {/*          onChange={handleInputChange} placeholder="Комментарий 2"*/}
                            {/*          style={{height: "100px"}}/>*/}
                        </div>
                    </div>
                </>
            );
        case 'buh':
            return (
                <>
                    <header
                        className="header save__header"
                    >
                        <div className="header__left">
                            <div className="header__left">
                                <div>
                                    <Link to={`/`}
                                          onClick={setRender}
                                    >
                                        <img src={leftImg} className="logo_img" width="25px"
                                             height="25px"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                            <div
                                style={{cursor: "pointer"}}
                            >
                                {/*<Link to={`/`} onClick={createCompany}>СОХРАНИТЬ</Link>*/}
                                {/*<Link to={`/`} onClick={sentBDCreate}>СОХРАНИТЬ</Link>*/}
                                <a
                                    style={{display: bg ? "block" : "none"}}
                                    onClick={() => {
                                        setBg(!bg);
                                    }
                                    }>РЕДАКТИРОВАТЬ</a>
                                <a
                                    style={{display: !bg ? "block" : "none"}}
                                    onClick={() => {
                                        setBg(!bg);
                                        sentBDUpdate()
                                    }}>СОХРАНИТЬ</a>
                            </div>
                        </div>
                    </header>
                    <div className="share icon "
                         onClick={(e) => {
                             e.stopPropagation();
                             copyCurrentUrlToClipboard();
                         }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>Поделиться</div>
                            <img src={shareImg} className="share_img share_link" width="20px"
                                 height="20px"/>
                        </div>

                        <div className="item_card__products" style={{display: "flex"}}>
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{*/}
                            {/*        display: checked.flowers ? "block" : "none"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <img src={shareFlowers} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.strawberry ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareStrawberry} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.balloon ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareBaloon} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.cakes ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareCake} className="share_img products" width="25px"*/}
                            {/*         alt="QR"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div
                        className="bg"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    ></div>
                    <div
                        className="modal modal_storno"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    >
                        <div
                            style={showModalDeletePhone ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                номер телефона?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false);
                                        setShowModalDeletePhone(false)
                                        handleDeletePhone(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeletePhone(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                        <div
                            style={showModalDeleteAddress ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                город?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                        handleDeleteCity(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="create_organizations card_viewer"
                    >
                        <div className="bg" style={{display: bg ? "block" : "none"}}></div>
                        <div className="create_products">
                            <div>
                                <label htmlFor="flowers" className="create_products__label">
                                    <input
                                        type="checkbox"
                                        id="flowers"
                                        className="create_checkbox"
                                        onChange={() => handleCheckboxChange("flowers")}
                                        checked={checked.flowers}
                                    />
                                    <img src={shareFlowers} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="strawberry" className="create_products__label">
                                    <input type="checkbox" id="strawberry" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("strawberry")} checked={checked.strawberry}/>
                                    <img src={shareStrawberry} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="balloon" className="create_products__label">
                                    <input type="checkbox" id="balloon" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("balloon")} checked={checked.balloon}/>
                                    <img src={shareBaloon} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="cake" className="create_products__label">
                                    <input type="checkbox" id="cake" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("cakes")} checked={checked.cakes}/>
                                    <img src={shareCake} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                        </div>
                        <div>Название организации: </div>
                        <div className="create_fields">
                            <input className="item_" name="name" value={inputChange ? formData.name : name}
                                   onChange={handleInputChange}
                                   placeholder="Введите название"/>
                            <div>
                                <div>ИНН: </div>
                                <div className="reques_item">
                                    <input className={formData.INN ? "item_" : "item_-empty"}
                                           name="INN"
                                           value={inputChange ? normalizeINN(formData.INN) : INN}
                                           onChange={
                                               (e) => {
                                                   tokenSentForINN(e);
                                                   handleInputChange(e);
                                               }

                                           }
                                           placeholder="Введите ИНН"
                                           ref={inputRefs.textToCopyINNRef}
                                           required
                                    />
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResult ? "block" : "none"}}
                                >
                                    {modalResultsINN && modalResultsINN.map((result) => {
                                        console.log("INN datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        // setBool(true);
                                                        // getNamesBD(result.unrestricted_value, 56.183759, 70.463626, 56.11055, 69.479639)
                                                        // localStorage.setItem('searchValue CREATE', result.unrestricted_value)
                                                        arrangementCompanyData()
                                                        setOpenSearchResult(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                    {/*<span>{result.unrestricted_value}</span>*/}
                                                    {/*<span>{result.data.country},</span>*/}
                                                    {/*<span>{result.data.city},</span>*/}
                                                    {/*<span>{result.data.region}</span>*/}
                                                    {/*<span>{result.district}</span>*/}

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>Расчетный счет: </div>
                            <div className="reques_item">
                                <input className="item_" name="rs" value={inputChange ? formData.rs : rs}
                                       ref={inputRefs.textToCopyRSRef}
                                       onChange={handleInputChange}
                                       placeholder="Введите Р/С"/>
                                <div className="copy icon"
                                     onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                    <img src={copyImg} className="logo_img" width="20px"
                                         height="20px"/>
                                </div>
                            </div>
                            <div>ОГРН: </div>
                            <div className="reques_item">
                                <input className="item_" name="OGRN" value={inputChange ? formData.OGRN : OGRN}
                                       ref={inputRefs.textToCopyOGRNRef}
                                       onChange={handleInputChange}
                                       placeholder="Введите ОГРН"/>
                                <div className="copy icon"
                                     onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                    <img src={copyImg} className="logo_img" width="20px"
                                         height="20px"/>
                                </div>
                            </div>
                            <div>
                                <div>БИК: </div>
                                <div className="reques_item">
                                    <input className="item_" name="BIK" value={inputChange ? formData.BIK : BIK}
                                           ref={inputRefs.textToCopyBIKRef}
                                           onChange={
                                               (e) => {
                                                   tokenSentForBanks(e);
                                                   handleInputChange(e);
                                               }

                                           } placeholder="Введите БИК"/>
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResultBanks ? "block" : "none"}}
                                >
                                    {modalResultsBanks && modalResultsBanks.map((result) => {
                                        console.log("Banks datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        arrangementBankBIK()
                                                        setOpenSearchResultBanks(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div>Название банка: </div>
                            <input className="item_" name="bankName" value={inputChange ? formData.bankName : bank}
                                   onChange={handleInputChange} placeholder="Введите название банка"/>

                            <div>QR на РС:</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input className="item_"
                                       name="QRrs"
                                       value={inputChange ? formData.QRrs : QRrs}
                                       onChange={handleInputChange}
                                       placeholder="Введите QR на р/с"/>
                                <a
                                    href={formData.QRrs }
                                    target="_blank" className="copy icon"
                                    style={formData.QRrs ?
                                        { display: "block", zIndex: "205" } : { display: "none" }}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{ marginLeft: "10px" }}/>
                                </a>
                            </div>
                            <div>QR статичный:</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input className="item_"
                                       name="QRstatic"
                                       value={inputChange ? formData.QRstatic : QRstatic}
                                       onChange={handleInputChange}
                                       placeholder="Введите QR на статичный"/>
                                <a
                                    href={formData.QRstatic }
                                    target="_blank" className="copy icon"
                                    style={formData.QRstatic ?
                                        { display: "block", zIndex: "205" } : { display: "none" }}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{ marginLeft: "10px" }}/>
                                </a>
                            </div>
                            <div>Договор:</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <input
                                    className="item_"
                                    name="contract"
                                    value={inputChange ? formData.contract : contract}
                                    onChange={handleInputChange}
                                    placeholder="Введите договор"
                                />
                                <a
                                    href={formData.contract}
                                    target="_blank"
                                    className="copy icon"
                                    style={formData.contract ?
                                        { display: "block", zIndex: "205" } : { display: "none" }}
                                >
                                    <img
                                        src={rightImg}
                                        className="logo_img"
                                        width="25px" height="25px"
                                        style={{ marginLeft: "10px" }} />
                                </a>
                            </div>

                            <div>Адрес регистрации ЮЛ:</div>
                            <input className="item_" name="cityReg"
                                   value={inputChange ? formData.cityReg : addressCompany}
                                   onChange={handleInputChange}
                                   placeholder="Адрес регистрации ЮЛ"/>
                            <div className="create_cities">
                                <input className="item_" name="city" value={additionalCity[0]?.address_custom} onChange={
                                    (e) => {
                                        tokenSentCity(e);
                                        handleCityChange(0, e);
                                        setSearchedResult(true)
                                    }
                                }
                                       // placeholder="Введите город"
                                />
                                <div className="click_plus" style={{display: (info.role.role === "buh") ? "block" : "none"}}>
                                    Нажмите на <b>+</b> чтобы добавить город
                                </div>
                                <button disabled={disAddCity ? true : false} className="add_city" onClick={() => {
                                    handleAddCity();
                                    setDisAddCity(true)
                                    setOpenInput(prevState =>
                                        [...prevState, false]
                                    )
                                }}>+
                                </button>
                                 {/*Дополнительные города */}
                                {additionalCity && additionalCity.map((cityItem, index) => {
                                    console.log('cityItem', cityItem);
                                    console.log('additionalCity', additionalCity);
                                    const valueItem = `${cityItem.settlement || ''} ${cityItem.nameCityTitle || ''} ${cityItem.region || ''} ${cityItem.area_with_typeTitle || ''} ${cityItem.settlement_with_typeTitle || ''} ${cityItem.postcode || ''}` || ''
                                    return (
                                        <div key={index} className="input_item">
                                            <div>Город {index + 1} </div>
                                            <div
                                                style={{position: "relative"}}
                                            >
                                                <input
                                                    className="item_"
                                                    disabled={openInput[index]}
                                                    name={`city${index + 1}`}
                                                    value={cityItem.nameCityTitle}
                                                    onChange={(e) => {
                                                        handleCityChange(index, e);
                                                        tokenSentCity(e);
                                                        setSearchedResult(true);
                                                    }}
                                                    placeholder={`Введите город ${index + 1}`}
                                                />
                                                <div
                                                    className="item_-fullAddress"
                                                    style={{display: (cityItem.nameCityTitle !== '' || cityItem.settlement !== '') ? 'block' : 'none'}}
                                                >{cityItem.settlement || ''} {cityItem.nameCityTitle || ''} {cityItem.regionTitle || cityItem.region} {cityItem.settlement_with_typeTitle || '' || cityItem.area_with_typeTitle || ''} {cityItem.postcode || ''}</div>
                                            </div>
                                            <button className="delete_city" onClick={() => {
                                                deleteCity(index);
                                            }}>x
                                            </button>
                                        </div>
                                    )
                                })}
                                <div
                                    className="search_results"
                                    style={searchedResult ? {display: "block"} : {display: "none"}}
                                >
                                    {modalResults && modalResults.map((result) => {
                                        // console.log("FRONT", result)
                                        if (result.postal_code !== null) {
                                            // setSearchedResult(true)
                                            return (
                                                <div className="search_results__wrapper" key={result.id}
                                                     onClick={showChange}>
                                                    <div
                                                        className="search_results__item"
                                                        onClick={() => {
                                                            setShowFullAddress(true)
                                                            setSearchedResult(false)
                                                            const res = result.settlement + ', ' + result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                                result.area_with_typeTitle + ', ' + result.postcode + ', ' + result.settlement_with_typeTitle;
                                                            arrangementAddress(res, result)
                                                            setDisAddCity(false)
                                                            setOpenInput(prevState => {
                                                                // Создаем новый массив, в котором все элементы копируются из предыдущего состояния
                                                                // Затем устанавливаем значение true для последнего элемента
                                                                return prevState.map((item, index) => (index === prevState.length - 1 ? true : item));
                                                            });
                                                        }}
                                                    >
                                                        <div>
                                                            {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null; // Или другое действие, если city пустой
                                        }
                                    })}
                                </div>
                            </div>
                            {/*<input className="item_" name="region" value={formData.region} onChange={handleInputChange}*/}
                            {/*       placeholder="Введите регион"/>*/}
                            {/*<div>*/}
                            {/*    <input className="item_" name="lettersAddress" value={formData.lettersAddress} onChange={*/}
                            {/*        (e) => {*/}
                            {/*            tokenSentCity(e, 'address_letters');*/}
                            {/*            handleInputChange(e);*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*           placeholder="Введите адрес для писем"/>*/}
                            {/*    <div*/}
                            {/*        className="search_results"*/}
                            {/*        style={{display: openSearchResultLettersAddress ? "block" : "none"}}*/}
                            {/*    >*/}
                            {/*        {modalResultsLettersAddress && modalResultsLettersAddress.map((result) => {*/}
                            {/*            console.log("FRONT", result)*/}
                            {/*            if (result.data.postal_code !== null) {*/}
                            {/*                return (*/}
                            {/*                    <div className="search_results__wrapper" key={result.id}>*/}
                            {/*                        <div*/}
                            {/*                            className="search_results__item"*/}
                            {/*                            onClick={() => {*/}
                            {/*                                setOpenSearchResultLettersAddress(false)*/}
                            {/*                                arrangementLettersAddress()*/}
                            {/*                            }}*/}
                            {/*                        >*/}
                            {/*                            {result.unrestricted_value}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                );*/}
                            {/*            } else {*/}
                            {/*                return null; // Или другое действие, если city пустой*/}
                            {/*            }*/}
                            {/*        })}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<input name="leaderPhone" value={formData.leaderPhone}*/}
                            {/*       className={formData.leaderPhone ? "item_" : "item_-empty"}*/}
                            {/*       onChange={handleInputChange} placeholder="Введите телефон руководителя" required/>*/}

                            <div className="create_phones">
                                <div className="create_phone">
                                    <div style={{display: !bg ? "block" : "none"}} className="click_plus">
                                        Нажмите на <b>+</b> чтобы добавить рабочий телефон
                                    </div>
                                    <button
                                        style={{display: !bg ? "block" : "none"}}
                                        disabled={disAddPhone ? true : false} className="add_phone" onClick={() => {
                                        handleAddPhone();
                                        setDisAddPhone(true)
                                    }}>+
                                    </button>
                                    {additionalPhone && additionalPhone.map((phone, index) => (
                                        <div key={index} className="input_item">
                                            <div>Рабочий телефон {index + 1}</div>

                                            <div style={{position: "relative"}}>
                                                <InputMask
                                                    className="item_"
                                                    mask="+7(999)999-99-99"
                                                    maskChar="_"
                                                    name={`workPhone${index + 1}`}
                                                    value={additionalPhone[index].number}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setAdditionalPhone(prevData => {
                                                            const updatedPhones = [...prevData];
                                                            updatedPhones[index] = {
                                                                ...updatedPhones[index],
                                                                workPhone: normalizePhoneNumber(value)
                                                            };
                                                            return updatedPhones;
                                                        });

                                                        // Проверка и установка состояния кнопки в зависимости от того, была ли введена последняя цифра
                                                        setTimeout(() => {
                                                            const lastChar = value[value.length - 1];
                                                            if (lastChar !== '_') {
                                                                setDisAddPhone(false); // Разрешаем кнопку, если введена последняя цифра
                                                            } else {
                                                                setDisAddPhone(true); // Запрещаем кнопку, если не введена последняя цифра
                                                            }
                                                        }, 0); // Нулевая задержка для моментальной проверки
                                                    }}
                                                    placeholder={`Введите рабочий телефон ${index + 1}`}
                                                />
                                                <div className="catalog__contacts phones_links">
                                                    <div className="phone_links theCard_phones">
                                                        <div className="call icon">
                                                            <a href={`tel:${additionalPhone[index].number}`}>
                                                                <img src={phoneImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="wa icon">
                                                            <a href={`whatsapp://send?phone=${additionalPhone[index].number}`}>
                                                                <img src={waImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="telegram icon">
                                                            <a href={`https://t.me/${additionalPhone[index].number}`}>
                                                                <img src={tgImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="vk icon">
                                                            <a href={`viber://add?number=${additionalPhone[index].number && (additionalPhone[index].number).replace(/^\+/, '')}`}>
                                                                <img src={vbImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<button className="delete_phone" onClick={() => handleDeletePhone(index)}>x</button>*/}
                                            <button style={{display: !bg ? "block" : "none"}} className="delete_phone" onClick={() => deletePhone(index)}>x
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{position: "relative"}}>
                                <div>Телефон руководителя:</div>
                                <InputMask mask="+7(999)999-99-99" maskChar="_" name="leaderPhone"
                                           value={inputChange ? formData.leaderPhone : leaderPhone}
                                           onChange={handleInputChange}
                                           style={{width: "54%"}}
                                           placeholder="Введите телефон руководителя"
                                           className={formData.leaderPhone ? "item_" : "item_-empty"}/>
                                <div className="catalog__contacts phones_links"
                                     style={{top: "13px"}}
                                >
                                    <div className="phone_links theCard_phones">
                                        <div className="call icon">
                                            <a href={`tel:${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={phoneImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="wa icon">
                                            <a href={`whatsapp://send?phone=${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={waImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="telegram icon">
                                            <a href={`https://t.me/${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={tgImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="vk icon">
                                            <a href={`viber://add?number=${formData.leaderPhone && (formData.leaderPhone).replace(/^\+/, '')}`}>
                                                <img src={vbImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input className="item_" name="soc1" value={inputChange ? formData.soc1 : soc1}
                                   onChange={handleInputChange} placeholder="Введите соцсеть 1"/>
                            <input className="item_" name="soc2" value={inputChange ? formData.soc2 : soc2}
                                   onChange={handleInputChange} placeholder="Введите соцсеть 2"/>
                            <input className="item_" name="siteName" value={formData.siteName}
                                   onChange={handleInputChange} placeholder="Введите название сайта"/>
                            <textarea className="item_" name="messenger1"
                                      value={inputChange ? formData.messenger1 : messenger1}
                                      onChange={handleInputChange} placeholder="Комментарий 1"
                                      style={{height: "300px"}}/>
                            {/*<div className="item_">*/}
                            {/*    {formData.messenger1.split('\n').map((line, index) => (*/}
                            {/*        <p key={index}>{line}</p>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                            {/*<div className="item_">*/}
                            {/*    {formData.messenger2.split('\n').map((line, index) => (*/}
                            {/*        <p key={index}>{line}</p>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </>
            );
        case 'manager':
            return (
                <>

                    <header
                        className="header save__header"
                    >
                        <div className="header__left">
                            <div className="header__left">
                                <div>
                                    <Link to={`/`}
                                          onClick={setRender}
                                    >
                                        <img src={leftImg} className="logo_img" width="25px"
                                             height="25px"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="header__right">
                            <div className="filter_"></div>
                            <div
                                style={{cursor: "pointer"}}
                            >
                                {/*<Link to={`/`} onClick={createCompany}>СОХРАНИТЬ</Link>*/}
                                {/*<Link to={`/`} onClick={sentBDCreate}>СОХРАНИТЬ</Link>*/}
                                {/*<a*/}
                                {/*    style={{display: bg ? "block" : "none"}}*/}
                                {/*    onClick={() => {*/}
                                {/*        setBg(!bg);*/}
                                {/*    }*/}
                                {/*    }>РЕДАКТИРОВАТЬ</a>*/}
                                {/*<a*/}
                                {/*    style={{display: !bg ? "block" : "none"}}*/}
                                {/*    onClick={() => {*/}
                                {/*        setBg(!bg);*/}
                                {/*        sentBDUpdate()*/}
                                {/*    }}>СОХРАНИТЬ</a>*/}
                            </div>
                        </div>
                    </header>
                    <div className="share icon "
                         onClick={(e) => {
                             e.stopPropagation();
                             copyCurrentUrlToClipboard();
                         }}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div>Поделиться</div>
                            <img src={shareImg} className="share_img share_link" width="20px"
                                 height="20px"/>
                        </div>

                        <div className="item_card__products" style={{display: "flex"}}>
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{*/}
                            {/*        display: checked.flowers ? "block" : "none"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <img src={shareFlowers} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.strawberry ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareStrawberry} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.balloon ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareBaloon} className="share_img products" width="25px"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                            {/*<div*/}
                            {/*    className="icon"*/}
                            {/*    // onClick={() => copyCurrentUrlToClipboard(`card/${name.QRrs}`)}*/}
                            {/*    style={{display: checked.cakes ? "block" : "none"}}*/}
                            {/*>*/}
                            {/*    <img src={shareCake} className="share_img products" width="25px"*/}
                            {/*         alt="QR"*/}
                            {/*         height="25px"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div
                        className="bg"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    ></div>
                    <div
                        className="modal modal_storno"
                        style={showModal ? {display: "block"} : {display: "none"}}
                    >
                        <div
                            style={showModalDeletePhone ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                номер телефона?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false);
                                        setShowModalDeletePhone(false)
                                        handleDeletePhone(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeletePhone(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                        <div
                            style={showModalDeleteAddress ? {display: "block"} : {display: "none"}}
                        >
                            <div>Вы точно хотите
                                удалить
                                город?
                            </div>
                            <div
                                className="modal__btns"
                            >
                                <button
                                    className="modal_btn yes"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                        handleDeleteCity(deleteIndex)
                                    }}
                                >Да
                                </button>
                                <button
                                    className="modal_btn no"
                                    onClick={() => {
                                        setShowModal(false)
                                        setShowModalDeleteAddress(false)
                                    }}
                                >Нет
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="create_organizations card_viewer"
                    >
                        <div className="bg" style={{display: bg ? "block" : "none"}}></div>
                        <div className="create_products">
                            <div>
                                <label htmlFor="flowers" className="create_products__label">
                                    <input
                                        type="checkbox"
                                        id="flowers"
                                        className="create_checkbox"
                                        onChange={() => handleCheckboxChange("flowers")}
                                        checked={checked.flowers}
                                    />
                                    <img src={shareFlowers} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="strawberry" className="create_products__label">
                                    <input type="checkbox" id="strawberry" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("strawberry")} checked={checked.strawberry}/>
                                    <img src={shareStrawberry} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="balloon" className="create_products__label">
                                    <input type="checkbox" id="balloon" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("balloon")} checked={checked.balloon}/>
                                    <img src={shareBaloon} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                            <div>
                                <label htmlFor="cake" className="create_products__label">
                                    <input type="checkbox" id="cake" className="create_checkbox"
                                           onChange={() => handleCheckboxChange("cakes")} checked={checked.cakes}/>
                                    <img src={shareCake} className="share_img products" width="25px" height="25px"/>
                                </label>
                            </div>
                        </div>
                        <div className="create_fields">
                            <div style={{display: (formData.name || name) ? "block" : "none"}}>
                                <div>Название организации:</div>
                                <input className="item_" name="name" value={inputChange ? formData.name : name}
                                       onChange={handleInputChange}
                                       placeholder="Введите название"/>
                            </div>
                            <div style={{display: (normalizeINN(formData.INN) || INN) ? "block" : "none"}}>
                                <div>ИНН:</div>
                                <div className="reques_item">
                                    <input className={formData.INN ? "item_" : "item_-empty"}
                                           name="INN"
                                           value={inputChange ? normalizeINN(formData.INN) : INN}
                                           onChange={
                                               (e) => {
                                                   tokenSentForINN(e);
                                                   handleInputChange(e);
                                               }

                                           }
                                           placeholder="Введите ИНН"
                                           ref={inputRefs.textToCopyINNRef}
                                           required
                                    />
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyINNRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResult ? "block" : "none"}}
                                >
                                    {modalResultsINN && modalResultsINN.map((result) => {
                                        console.log("INN datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        // setBool(true);
                                                        // getNamesBD(result.unrestricted_value, 56.183759, 70.463626, 56.11055, 69.479639)
                                                        // localStorage.setItem('searchValue CREATE', result.unrestricted_value)
                                                        arrangementCompanyData()
                                                        setOpenSearchResult(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                    {/*<span>{result.unrestricted_value}</span>*/}
                                                    {/*<span>{result.data.country},</span>*/}
                                                    {/*<span>{result.data.city},</span>*/}
                                                    {/*<span>{result.data.region}</span>*/}
                                                    {/*<span>{result.district}</span>*/}

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{display: (formData.rs || rs) ? "block" : "none"}}>
                                <div>Расчетный счет:</div>
                                <div className="reques_item">
                                    <input className="item_" name="rs" value={inputChange ? formData.rs : rs}
                                           ref={inputRefs.textToCopyRSRef}
                                           onChange={handleInputChange}
                                           placeholder="Введите Р/С"/>
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyRSRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: (formData.OGRN || OGRN) ? "block" : "none"}}>
                                <div>ОГРН:</div>
                                <div className="reques_item">
                                    <input className="item_" name="OGRN" value={inputChange ? formData.OGRN : OGRN}
                                           ref={inputRefs.textToCopyOGRNRef}
                                           onChange={handleInputChange}
                                           placeholder="Введите ОГРН"/>
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyOGRNRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: (formData.BIK || BIK) ? "block" : "none"}}>
                                <div>БИК:</div>
                                <div className="reques_item">
                                    <input className="item_" name="BIK" value={inputChange ? formData.BIK : BIK}
                                           ref={inputRefs.textToCopyBIKRef}
                                           onChange={
                                               (e) => {
                                                   tokenSentForBanks(e);
                                                   handleInputChange(e);
                                               }

                                           } placeholder="Введите БИК"/>
                                    <div className="copy icon"
                                         onClick={() => copyToClipboardOnClick('textToCopyBIKRef')}>
                                        <img src={copyImg} className="logo_img" width="20px"
                                             height="20px"/>
                                    </div>
                                </div>
                                <div
                                    className="search_results"
                                    style={{display: openSearchResultBanks ? "block" : "none"}}
                                >
                                    {modalResultsBanks && modalResultsBanks.map((result) => {
                                        console.log("Banks datas", result)
                                        return (
                                            <div className="search_results__wrapper" key={result.id}>
                                                <div
                                                    className="search_results__item"
                                                    onClick={() => {
                                                        arrangementBankBIK()
                                                        setOpenSearchResultBanks(false)
                                                    }}
                                                >
                                                    {result.unrestricted_value}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{display: (formData.bankName || bank) ? "block" : "none"}}>
                                <div>Название банка:</div>
                                <input className="item_" name="bankName" value={inputChange ? formData.bankName : bank}
                                       onChange={handleInputChange} placeholder="Введите название банка"/>
                            </div>

                            {/*-----*/}
                            {/*<div>QR код:</div>*/}
                            {/*<div style={{ display: "flex", alignItems: "center" }}>*/}
                            {/*    <input className="item_"*/}
                            {/*           name="QRrs"*/}
                            {/*           value={inputChange ? formData.QRrs : QRrs}*/}
                            {/*           onChange={handleInputChange}*/}
                            {/*           placeholder="Введите QR на р/с"/>*/}
                            {/*    <a*/}
                            {/*        href={formData.QRrs }*/}
                            {/*        target="_blank" className="copy icon"*/}
                            {/*        style={formData.QRrs ?*/}
                            {/*            { display: "block", zIndex: "205" } : { display: "none" }}*/}
                            {/*    >*/}
                            {/*        <img*/}
                            {/*            src={rightImg}*/}
                            {/*            className="logo_img"*/}
                            {/*            width="25px" height="25px"*/}
                            {/*            style={{ marginLeft: "10px" }}/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            {/*<div>QR статичный:</div>*/}
                            {/*<div style={{ display: "flex", alignItems: "center" }}>*/}
                            {/*    <input className="item_"*/}
                            {/*           name="QRstatic"*/}
                            {/*           value={inputChange ? formData.QRstatic : QRstatic}*/}
                            {/*           onChange={handleInputChange}*/}
                            {/*           placeholder="Введите QR на статичный"/>*/}
                            {/*    <a*/}
                            {/*        href={formData.QRstatic }*/}
                            {/*        target="_blank" className="copy icon"*/}
                            {/*        style={formData.QRstatic ?*/}
                            {/*            { display: "block", zIndex: "205" } : { display: "none" }}*/}
                            {/*    >*/}
                            {/*        /!*<img*!/*/}
                            {/*        /!*    src={rightImg}*!/*/}
                            {/*        /!*    className="logo_img"*!/*/}
                            {/*        /!*    width="25px" height="25px"*!/*/}
                            {/*        /!*    style={{ marginLeft: "10px" }}/>*!/*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            <div style={{display: (formData.contract || contract) ? "block" : "none"}}>
                                <div>Договор:</div>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <input
                                        className="item_"
                                        name="contract"
                                        value={inputChange ? formData.contract : contract}
                                        onChange={handleInputChange}
                                        placeholder="Введите договор"
                                    />
                                    <a
                                        href={formData.contract}
                                        target="_blank"
                                        className="copy icon"
                                        style={formData.contract ?
                                            {display: "block", zIndex: "205"} : {display: "none"}}
                                    >
                                        <img
                                            src={rightImg}
                                            className="logo_img"
                                            width="25px" height="25px"
                                            style={{marginLeft: "10px"}}/>
                                    </a>
                                </div>
                            </div>
                            {/*-----*/}
                            <div style={{display: (formData.cityReg || addressCompany) ? "block" : "none"}}>
                                <div>Адрес регистрации ЮЛ:</div>
                                <input className="item_" name="cityReg"
                                       value={inputChange ? formData.cityReg : addressCompany}
                                       onChange={handleInputChange}
                                       placeholder="Адрес регистрации ЮЛ"/>
                            </div>
                            <div className="create_cities">
                                {/*<input className="item_" name="city" value={additionalCity[0]?.address_custom} onChange={*/}
                                {/*    (e) => {*/}
                                {/*        tokenSentCity(e);*/}
                                {/*        handleCityChange(0, e);*/}
                                {/*        setSearchedResult(true)*/}
                                {/*    }*/}
                                {/*}*/}
                                {/*       placeholder="Введите город"*/}
                                {/*/>*/}
                                {/*<div className="click_plus" style={{display: "none"}}>*/}
                                {/*    Нажмите на <b>+</b> чтобы добавить город*/}
                                {/*</div>*/}
                                {/*<button disabled={disAddCity ? true : false} className="add_city" onClick={() => {*/}
                                {/*    handleAddCity();*/}
                                {/*    setDisAddCity(true)*/}
                                {/*    setOpenInput(prevState =>*/}
                                {/*        [...prevState, false]*/}
                                {/*    )*/}
                                {/*}}>+*/}
                                {/*</button>*/}
                                {/* Дополнительные города */}
                                {additionalCity && additionalCity.map((cityItem, index) => {
                                    console.log('cityItem', cityItem);

                                    console.log('additionalCity', additionalCity);
                                    const valueItem = `${cityItem.settlement || ''} ${cityItem.nameCityTitle || ''} ${cityItem.region || ''} ${cityItem.area_with_typeTitle || ''} ${cityItem.settlement_with_typeTitle || ''} ${cityItem.postcode || ''}` || ''
                                    return (
                                        <div key={index} className="input_item">
                                            <div>Город {index + 1} </div>
                                            <div
                                                style={{position: "relative"}}
                                            >
                                                <input
                                                    className="item_"
                                                    disabled={openInput[index]}
                                                    name={`city${index + 1}`}
                                                    value={cityItem.nameCityTitle}
                                                    onChange={(e) => {
                                                        handleCityChange(index, e);
                                                        tokenSentCity(e);
                                                        setSearchedResult(true);
                                                    }}
                                                    placeholder={`Введите город ${index + 1}`}
                                                />
                                                <div
                                                    className="item_-fullAddress"
                                                    style={{display: (cityItem.nameCityTitle !== '' || cityItem.settlement !== '') ? 'block' : 'none'}}
                                                >{cityItem.settlement || ''} {cityItem.nameCityTitle || ''} {cityItem.regionTitle || cityItem.region} {cityItem.settlement_with_typeTitle || '' || cityItem.area_with_typeTitle || ''} {cityItem.postcode || ''}</div>
                                            </div>
                                            {/*<button className="delete_city" onClick={() => {*/}
                                            {/*    deleteCity(index);*/}
                                            {/*}}>x*/}
                                            {/*</button>*/}
                                        </div>
                                    )
                                })}
                                <div
                                    className="search_results"
                                    style={searchedResult ? {display: "block"} : {display: "none"}}
                                >
                                    {modalResults && modalResults.map((result) => {
                                        // console.log("FRONT", result)
                                        if (result.postal_code !== null) {
                                            // setSearchedResult(true)
                                            return (
                                                <div className="search_results__wrapper" key={result.id}
                                                     onClick={showChange}>
                                                    <div
                                                        className="search_results__item"
                                                        onClick={() => {
                                                            setShowFullAddress(true)
                                                            setSearchedResult(false)
                                                            const res = result.settlement + ', ' + result.nameCityTitle + ', ' + result.regionTitle + ', ' +
                                                                result.area_with_typeTitle + ', ' + result.postcode + ', ' + result.settlement_with_typeTitle;
                                                            arrangementAddress(res, result)
                                                            setDisAddCity(false)
                                                            setOpenInput(prevState => {
                                                                // Создаем новый массив, в котором все элементы копируются из предыдущего состояния
                                                                // Затем устанавливаем значение true для последнего элемента
                                                                return prevState.map((item, index) => (index === prevState.length - 1 ? true : item));
                                                            });
                                                        }}
                                                    >
                                                        <div>
                                                            {`${result.settlement} ${result.nameCityTitle} ${result.regionTitle} 
                                                    ${result.area_with_typeTitle} ${result.postcode}`}
                                                        </div>

                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null; // Или другое действие, если city пустой
                                        }
                                    })}
                                </div>
                            </div>
                            {/*<input className="item_" name="region" value={formData.region} onChange={handleInputChange}*/}
                            {/*       placeholder="Введите регион"/>*/}
                            {/*<div>*/}
                            {/*    <input className="item_" name="lettersAddress" value={formData.lettersAddress} onChange={*/}
                            {/*        (e) => {*/}
                            {/*            tokenSentCity(e, 'address_letters');*/}
                            {/*            handleInputChange(e);*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*           placeholder="Введите адрес для писем"/>*/}
                            {/*    <div*/}
                            {/*        className="search_results"*/}
                            {/*        style={{display: openSearchResultLettersAddress ? "block" : "none"}}*/}
                            {/*    >*/}
                            {/*        {modalResultsLettersAddress && modalResultsLettersAddress.map((result) => {*/}
                            {/*            console.log("FRONT", result)*/}
                            {/*            if (result.data.postal_code !== null) {*/}
                            {/*                return (*/}
                            {/*                    <div className="search_results__wrapper" key={result.id}>*/}
                            {/*                        <div*/}
                            {/*                            className="search_results__item"*/}
                            {/*                            onClick={() => {*/}
                            {/*                                setOpenSearchResultLettersAddress(false)*/}
                            {/*                                arrangementLettersAddress()*/}
                            {/*                            }}*/}
                            {/*                        >*/}
                            {/*                            {result.unrestricted_value}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                );*/}
                            {/*            } else {*/}
                            {/*                return null; // Или другое действие, если city пустой*/}
                            {/*            }*/}
                            {/*        })}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<input name="leaderPhone" value={formData.leaderPhone}*/}
                            {/*       className={formData.leaderPhone ? "item_" : "item_-empty"}*/}
                            {/*       onChange={handleInputChange} placeholder="Введите телефон руководителя" required/>*/}

                            <div className="create_phones">
                                <div className="create_phone">
                                    <div style={{display: !bg ? "block" : "none"}} className="click_plus">
                                        Нажмите на <b>+</b> чтобы добавить рабочий телефон
                                    </div>
                                    <button
                                        style={{display: !bg ? "block" : "none"}}
                                        disabled={disAddPhone ? true : false} className="add_phone" onClick={() => {
                                        handleAddPhone();
                                        setDisAddPhone(true)
                                    }}>+
                                    </button>
                                    {additionalPhone && additionalPhone.map((phone, index) => (
                                        <div key={index} className="input_item">
                                            <div>Рабочий телефон {index + 1}</div>

                                            <div style={{position: "relative"}}>
                                                <InputMask
                                                    className="item_"
                                                    mask="+7(999)999-99-99"
                                                    maskChar="_"
                                                    name={`workPhone${index + 1}`}
                                                    value={additionalPhone[index].number}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        setAdditionalPhone(prevData => {
                                                            const updatedPhones = [...prevData];
                                                            updatedPhones[index] = {
                                                                ...updatedPhones[index],
                                                                workPhone: normalizePhoneNumber(value)
                                                            };
                                                            return updatedPhones;
                                                        });

                                                        // Проверка и установка состояния кнопки в зависимости от того, была ли введена последняя цифра
                                                        setTimeout(() => {
                                                            const lastChar = value[value.length - 1];
                                                            if (lastChar !== '_') {
                                                                setDisAddPhone(false); // Разрешаем кнопку, если введена последняя цифра
                                                            } else {
                                                                setDisAddPhone(true); // Запрещаем кнопку, если не введена последняя цифра
                                                            }
                                                        }, 0); // Нулевая задержка для моментальной проверки
                                                    }}
                                                    placeholder={`Введите рабочий телефон ${index + 1}`}
                                                />
                                                <div className="catalog__contacts phones_links">
                                                    <div className="phone_links theCard_phones">
                                                        <div className="call icon">
                                                            <a href={`tel:${additionalPhone[index].number}`}>
                                                                <img src={phoneImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="wa icon">
                                                            <a href={`whatsapp://send?phone=${additionalPhone[index].number}`}>
                                                                <img src={waImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="telegram icon">
                                                            <a href={`https://t.me/${additionalPhone[index].number}`}>
                                                                <img src={tgImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                        <div className="vk icon">
                                                            <a href={`viber://add?number=${additionalPhone[index].number && (additionalPhone[index].number).replace(/^\+/, '')}`}>
                                                                <img src={vbImg} className="logo_img" width="25px"
                                                                     height="25px"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<button className="delete_phone" onClick={() => handleDeletePhone(index)}>x</button>*/}
                                            <button style={{display: !bg ? "block" : "none"}} className="delete_phone"
                                                    onClick={() => deletePhone(index)}>x
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{position: "relative", display: (formData.leaderPhone || leaderPhone) ? "block" : "none"}}>
                                <div>Телефон руководителя:</div>
                                <InputMask mask="+7(999)999-99-99" maskChar="_" name="leaderPhone"
                                           value={inputChange ? formData.leaderPhone : leaderPhone}
                                           onChange={handleInputChange}
                                           style={{width: "54%"}}
                                           placeholder="Введите телефон руководителя"
                                           className={formData.leaderPhone ? "item_" : "item_-empty"}/>
                                <div className="catalog__contacts phones_links"
                                     style={{top: "13px"}}
                                >
                                    <div className="phone_links theCard_phones">
                                        <div className="call icon">
                                            <a href={`tel:${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={phoneImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="wa icon">
                                            <a href={`whatsapp://send?phone=${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={waImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="telegram icon">
                                            <a href={`https://t.me/${normalizePhoneNumber(formData.leaderPhone)}`}>
                                                <img src={tgImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                        <div className="vk icon">
                                            <a href={`viber://add?number=${formData.leaderPhone && (formData.leaderPhone).replace(/^\+/, '')}`}>
                                                <img src={vbImg} className="logo_img" width="25px"
                                                     height="25px"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: (formData.soc1 || soc1) ? "block" : "none"}}>
                                <div>Социальная сеть</div>
                                <input className="item_" name="soc1" value={inputChange ? formData.soc1 : soc1}
                                       onChange={handleInputChange} placeholder="Введите соцсеть 1"/>
                            </div>
                            <div style={{display: (formData.soc2 || soc2) ? "block" : "none"}}>
                                <div>Социальная сеть</div>
                                <input className="item_" name="soc2" value={inputChange ? formData.soc2 : soc2}
                                       onChange={handleInputChange} placeholder="Введите соцсеть 2"/>
                            </div>
                            {/*<input className="item_" name="siteName" value={formData.siteName}*/}
                            {/*       onChange={handleInputChange} placeholder="Введите название сайта"/>*/}
                            {/*<textarea className="item_" name="messenger1" value={inputChange ? formData.messenger1 : messenger1}*/}
                            {/*          onChange={handleInputChange} placeholder="Комментарий 1" style={{resize: "none"}}/>*/}
                            {/*<textarea className="item_" name="messenger2" value={inputChange ? formData.messenger2 : messenger2}*/}
                            {/*          onChange={handleInputChange} placeholder="Комментарий 2" style={{height: "100px"}}/>*/}
                            <div style={{display: (formData.messenger1) ? "block" : "none"}}>
                                <div>Комментарий</div>
                                <div className="item_">
                                    {formData.messenger1.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            </div>
                            {/*<div style={{display: (formData.messenger2) ? "block" : "none"}}>*/}
                            {/*    <div>Комментарий</div>*/}
                            {/*    <div className="item_">*/}
                            {/*        {formData.messenger2.split('\n').map((line, index) => (*/}
                            {/*            <p key={index}>{line}</p>*/}
                            {/*        ))}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </>
            );
    }
};

export {TheCardViewer};
